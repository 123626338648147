import axios from 'axios';
import {
    groupListRequest,
    groupListSuccess,
    groupListFail,
    groupCreateRequest,
    groupCreateSuccess,
    groupCreateFail,
    groupUpdateRequest,
    groupUpdateSuccess,
    groupUpdateFail,

} from '../stores/userGroupSlice'


const SESSION_TIMEOUT_DURATION = 30 * 60 * 1000; // 30 minutes
const AUTH_LOGIN_URL_FRAGMENT = "/auth/login/";
const LOGOUT_ENDPOINT = `${process.env.REACT_APP_UNSPLASH_URL}auth/logout/`;

const clearSessionAndReload = () => {
    localStorage.clear();
    window.location.reload();
};

const isSessionExpired = () => {
    const lastActiveTime = parseInt(localStorage.getItem('last_active_time'), 10);
    const isCookieExpired = document.cookie.split(";").some((cookie) => cookie.includes("session_expired=True"));
    return (Date.now() - lastActiveTime) > SESSION_TIMEOUT_DURATION || isCookieExpired;
};

export const api = axios.create({
    baseURL: process.env.REACT_APP_UNSPLASH_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

api.interceptors.response.use(
    response => response,
    async (error) => {
        console.log("interceptor error:", error);

        if (error.response.status === 405 && error.response.request.responseURL.includes(AUTH_LOGIN_URL_FRAGMENT)) {
            clearSessionAndReload();
            return;
        }

        const { status, data } = error.response;
        const originalRequest = error.config;


        const sessionInvalid = isSessionExpired() || data.token_not_valid || data.token_expired === "true";

        if (sessionInvalid && (status === 401 || isSessionExpired())) {
            if (isSessionExpired()) {
                await axios.post(LOGOUT_ENDPOINT, {}, { withCredentials: true });
            }
            clearSessionAndReload();
            return;
        }

        if (status === 401 && localStorage.getItem('isAuthenticated') === "true") {
            if (!originalRequest._retry) {
                originalRequest._retry = true;
                return api(originalRequest);
            } else {
                clearSessionAndReload();
                return;
            }
        }

        return Promise.reject(error);
    }
);

//* LOGIN PAGE
export var LoginUserApi = async (data) => {
    var result = await axios(`auth/token/`, {
        credentials: 'include',
        method: 'POST',
        data: data,
    })

    return result
}

//* MAIN PAGE 

export var GET_MainPageTable = async () => {
    var result = await api.get(`gateway/`)
    return result
}


export var getSingleGatewayData = async (device_serial) => {
    var result = await api.get(`gateway/${device_serial}`)
    return result
}

export const addGateway = async (data) => {

    const result = await api.post('gateway/', data)
    return result
}

export const updateGateway = async (device_serial, data) => {

    const result = await api.patch(`gateway/${device_serial}/`, data)
    return result
}


export var getAllSupportTicketData = async () => {
    var result = await api.get(`support/tickets/`)
    return result
}

export var getSingleAllSupportTicketData = async (filters) => {
    var result = await api.get(`support/tickets/`, {params: filters})
    return result
}


export var nodeLogsApi = async (params) => {
    var result = await api.get(`data/node_logs/`, {params: params})
    return result
}

// export var getNodes = async (data) => {
//     var result = await api.post(`data/get_nodes/`, data)
//     return result
// }

export var getSingleSupportTicketData = async (id) => {
    var result = await api.get(`support/tickets/${id}/`)
    return result
}

export var createMessageToTicket = async (id, data) => {
    var result = await api.post(`support/tickets/${id}/messages/`, data)
    return result
}

export var updateTicketStatus = async (id, data) => {
    var result = await api.put(`support/tickets/${id}/`, data)
    return result
}

// export var createWebSiteSettings = async (data) => {
//     var result = await api.post(`data/create-website-setting/`, data)
//     return result
// }


export const getFavList = async () => {
    const result = await api.get(`users/my-profile/follows/`)
    return result
}

// export var updateWebSiteSettings = async (data) => {
//     var result = await api.post(`data/update-website-setting/`, data)
//     return result
// }

// export var deleteWebSiteSettings = async (data) => {
//     var result = await api.post(`data/delete-website-setting/`, data)
//     return result
// }


export var getAllLogs = async (params) => {
    var result = await api.get(`data/all_logs/`, {params: params})
    return result
}


// export var getftpRefresh = async (data, id) => {
//     var result = await api.post(`data/ftp_refresh/${id}`, data)
//     return result
// }

export var getAnomalyAnalyzer = async (device_serial, params) => {
    var result = await api.get(`gateway/${device_serial}/anomaly_analyzer/`, {params: params})
    return result
}

export const listGroups = () => async (dispatch) => {
    try{
        dispatch(groupListRequest());

        const { data } = await api.get('groups/');

        dispatch(groupListSuccess(data))
    }catch(error){
        dispatch(groupListFail(
            error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message
        ))
    }
};


export const createGroup = (group) => async (dispatch) => {
    try{
        dispatch(groupCreateRequest());

        const { data } = await api.post(
            'groups/',
            group
        );

        dispatch(groupCreateSuccess(data))
    }catch(error){
        dispatch(groupCreateFail(
            error.response && error.response.data.groupNumber.detail
            ? error.response.data.groupNumber.detail
            : error.message
        ))
    }
};


export const updateGroup = (group, groupID) => async (dispatch) => {
    try{
        dispatch(groupUpdateRequest());

        const { data } = await api.put(`groups/${groupID}/`, group);

        await dispatch(groupUpdateSuccess(data))
    }catch(error){
        dispatch(groupUpdateFail(
            error.response && error.response.data.groupNumber.detail
            ? error.response.data.groupNumber.detail
            : error.message
        ))
    }
};


export var deleteUserGroup = async (id) => {
    var result = await api.delete(`groups/${id}/`)
    return result
}


export var getAllUsers = async (data) => {
    var result = await api.get(`users/`, {params: data})
    return result
}

export var updateUser = async (userID, data) => {
    var result = await api.patch(`users/${userID}/`, data)
    return result
}

// export var getUser = async (userID) => {
//     var result = await api.post(`auth/get-update-user/${userID}`)
//     return result
// }

export var deleteUserApi = async (userID) => {
    var result = await api.delete(`users/${userID}`)
    return result
}

export var createNewUsers = async (data) => {
    var result = await api.post(`auth/register/`, data)
    return result
}


export var updateConfigsApi = async (data, device_serial) => {
    var result = await api.put(`gateway/${device_serial}/configs/`, data)
    return result
}

export var getConfigsApi = async (device_serial) => {
    var result = await api.get(`gateway/${device_serial}/configs/`)
    return result
}


export const getDefaultParams = async (params) => {
    const result = await api.get(`data/default-gateway-params/`, {params: params})
    return result
}


export const getGatewayGMT = async (device_serial) => {
    const result = await api.get(`gateway/${device_serial}/gmt/`)
    return result
}


export var getSessions = async (device_serial, params) => {
    var result = await api.get(`gateway/${device_serial}/sessions/`, {params: params})
    return result
}


export var resetST = async (device_serial) => {
    var result = await api.post(`gateway/${device_serial}/reset-st/`)
    return result
}

export var addRoomApi = async (device_serial, roomData) => {
    var result = await api.post(`gateway/${device_serial}/rooms/`, roomData)
    return result
}


export const updateRoom = async (device_serial, room_id, updatedData) => {
    const result = await api.patch(`gateway/${device_serial}/rooms/${room_id}`, updatedData)
    return result
};


export var deleteRoomApi = async (device_serial, room_id) => {
    var result = await api.delete(`gateway/${device_serial}/rooms/${room_id}`)
    return result
}


export var addNodeApi = async (device_serial, room_id, nodeData) => {
    var result = await api.post(`gateway/${device_serial}/rooms/${room_id}/nodes/`, nodeData)
    return result
}


export var deleteNodeApi = async (device_serial, room_id, node_id) => {
    var result = await api.delete(`gateway/${device_serial}/rooms/${room_id}/nodes/${node_id}`)
    return result
}


export var FotaInformationApi = async (params) => {
    var result = await api.get(`fota/`, {params: params})
    return result
}


export var FotaApi = async (device_serial, data) => {
    var result = await api.post(`gateway/${device_serial}/fota/`, data)
    return result
}

export const updateFota = async (fotaID, data) => {
    const result = await api.put(`fota/${fotaID}`, data)
    return result
};


export const deleteFota = async (fotaID) => {
    const result = await api.delete(`fota/${fotaID}`)
    return result
};



export var AddFotaApi = async (data) => {
    var result = await api.post(`fota/`, data)
    return result
}


export var updateProfile = async (updatedData) => {
    var result = await api.put('users/my-profile/', updatedData);

    return result;
}


export const getToken = async () => {
    const result = await api.get('users/my-profile/verification-code/');
    return result;
}


export const getLastLogTimes = async (device_serial) => {
    const result = await api.get(`gateway/${device_serial}/last_log_times/`);
    return result;
}


export const getNodeList = async () => {
    const result = await api.get('data/get_nodes/');
    return result;
}


export const getGatewayUsers = async (device_serial) => {
    const result = await api.get(`gateway/${device_serial}/users/`);
    return result;
}


export const deleteHome = async (device_serial) => {
    const result = await api.delete(`gateway/${device_serial}/home/`);
    return result;
}


export const getDeviceStatus = async (device_type, query_params) => {
    const result = await api.get(`logs/status-report/${device_type}`, {params: query_params});
    return result;
}


export const getLastAnomalyTime = async (device_serial) => {
    const result = await api.get(`gateway/${device_serial}/last-anomaly/`);
    return result;
}

export const deleteAccount = async (email, password) => {
    const result = await api.post('auth/delete-account/', {email, password});
    return result;
}

export const refreshLogs = async (device_serial) => {
    const result = await api.get(`gateway/${device_serial}/refresh-logs/`);
    return result;
}