import { createSlice } from '@reduxjs/toolkit'


export const ModificationStepsControllerSlice = createSlice({
    name: 'ModificationStepsController',
    initialState: {
        mainStep: 1,
        step1Value: null,
        step2Index: null,
        step2Value: null,
        step3Index: null,
        step3Value: null,
        step4Index: null,
        step4Value: null,
        isNextButtonOk: false,
        stepType: ''
    },

    reducers: {
        setMainStep: (state, action) => {
            state.mainStep = action.payload
        },
        setStep1Value: (state, action) => {
            state.step1Value = action.payload
        },
        setStep2Index: (state, action) => {
            state.step2Index = action.payload
        },
        setStep2Value: (state, action) => {
            state.step2Value = action.payload
        },
        setStep3Index: (state, action) => {
            state.step3Index = action.payload
        },
        setStep3Value: (state, action) => {
            state.step3Value = action.payload
        },
        setStep4Index: (state, action) => {
            state.step4Index = action.payload
        },
        setStep4Value: (state, action) => {
            state.step4Value = action.payload
        },
        setIsNextButtonOk: (state, action) => {
            state.isNextButtonOk = action.payload
        },
        setStepType: (state, action) => {
            state.stepType = action.payload
        },
    }
})


export const { setMainStep, setStep1Value, setStep2Index, setStep2Value, setStep3Index, setStep3Value, setStep4Index, setStep4Value, setIsNextButtonOk, setStepType } = ModificationStepsControllerSlice.actions

export default ModificationStepsControllerSlice.reducer