import { createSlice } from "@reduxjs/toolkit";


export const oldNodeSlice = createSlice({
    name: 'oldNodeReducers',
    initialState: {
        error: null,
        loading: false,
        success: false,
        oldNodes: []
    },
    reducers:{
        // oldNodes
        oldNodeListRequest: (state) => { state.loading = true },
        oldNodeListSuccess: (state, action) => { state.loading = false; state.oldNodes = action.payload; state.success = true; },
        oldNodeListFail: (state, action) => { state.loading = false; state.error = action.payload; },

        // Reset Actions
        oldNodeListReset: (state) => { state.loading = false; state.success = false; state.error = null; },

        // Reset Slice
        oldNodeSliceReset: (state) => {
            state.loading = false; state.success = false; state.error = null; state.oldNodes = null;
        },
    }
});

export const {

    oldNodeListRequest,
    oldNodeListSuccess,
    oldNodeListFail,
    oldNodeListReset,

    oldNodeSliceReset,
    
} = oldNodeSlice.actions;
