import { api } from "../../apis/Apis";
import { hotlineDetailFail, hotlineDetailRequest, hotlineDetailSuccess, hotlineListFail, hotlineListRequest, hotlineListSuccess } from "../../stores/hotline/hotlineSlice";


export const listHotlines = (filters) => async (dispatch) => {
    try{
        dispatch(hotlineListRequest());

        const { data } = await api.get('hotlines/', { params: filters });

        dispatch(hotlineListSuccess(data));
    }catch(error){
        dispatch(hotlineListFail(
            error.response && error.response.data.detail
            ? error.response.data.detail || error.response.data.error
            : error.message,
        ));
    }
}


export const detailHotline = (id) => async (dispatch) => {
    try{
        dispatch(hotlineDetailRequest());

        const { data } = await api.get(`hotlines/${id}/`);

        dispatch(hotlineDetailSuccess(data));
    }catch(error){
        dispatch(hotlineDetailFail(
            error.response && error.response.data.detail
            ? error.response.data.detail || error.response.data.error
            : error.message,
        ));
    }
}
