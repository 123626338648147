import React from 'react'

import roomImage1 from '../../../assets/images/rooms/roomImage1.png'
import roomImage2 from '../../../assets/images/rooms/roomImage2.png'
import roomImage3 from '../../../assets/images/rooms/roomImage3.png'
import roomImage4 from '../../../assets/images/rooms/roomImage4.png'
import roomImage5 from '../../../assets/images/rooms/roomImage5.png'
import roomImage6 from '../../../assets/images/rooms/roomImage6.png'
import roomImage7 from '../../../assets/images/rooms/roomImage7.png'
import { Select } from 'antd'
import { useTranslation } from 'react-i18next'

const { Option } = Select;

function ImageDropdown({ onChange ,roomImage }) {

    const {t} = useTranslation();

    return (
        <Select
            style={{ width: '100%' }}
            placeholder={t('modals.add_room_modal.image_placeholder')}
            className="custom-select"
            onChange={onChange}
            defaultValue={roomImage < 7 ? 7 : roomImage}
        >
            <Option value={7}>
                <img alt="Image 1" src={roomImage1} style={{ width: '54px', height: '44px' }} />
            </Option>
            <Option value={8}>
                <img alt="Image 2" src={roomImage2} style={{ width: '51px', height: '42px' }} />
            </Option>
            <Option value={9}>
                <img alt="Image 3" src={roomImage3} style={{ width: '51px', height: '41px' }} />
            </Option>
            <Option value={10}>
                <img alt="Image 4" src={roomImage4} style={{ width: '53px', height: '45px' }} />
            </Option>
            <Option value={11}>
                <img alt="Image 5" src={roomImage5} style={{ width: '56px', height: '53px' }} />
            </Option>
            <Option value={12}>
                <img alt="Image 6" src={roomImage6} style={{ width: '51px', height: '39px' }} />
            </Option>
            <Option value={13}>
                <img alt="Image 7" src={roomImage7} style={{ width: '52px', height: '40px' }} />
            </Option>
        </Select>
    )
}

export default ImageDropdown
