import { createSlice } from '@reduxjs/toolkit'


export const systemCheckControllerSlice = createSlice({
    name: 'systemCheckControllerSlice',
    initialState: {
        systemCheckStatus: localStorage.getItem('systemCheck') && JSON.parse(localStorage.getItem('systemCheck')).status == true ? true : false,
        systemCheckBody: <>{localStorage.getItem('systemCheck') && JSON.parse(localStorage.getItem('systemCheck')).body}</>
    },

    reducers: {
        setSystemCheckStatus: (state, action) => {
            state.systemCheckStatus = action.payload
        },
        setSystemCheckBody: (state, action) => {
            state.systemCheckBody = action.payload
        },
    }
})


export const { setSystemCheckStatus, setSystemCheckBody } = systemCheckControllerSlice.actions

export default systemCheckControllerSlice.reducer


