import React from 'react'
import { SEO_HELMET } from '../../../../../../core/utils/helmet';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../../../core/stores/navbarTitleControllerSlice';
import { Card, DatePicker, Descriptions, Row, Select } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { getDeviceStatusList } from '../../../../../../core/actions/logs/deviceStatusActions';
import SensorStatusTable from './SensorStatusTable';
import GatewayStatusTable from './GatewayStatusTable';


function DeviceStatusHistory() {

    const dispatch = useDispatch();

    const dateFormat = 'YYYY-MM-DD';

    const today = moment(new Date()).format(dateFormat);
    const sevenDaysAgo = moment(new Date()).subtract(7, 'days').format(dateFormat);

    const {
        deviceStatusReducers: { deviceStatusList, loading, error, dataCount }
    } = useSelector((state) => state);

    const [queryParams, setQueryParams] = React.useState({
        created_at__lte: null,
        created_at__gte: sevenDaysAgo,
        device_type: "sensor"
    });

    const [totalOnlinePercentage, setTotalOnlinePercentage] = React.useState(0);

    React.useEffect(() => {
        dispatch(setTitle(t('pages.device_status_analytics.title')))
        dispatch(getDeviceStatusList(queryParams))
    }, [dispatch, queryParams])

    const descriptionItems = [
        {
            label: t('pages.device_status_analytics.description_items.device_type'),
            children: <>
                <Select options={[
                        {label: t('pages.device_status_analytics.description_items.gateway'), value: "gateway"},
                        {label: t('pages.device_status_analytics.description_items.node'), value: "sensor"}
                    ]}
                    defaultValue={queryParams.device_type}
                    style={{width: "100%"}}
                    onChange={(value) => setQueryParams({...queryParams, device_type: value})}
                />
            </>
        },
        {
            label: t('description_items.date'),
            children: <>
                <DatePicker.RangePicker
                    format={dateFormat}
                    defaultValue={[dayjs(sevenDaysAgo), dayjs(today)]}
                    onChange={(date, dateString) => {
                        setQueryParams({
                            ...queryParams,
                            created_at__gte: dateString[0],
                            created_at__lte: dateString[1]
                        })
                    }}
                />
            </>
        },
        {
            label: t('pages.device_status_analytics.tables.online_percentage'),
            children: <span>{totalOnlinePercentage || t('general.unknown')}%</span>
        },
        {
            label: t('pages.node_logs.description_items.total_entries'),
            children: <span>{dataCount || t('general.unknown')}</span>
        }
    ];

    const renderTable = (device_type) => {
        switch (device_type) {
            case "sensor":
                return (
                    <SensorStatusTable dataSource={deviceStatusList} loading={loading} secondsToHHmmss={secondsToHHmmss} setTotalOnlinePercentage={setTotalOnlinePercentage} calculatePercentage={calculatePercentage} secondsToMMDDHHmmss={secondsToMMDDHHmmss} />
                )
            case "gateway":
                return (
                    <GatewayStatusTable dataSource={deviceStatusList} loading={loading} secondsToHHmmss={secondsToHHmmss} setTotalOnlinePercentage={setTotalOnlinePercentage} calculatePercentage={calculatePercentage} secondsToMMDDHHmmss={secondsToMMDDHHmmss} />
                )
            default:
                return (
                    <Card className='w-100 h-100'>
                        <div className='d-flex justify-content-center align-items-center user-select-none' style={{ height: '80vh', width: '100%' }}>
                            <p className='h1'>{t('pages.node_logs.no_data')}</p>
                        </div>
                    </Card>
                )
        }
    };

    const renderContent = () => {
        if (error) {
            return (
                <Card className='w-100 h-100'>
                    <div className='d-flex justify-content-center align-items-center user-select-none' style={{ height: '80vh', width: '100%' }}>
                        <p className='h1'>{t('pages.node_logs.error')}</p>
                    </div>
                </Card>
            )
        }

        return renderTable(queryParams.device_type)
    }

    const secondsToHHmmss = (duration) => {
        const sec_num = parseInt(duration, 10)

        if (sec_num === 0) {
            return "00:00:00"
        }

        let hours = Math.floor(sec_num / 3600)
        let minutes = Math.floor((sec_num - (hours * 3600)) / 60)
        let seconds = sec_num - (hours * 3600) - (minutes * 60)

        if (hours < 10) {hours = "0" + hours}
        if (minutes < 10) {minutes = "0" + minutes}
        if (seconds < 10) {seconds = "0" + seconds}
        return hours + ':' + minutes + ':' + seconds
    }

    const secondsToMMDDHHmmss = (duration) => {
        const sec_num = parseInt(duration, 10)

        if (sec_num === 0) {
            return "00:00:00"
        }

        let month = Math.floor(sec_num / 2592000)
        let day = Math.floor((sec_num - (month * 2592000)) / 86400)
        let hours = Math.floor((sec_num - (month * 2592000) - (day * 86400)) / 3600)
        let minutes = Math.floor((sec_num - (month * 2592000) - (day * 86400) - (hours * 3600)) / 60)
        let seconds = sec_num - (month * 2592000) - (day * 86400) - (hours * 3600) - (minutes * 60)

        if (hours < 10) {hours = "0" + hours}
        if (minutes < 10) {minutes = "0" + minutes}
        if (seconds < 10) {seconds = "0" + seconds}
        
        if (month === 0) {
            return `${day}d ${hours}:${minutes}:${seconds}`
        }
        return `${month}m ${day}d ${hours}:${minutes}:${seconds}`
    }
    
    const calculatePercentage = (x, y) => {
        return (x / (x + y) * 100).toFixed(2)
    }
    
    return (
        <>
            <SEO_HELMET title={t('pages.device_status_analytics.title')} />

            <>
                <Row gutter={[0, 15]}>
                    <Descriptions
                        items={descriptionItems}
                        bordered
                        size='small'
                        layout='horizontal'
                        column={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 4}}
                        style={{
                            width: "100%"
                        }}
                    />

                    {renderContent()}

                </Row>
            </>
        </>
    )
}

export default DeviceStatusHistory