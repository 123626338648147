import { createSlice } from '@reduxjs/toolkit'


export const boxShipmentControllerSlice = createSlice({
    name: 'boxShipmentControllerSlice',
    initialState: {
        emlployeeState: {
            "employeeName": "",
            "employeeSurName": "",
            "tel": "",
        },
    },

    reducers: {
        setEmlployeeState: (state, action) => {
            state.emlployeeState = action.payload
        },
   
    }
})


export const { setEmlployeeState } = boxShipmentControllerSlice.actions

export default boxShipmentControllerSlice.reducer