import { Button, Table } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Moment from 'moment';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import './NodeLogTable.scss'
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';

function NodeLogTable({ dataSource, loading, sensor_type, table_name, gateway_gmt}) {

    const [filteredInfo, setFilteredInfo] = useState({})
    const [currentData, setCurrentData] = useState([])
    const {t} = useTranslation();

    const {
        authReducers: { isDarkMode }
    } = useSelector((state) => state)


    const sd_width = useMediaQuery({ query: '(max-width: 576px)' });
    const ssd_width = useMediaQuery({ query: '(max-width: 768px)' });
    const hd_width = useMediaQuery({ query: '(max-width: 992px)' });
    const fhd_width = useMediaQuery({ query: '(max-width: 1200px)' });
    const qhd_width = useMediaQuery({ query: '(max-width: 1440px)' });
    const uhd_width = useMediaQuery({ query: '(max-width: 1600px)' });

    const baseColumns = [
        {
            title: t('tables.date'),
            dataIndex: "date",
            key: "date",
            render: (text, record) => 
                <div>
                    {Moment.unix(record?.timestamp).utcOffset(gateway_gmt - 12).format("DD/MM/YY, HH:mm")}
                </div>,
            width: 150
        },
        {
            title: t('tables.node_logs_table.timestamp'),
            dataIndex: "timestamp",
            key: "timestamp",
            width: 120,
        },
        {
            title: t('tables.node_logs_table.compare_timestamp'),
            dataIndex: "compare_timestamp",
            key: "compare_timestamp",
            render: (_, record, index) => {
                const compare_timestamp =
                    currentData?.length > 0 ?
                        index + 1 !== currentData?.length ? Moment.unix(record?.timestamp).diff(Moment.unix(currentData[index + 1]?.timestamp), 'seconds') : 0
                    :
                        index + 1 !== dataSource?.length ?  Moment.unix(record?.timestamp).diff(Moment.unix(dataSource[index + 1]?.timestamp), 'seconds') : 0

                return (
                    <div>
                        {compare_timestamp}
                    </div>
                );
            },
            width: 100
        },
        {
            title: "RX",
            dataIndex: "rx",
            key: "rx",
            width: 80,
        },
        {
            title: "TX",
            dataIndex: "tx",
            key: "tx",
            width: 80,
        },
        {
            title: t('tables.node_logs_table.battery_voltage'),
            dataIndex: "battery_voltage",
            key: "battery_voltage",
            width: 100,
            render: (text, record) => ((parseInt(text) / 170) + 2).toFixed(3)
        },
        {
            title: t('tables.node_logs_table.transmit_count'),
            dataIndex: "transmit_count",
            key: "transmit_count",
            width: 120,
        },
    ]

    const csvHeaders = [
        { label: t('tables.node_logs_table.timestamp'), key: "timestamp" },
        { label: "RX", key: "rx" },
        { label: "TX", key: "tx" },
        { label: t('tables.node_logs_table.battery_voltage'), key: "battery_voltage" },
        { label: t('tables.node_logs_table.transmit_count'), key: "transmit_count" },
    ];

    useEffect(() => {
        if (Object.keys(filteredInfo).length !== 0) {
            setCurrentData(dataSource.filter((item) => {
                for (const key in filteredInfo) {
                    if (filteredInfo[key] !== null) {
                        return filteredInfo[key].includes(item[key]);
                    }
                }
                return null;
            }))
        }
    }, [dataSource])


    // ----------------- New columns by sensor type -----------------


    switch (sensor_type) {
        case "1":
            // Motion
            baseColumns.push(
                {
                    title: t('tables.node_logs_table.state'),
                    dataIndex: "sensor_status",
                    key: "sensor_status",
                    filters: [...new Set(dataSource.map(item => item.sensor_status))].map(item => ( item === "No Motion" ? { text: t('tables.node_logs_table.dummy_data'), value: item }: { text: t(`tables.node_logs_table.${item}`), value: item })),
                    filteredValue: filteredInfo.sensor_status || null,
                    onFilter: (value, record) => record.sensor_status.indexOf(value) === 0,
                    render: (text, record) => text === "No Motion" ? t('tables.node_logs_table.dummy_data') : t(`tables.node_logs_table.${text}`),
                    width: 150,
                },
                {
                    title: t('tables.node_logs_table.state_number'),
                    dataIndex: "sensor_status_number",
                    key: "sensor_status_number",
                    width: 100,
                }
            )

            csvHeaders.push(
                { label: t('tables.node_logs_table.state'), key: "sensor_status" },
                { label: t('tables.node_logs_table.state_number'), key: "sensor_status_number" },
            )

            break;
        case "2":
            // Door
            baseColumns.push(
                {
                    title: t('tables.node_logs_table.state'),
                    dataIndex: "sensor_status",
                    key: "sensor_status",
                    filters: [...new Set(dataSource.map(item => item.sensor_status))].map(item => ({ text: t(`tables.node_logs_table.${item}`), value: item })),
                    filteredValue: filteredInfo.sensor_status || null,
                    onFilter: (value, record) => record.sensor_status.indexOf(value) === 0,
                    render: text => t(`tables.node_logs_table.${text}`),
                    width: 100
                },
                {
                    title: t('tables.node_logs_table.state_number'),
                    dataIndex: "sensor_status_number",
                    key: "sensor_status_number",
                    width: 100
                }
            )

            csvHeaders.push(
                { label: t('tables.node_logs_table.state'), key: "sensor_status" },
                { label: t('tables.node_logs_table.state_number'), key: "sensor_status_number" },
            )

            break;
        case "3":
            // Force
            baseColumns.push(
                {
                    title: t('tables.node_logs_table.state'),
                    dataIndex: "chair_status",
                    key: "chair_status",
                    filters: [
                        { text: t('tables.node_logs_table.dummy_data'), value: t('tables.node_logs_table.dummy_data') },
                        ...[...new Set(dataSource.map(item => item.chair_status))].filter(item => item !== 'No Person').filter(item => item !== 'Dummy Data').map(item => ({ text:  t(`tables.node_logs_table.${item}`), value: item }))
                    ],
                    filteredValue: filteredInfo.chair_status || null,
                    onFilter: (value, record) => {
                        if (value === t('tables.node_logs_table.dummy_data')) {
                            return record.chair_status === 'No Person' || record.chair_status === 'Dummy Data';
                        }
                        return record.chair_status.indexOf(value) === 0;
                    },
                    render: (text, record) => text === "No Person" ? t('tables.node_logs_table.dummy_data') : t(`tables.node_logs_table.${text}`),
                    width: 150
                },
                {
                    title: t('tables.node_logs_table.state_number'),
                    dataIndex: "chair_status_number",
                    key: "chair_status_number",
                    width: 100
                },
                {
                    title: "ADC Raw",
                    dataIndex: "adc",
                    key: "adc",
                    width: 100
                },
                {
                    title: t('tables.node_logs_table.load_status'),
                    dataIndex: "load_status",
                    key: "load_status",
                    filters: [...new Set(dataSource.map(item => item.load_status))].map(item => ({ text: t(`tables.node_logs_table.${item}`), value: item })),
                    filteredValue: filteredInfo.load_status || null,
                    onFilter: (value, record) => record.load_status.indexOf(value) === 0,
                    render: text => t(`tables.node_logs_table.${text}`),
                    width: 150
                },
                {
                    title: t('tables.node_logs_table.sensor_status'),
                    dataIndex: "sensor_status",
                    key: "sensor_status",
                    filters: [...new Set(dataSource.map(item => item.sensor_status))].map(item => ({ text: t(`tables.node_logs_table.${item}`), value: item })),
                    filteredValue: filteredInfo.sensor_status || null,
                    onFilter: (value, record) => record.sensor_status.indexOf(value) === 0,
                    width: 150,
                    render: text => t(`tables.node_logs_table.${text}`),
                }
            )

            csvHeaders.push(
                { label: t('tables.node_logs_table.state'), key: "chair_status" },
                { label: t('tables.node_logs_table.state_number'), key: "chair_status_number" },
                { label: "ADC Raw", key: "adc" },
                { label: t('tables.node_logs_table.load_status'), key: "load_status" },
                { label: t('tables.node_logs_table.sensor_status'), key: "sensor_status" },
            )

            break;
        case "4":
            // Emergency Button
            baseColumns.push(
                {
                    title: t('tables.node_logs_table.state'),
                    dataIndex: "sensor_status",
                    key: "sensor_status",
                    filters: [...new Set(dataSource.map(item => item.sensor_status))].map(item => ( item === "Button Not Pressed" ? { text: t('tables.node_logs_table.dummy_data'), value: item }: { text: t(`tables.node_logs_table.${item}`), value: item })),
                    filteredValue: filteredInfo.sensor_status || null,
                    onFilter: (value, record) => record.sensor_status.indexOf(value) === 0,
                    render: (text, record) => text === "Button Not Pressed" ? t('tables.node_logs_table.dummy_data') : t(`tables.node_logs_table.${text}`),
                    width: 150
                },
                {
                    title: t('tables.node_logs_table.state_number'),
                    dataIndex: "sensor_status_number",
                    key: "sensor_status_number",
                    width: 100
                }
            )

            csvHeaders.push(
                { label: t('tables.node_logs_table.state'), key: "sensor_status" },
                { label: t('tables.node_logs_table.state_number'), key: "sensor_status_number" },
            )

            break;
        case "5":
            // Humidity Motion
            baseColumns.push(
                {
                    title: t('tables.node_logs_table.state'),
                    dataIndex: "sensor_status",
                    key: "sensor_status",
                    filters: [...new Set(dataSource.map(item => item.sensor_status))].map(item => (item === "No Motion" ? { text: t('tables.node_logs_table.dummy_data'), value: item } : { text: t(`tables.node_logs_table.${item}`), value: item })),
                    filteredValue: filteredInfo.sensor_status || null,
                    onFilter: (value, record) => record.sensor_status.indexOf(value) === 0,
                    render: (text, record) => text === "No Motion" ? t('tables.node_logs_table.dummy_data') : t(`tables.node_logs_table.${text}`),
                    width: 150
                },
                {
                    title: t('tables.node_logs_table.state_number'),
                    dataIndex: "sensor_status_number",
                    key: "sensor_status_number",
                    width: 100
                },
                {
                    title: t('tables.node_logs_table.humidity_table.temperature'),
                    dataIndex: "temperature",
                    key: "temperature",
                    render: (text, record) => (parseInt(text) / 10),
                    width: 100
                },
                {
                    title: t('tables.node_logs_table.humidity_table.ref_temperature'),
                    dataIndex: "reference_temperature",
                    key: "reference_temperature",
                    render: (text, record) => (parseInt(text) / 10),
                    width: 100
                },
                {
                    title: t('tables.node_logs_table.humidity_table.humidity'),
                    dataIndex: "humidity",
                    key: "humidity",
                    render: (text, record) => (parseInt(text) / 10),
                    width: 100
                },
                {
                    title: t('tables.node_logs_table.humidity_table.ref_humidity'),
                    dataIndex: "reference_humidity",
                    key: "reference_humidity",
                    render: (text, record) => (parseInt(text) / 10),
                    width: 100
                }
            )

            csvHeaders.push(
                { label: t('tables.node_logs_table.state'), key: "sensor_status" },
                { label: t('tables.node_logs_table.state_number'), key: "sensor_status_number" },
                { label: t('tables.node_logs_table.humidity_table.temperature'), key: "temperature" },
                { label: t('tables.node_logs_table.humidity_table.ref_temperature'), key: "reference_temperature" },
                { label: t('tables.node_logs_table.humidity_table.humidity'), key: "humidity" },
                { label: t('tables.node_logs_table.humidity_table.ref_humidity'), key: "reference_humidity" },
            )

            break;
        case "6":
            // Sleep Monitor
            baseColumns.push(
                {
                    title: t('tables.node_logs_table.state'),
                    dataIndex: "sleep_sensor_status",
                    key: "sleep_sensor_status",
                    filters: [...new Set(dataSource.map(item => item.sleep_sensor_status))].map(item => ({ text: t(`tables.node_logs_table.${item}`), value: item })),
                    filteredValue: filteredInfo.sleep_sensor_status || null,
                    onFilter: (value, record) => record.sleep_sensor_status.indexOf(value) === 0,
                    render: text => t(`tables.node_logs_table.${text}`),
                    width: 150
                },
                {
                    title: t('tables.node_logs_table.state_number'),
                    dataIndex: "sleep_sensor_status_number",
                    key: "sleep_sensor_status_number",
                    width: 100
                },
                {
                    title: t('tables.node_logs_table.sleep_table.bpm'),
                    dataIndex: "bpm",
                    key: "bpm",
                    width: 100
                }
            )

            csvHeaders.push(
                { label: t('tables.node_logs_table.state'), key: "sleep_sensor_status" },
                { label: t('tables.node_logs_table.state_number'), key: "sleep_sensor_status_number" },
                { label: t('tables.node_logs_table.sleep_table.bpm'), key: "bpm" },
            )

            break;
        case "7":
            // Extender
            baseColumns.push(
                {
                    title: t('tables.node_logs_table.state'),
                    dataIndex: "sensor_status",
                    key: "sensor_status",
                    render: (text, record) => t('tables.node_logs_table.dummy_data'),
                    width: 150
                },
                {
                    title: t('tables.node_logs_table.state_number'),
                    dataIndex: "sensor_status_number",
                    key: "sensor_status_number",
                    render: (text, record) => "0",
                    width: 100
                }
            )
            baseColumns.splice(6,1)

            csvHeaders.push(
                { label: t('tables.node_logs_table.state'), key: "sensor_status" },
                { label: t('tables.node_logs_table.state_number'), key: "sensor_status_number" },
            )

            break;
        default:
            break;
    }

    return (
        <Table
            columns={baseColumns}
            dataSource={dataSource}
            loading={loading}
            scroll={{
                y: sd_width ? 'calc(100vh - 300px)' : ssd_width ? 'calc(100vh - 300px)' : hd_width ? 'calc(100vh - 300px)' : fhd_width ? 'calc(100vh - 300px)' : qhd_width ? 'calc(100vh - 300px)' : uhd_width ? 'calc(100vh - 350px)' : 'calc(100vh - 375px)',
                x: 'scroll'
            }}
            pagination={false}
            
            rowKey={(record) => record.log_id}

            onChange={(pagination, filters, sorter, extra) => {
                setFilteredInfo(filters);

                setCurrentData(extra.currentDataSource);
            }}

            footer={(currentPageData) =>
                
                <div className={`d-flex align-items-center justify-content-between flex-column flex-md-row gap-3 gap-md-0 ${fhd_width && 'mb-5'}`}>
                    <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                    {currentPageData.length > 0 &&
                        <>
                            <CSVLink
                                filename={`${Moment.unix(currentPageData[0]?.timestamp).format("DD/MM/YY")}_to_${Moment.unix(currentPageData[currentPageData?.length - 1]?.timestamp).format("DD/MM/YY")}_${table_name}_${currentPageData?.length}_logs.csv`}
                                data={currentPageData}
                                headers={csvHeaders}
                                onClick={()=>{
                                    toast.info(t('tables.node_logs_table.csv_downloading'), {
                                        position: toast.POSITION.TOP_RIGHT,
                                        autoClose: 3000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                    });
                                }}
                            >
                                <Button className={!isDarkMode && 'btn d-flex text-center align-items-center'}>
                                    {t('tables.node_logs_table.export_csv')}                                    
                                </Button>
                            </CSVLink>
                        
                            <Button onClick={() => {setFilteredInfo({}); setCurrentData([])}}>{t('tables.clear_filters')}</Button>
                        </>
                    }
                </div>
            }
        />
    )
}

export default NodeLogTable
