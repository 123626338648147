import { createSlice } from "@reduxjs/toolkit";



const deviceStatusSlice = createSlice({
    name: "deviceStatusReducers",
    initialState: {
        deviceStatusList: [],
        dataCount: null,
        loading: false,
        error: null,
        success: null,
    },
    reducers: {

        // GET DEVICE STATUS LIST
        getDeviceStatusListRequest: (state) => { state.loading = true; },
        getDeviceStatusListSuccess: (state, action) => { state.loading = false; state.deviceStatusList = action.payload.results; state.dataCount = action.payload.data_count; state.success = true; },
        getDeviceStatusListFailed: (state, action) => { state.loading = false; state.error = action.payload; },

        // RESET
        deviceStatusSliceReset: (state) => {
            state.deviceStatusList = null;
            state.dataCount = null;
            state.loading = false;
            state.error = null;
            state.success = null;
        },
    }
});

export const {
    getDeviceStatusListRequest,
    getDeviceStatusListSuccess,
    getDeviceStatusListFailed,
    deviceStatusSliceReset
} = deviceStatusSlice.actions;

export default deviceStatusSlice.reducer;