import { createSlice } from '@reduxjs/toolkit'


export const containerStatusInfoControllerSlice = createSlice({
    name: 'containerStatusInfoControllerSlice',
    initialState: {
        isFilteredForFireAlarm: false,
        fireAlarmContainerData: [],
        isFilteredForCoverAlarm: false,
        coverAlarmContainerData: [],
        isFilteredForNonContainerAssaingDevice: false,
        filteredForNonContainerAssaingDeviceData: [],
        isFilteredForTodayCollect: false,
        todayCollectContainerData: [],
        isFilteredLoading: false,
    },

    reducers: {
        setIsFilteredForFireAlarm: (state, action) => {
            state.isFilteredForFireAlarm = action.payload
        },
        setFireAlarmContainerData: (state, action) => {
            state.fireAlarmContainerData = action.payload
        },
        setIsFilteredForCoverAlarm: (state, action) => {
            state.isFilteredForCoverAlarm = action.payload
        },
        setCoverAlarmContainerData: (state, action) => {
            state.coverAlarmContainerData = action.payload
        },
        setIsFilteredForNonContainerAssaingDevice: (state, action) => {
            state.isFilteredForNonContainerAssaingDevice = action.payload
        },
        setFilteredForNonContainerAssaingDeviceData: (state, action) => {
            state.filteredForNonContainerAssaingDeviceData = action.payload
        },
        setIsFilteredForTodayCollect: (state, action) => {
            state.isFilteredForTodayCollect = action.payload
        },
        setTodayCollectContainerData: (state, action) => {
            state.todayCollectContainerData = action.payload
        },
        setIsFilteredLoading: (state, action) => {
            state.isFilteredLoading = action.payload
        },
    }
})


export const {
    setIsFilteredForFireAlarm,
    setFireAlarmContainerData,
    setIsFilteredForCoverAlarm,
    setCoverAlarmContainerData,
    setIsFilteredForNonContainerAssaingDevice,
    setFilteredForNonContainerAssaingDeviceData,
    setIsFilteredForTodayCollect,
    setTodayCollectContainerData,
    setIsFilteredLoading
} = containerStatusInfoControllerSlice.actions

export default containerStatusInfoControllerSlice.reducer


