import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import store from './core/stores/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";
import history from './core/history/history';
import './i18n';

/* -------------------------------------------------------------------------- */
//TODO -                             i18n                                */
/* -------------------------------------------------------------------------- */

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      {/* <React.StrictMode> */}
    
        <App />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
