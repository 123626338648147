import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client'

import { setSystemCheckBody } from '../../../core/stores/systemCheckControllerSlice';
import './SystemCheck.scss'
import fireAlarmGif from '../../../assets/icons/fire-alarm.gif';
import warningGif from '../../../assets/icons/warning-gif.gif';





// const socket = io.connect(process.env.REACT_APP_MQTT_SOCKET_LISTENER_URL)


const SystemCheck = () => {


    /* -------------------------------------------------------------------------- */
    //TODO -                         SELECTORS                                    */
    /* -------------------------------------------------------------------------- */

    const systemCheckController = useSelector(state => state.systemCheckController)
    const containersRealTimeStatusController = useSelector(state => state.containersRealTimeStatusController)

    const dispatch = useDispatch();


    /* -------------------------------------------------------------------------- */
    //TODO -                            STATES                                    */
    /* -------------------------------------------------------------------------- */

    const [systemCheckList, setSystemCheckList] = useState([])


    /* -------------------------------------------------------------------------- */
    //TODO -                            Functions                                 */
    /* -------------------------------------------------------------------------- */

    const fireAlarmCheck = async () => {
        // await GET_FireAlarmContainer()
        //     .then(res => {
        //         if (res.data.length != 0) {
        //             var newData = {
        //                 path: routes.container.path,
        //                 value: res.data.length
        //             }
        //             systemCheckList.push(newData)
        //         }
        //         // setSystemCheckList(prev => [...systemCheckList, [res.data.length, routes.container.path]])
        //     })
    }


    const coverAlarmCheck = async () => {
        // await GET_OpenCoverAlarmContainer()
        //     .then(res => {
        //         if (res.data.length != 0) {
        //             var newData = {
        //                 path: routes.container.path,
        //                 value: res.data.length
        //             }
        //             systemCheckList.push(newData)
        //         }
        //     })
    }

    const getData = async () => {
        await fireAlarmCheck()
        await coverAlarmCheck()

        // var systemCheckLCL = JSON.parse(localStorage.getItem('systemCheck'))


        const systemCheckObj = {
            status: systemCheckList.length == 0 ? false : true,
            body: systemCheckList
        }
        localStorage.setItem('systemCheck', JSON.stringify(systemCheckObj))
        dispatch(setSystemCheckBody(systemCheckList))

    }

    useEffect(() => {
        getData()
        // getFireAlarmContainer()
        // getOpenCoverAlarmContainer()
        // getRedContainer()
        // getYellowContainer()
        // getGreenContainer()
        // getDeviceNoneAssaignContainer()
    }, [])


    // useEffect(() => {
    //     getData()
    // }, [systemCheckList])



    //todos MQTT and SOCKET LISTENERS

    // const getFireAlarmContainer = useCallback(async () => {
    //     socket.on("check_fire_alarm_api", async (data) => {
    //         fireAlarmCheck()
    //     })
    // }, [])

    // const getOpenCoverAlarmContainer = useCallback(async () => {
    //     socket.on("check_open_cover_alarm_api", async (data) => {
    //         getOpenCoverAlarmContainer()
    //     })
    // }, [])

    // const getRedContainer = useCallback(async () => {
    //     socket.on("red_container_api", async (data) => {
    //         getData()
    //     })
    // }, [])

    // const getYellowContainer = useCallback(async () => {
    //     socket.on("yellow_container_api", async (data) => {
    //         getData()
    //     })
    // }, [])

    // const getGreenContainer = useCallback(async () => {
    //     socket.on("green_container_api", async (data) => {
    //         getData()
    //     })
    // }, [])

    // const getDeviceNoneAssaignContainer = useCallback(async () => {
    //     socket.on("device_none_assaign_container_api", async (data) => {
    //         getData()
    //     })
    // }, [])





    return (

        <div id='SystemCheck'>
            {containersRealTimeStatusController.fireAlarmContainer.length > 0 ?
                <div className='SystemCheck_fireAlarm'>
                    <div className="icon">
                        <img src={fireAlarmGif} srcSet='' />
                    </div>
                    <div className="value">
                        {containersRealTimeStatusController.fireAlarmContainer.length} adet konteynerda yangın alarmı tespit edildi. Yangın alarmı listesini görmek için <a href="">tıklayınız</a>
                    </div>
                </div>
                :
                ''
            }
             {containersRealTimeStatusController.openCoverAlarmContainer.length > 0 ?
                <div className='SystemCheck_openCoverAlarm'>
                    <div className="icon">
                        <img src={warningGif} srcSet='' />
                    </div>
                    <div className="value">
                        {containersRealTimeStatusController.openCoverAlarmContainer.length} adet konteynerda kapak açık alarmı tespit edildi. Kapak açık alarmı listesini görmek için <a href="">tıklayınız</a>
                    </div>
                </div>
                :
                ''
            }
        </div>
    )
}

export default SystemCheck