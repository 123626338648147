import React, { useEffect, useMemo, useRef, useState } from 'react'
import './Fota.scss'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Button, Form, Input, Modal, Popconfirm, Select, Space, Table, Tag, Typography, theme } from 'antd';
import { setIsModalOpenAntd, setModalCaptionAntd, setModalDataAntd, setRunFuncHandle, setStyleAntd } from '../../../core/stores/antdModalControllerSlice';

import { SEO_HELMET } from '../../../core/utils/helmet';
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice';
import { FotaInformationApi, SetLocalStorageApi, deleteFota, updateFota } from '../../../core/apis/Apis';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, Tty } from '@mui/icons-material';
import { toast } from 'react-toastify';
import AddFota from './AddFota';
import UpdateFota from './UpdateFota';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';


function Fota() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const isModalOpen = useSelector(state => state.modalController.isModalOpen)
    const apiStatusController = useSelector(state => state.apiStatusController)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        token: {
            colorBgContainerDisabled,
            colorTextDisabled
        }
    } = theme.useToken();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [responseData, setResponseData] = useState();
    const [responseDataLoading, setResponseDataLoading] = useState(true);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const {
        authReducers: { user_info }
    } = useSelector((state) => state);

    const [editingKey, setEditingKey] = useState('');
    const isEditing = (record) => record.key === editingKey;

    /* -------------------------------------------------------------------------- */
    //TODO -                             FORM                                   */
    /* -------------------------------------------------------------------------- */

    const [form] = Form.useForm();

    const edit = (record) => {
        form.setFieldsValue(record);
        setEditingKey(record.key);
    };

    const save = async (record) => {
        const row = await form.validateFields();

        const fieldsToUpdate = ["version", "command", "changelog", "is_active"];
        const updatedFotaData = {
            "fota_id": record.id
        };

        fieldsToUpdate.forEach(field => {
            if (row[field] !== record[field]) {
                updatedFotaData[field] = row[field];
            }
        });

        updateFota(record.id, updatedFotaData)
            .then(res => {
                if (res.status === 200) {
                    setEditingKey('');
                    getData();
                    toast.success(t('pages.fota.fota_details_update_success_toast'), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log("err:",err);
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    };

    const fotaDelete = (fotaID) => {

        deleteFota(fotaID)
            .then(res => {
                if (res.status === 204) {
                    // dispatch(setIsModalOpenAntd(false))
                    getData();
                    toast.success(t('pages.fota.fota_delete_success_toast'), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log("err:",err);
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const cancel = () => {
        setEditingKey('');
      };

    /* -------------------------------------------------------------------------- */
    //TODO -                          FOTA TABLE                                   */
    /* -------------------------------------------------------------------------- */
    const getData = async () => {
        await FotaInformationApi()
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data.fota)
                }
                setResponseDataLoading(false);
            })
            .catch(err => {
                setResponseDataLoading(false);
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const data = useMemo(() => {
        var list = []
        if (responseData) {
            [...responseData].sort((a, b) => {
                if (a.fota_id === "9999") return 1;
                if (b.fota_id === "9999") return -1;
                return b.fota_id - a.fota_id;
                // if (a.is_active === b.is_active) return b.fota_id - a.fota_id;
                // return a.is_active ? -1 : 1;
            }).map((res, index) => {
                list.push({
                    key: index,
                    id: res.fota_id,
                    version: res.version,
                    command: res.command,
                    changelog: res.changelog,
                    timestamp: res.timestamp,
                    is_active: res.is_active
                })
            })
        }
        return list
    }, [responseData])



    // const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm();
    //     setSearchText(selectedKeys[0]);
    //     setSearchedColumn(dataIndex);
    // };
    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText('');
    // };
    // const getColumnSearchProps = (dataIndex) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    //         <div
    //             style={{
    //                 padding: 8,
    //             }}
    //             onKeyDown={(e) => e.stopPropagation()}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={t('tables.search')}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: 'block',
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     {t('tables.search')}
    //                 </Button>
    //                 <Button
    //                     onClick={() => clearFilters && handleReset(clearFilters)}
    //                     size="small"
    //                     style={{
    //                         width: 90,
    //                     }}
    //                 >
    //                     {t('tables.clear')}
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? '#1890ff' : undefined,
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) =>
    //         record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) =>
    //         searchedColumn === dataIndex ? (
    //             <Highlighter
    //                 highlightStyle={{
    //                     backgroundColor: '#ffc069',
    //                     padding: 0,
    //                 }}
    //                 searchWords={[searchText]}
    //                 autoEscape
    //                 textToHighlight={text ? text.toString() : ''}
    //             />
    //         ) : (
    //             text
    //         ),
    // });

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        ...restProps
    }) => {
        return (
            <td {...restProps}>
                {editing ? (
                    <>
                        {dataIndex === "is_active" ?
                            <Form.Item name={dataIndex} style={{ margin: 0 }} initialValue={record[dataIndex]}>
                                <Select
                                    options={[
                                        { label: <Tag color="green">{t('general.active')}</Tag>, value: true },
                                        { label: <Tag color="red">{t('general.passive')}</Tag>, value: false },
                                    ]}
                                />
                            </Form.Item>
                            : 
                            <Form.Item
                                name={dataIndex}
                                style={{
                                    margin: 0,
                                }}
                                rules={[
                                    {
                                        required: true,
                                        message: `Please Input ${title}!`,
                                    },
                                ]}
                            >
                                {dataIndex === "command" ? <TextArea rows={6} /> : <Input /> }
                            </Form.Item>
                        }
                    </>
                ) : (
                    children
                )}
            </td>
        );
    };

    // const tablePropOnChange = (pagination, filters, sorter) => {
    //     setFilteredInfo(filters);
    // };
    const columns = [
        {
            title: t('tables.date'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: 150,
            render: (text, record) => {
                return (
                    <div>
                        {Moment.unix(text).format("DD.MM.YYYY HH:mm")}
                    </div>
                )
            }
        },
        {
            title: t('tables.fota_table.fota_id'),
            dataIndex: 'id',
            key: 'id',
            width: 120,
        },
        {
            title: t('tables.version'),
            dataIndex: 'version',
            key: 'version',
            editable: true,
            width: 120,
        },
        {
            title: t('tables.fota_table.changelog'),
            dataIndex: 'changelog',
            key: 'changelog',
            editable: true,
            width: 280,
            // filteredValue: filteredInfo.changelog || null,
            // ...getColumnSearchProps('changelog'),
        },
        {
            title: t('tables.fota_table.command'),
            dataIndex: 'command',
            key: 'command',
            editable: true,
            width: 250
        },
        {
            title: t('tables.status'),
            dataIndex: 'is_active',
            key: 'is_active',
            width: 100,
            editable: true,
            filters: [
                { text: <Tag color="success"><Typography.Text type='success'>{t('general.active')}</Typography.Text></Tag>, value: true },
                { text: <Tag color='error'>{t('general.passive')}</Tag>, value: false },
            ],
            filteredValue: filteredInfo.is_active || null,
            onFilter: (value, record) => record.is_active === value,
            render: (text, record) => {
                return (
                    <div>
                        {record.is_active ? <Tag color="success"><Typography.Text type='success'>{t('general.active')}</Typography.Text></Tag> : <Tag color='default'><Typography.Text type='secondary'>{t('general.passive')}</Typography.Text></Tag>}
                    </div>
                )
            }
        },
        {
            title: '',
            dataIndex: 'buttons',
            key: 'buttons',
            width: 60,
            render: (_, record) => (
                <Space size="middle" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button size='small' disabled={!record.is_active} type="primary" onClick={() => updateFotaHandle(record)}>
                        <i className="fa-solid fa-wrench"></i>
                    </Button>
                </Space>
            ),
        },
    ];

    if (user_info?.is_superuser){
        columns.push(
            {
                title: t('tables.operation'),
                dataIndex: 'operation',
                width: 150,
                render: (_, record) => {
                    const editable = isEditing(record);
                    return editable ? (
                        <span>
                            <Typography.Link
                                onClick={() => save(record)}
                                style={{
                                    marginRight: 8,
                                }}
                            >
                                {t('tables.save')}
                            </Typography.Link>
                            <Popconfirm title={t('tables.are_you_sure')} onConfirm={cancel}>
                                <Button type='link' danger>{t('tables.cancel')}</Button>
                            </Popconfirm>
                        </span>
                    ) : (
                        <>
                            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
                                {t('tables.edit')}
                            </Typography.Link>
                            <Button type='link' danger disabled={editingKey !== ''} onClick={() => fotaDelete(record.id)}>
                                {t('tables.delete')}
                            </Button>
                        </>
                    );
                },
            },
        )
    }

    const mergedColumns = columns.map((col) => {
        if (!col.editable) return col;
        
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    /* -------------------------------------------------------------------------- */
    //TODO -                          FUNCTIONS                                   */
    /* -------------------------------------------------------------------------- */

    useEffect(() => {
        dispatch(setTitle(t('pages.fota.title')))
        getData()
    }, [])


    const addFotaHandle = () => {
        dispatch(setModalDataAntd(<AddFota />))
        dispatch(setStyleAntd({ maxWidth: '600px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('modals.add_fota_modal.title')))
        dispatch(setRunFuncHandle(getData))
    }

    const updateFotaHandle = (record) => {
        dispatch(setModalDataAntd(<UpdateFota record={record} />))
        dispatch(setStyleAntd({ maxWidth: '600px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('modals.update_fota_modal.title')))
        dispatch(setRunFuncHandle(getData))
    }

    return (
        <>
            <div id='Fota'>
                <SEO_HELMET title={t('pages.fota.title')} />
                <Form form={form} component={false}>
                    <Table
                        components={{
                            body: {
                                cell: EditableCell,
                            },
                        }}
                        footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>
                            <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                            <Space>
                                <Button onClick={() => setFilteredInfo({})}>{t('tables.clear_filters')}</Button>
                                <Button onClick={() => addFotaHandle()}>{t('tables.fota_table.add_fota')}</Button>
                            </Space>
                        </div>
                        }
                        size='small'
                        bordered
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={false}
                        dataSource={data}
                        loading={responseDataLoading}
                        scroll={{
                            y: 'calc(100vh - 165px)',
                        }}
                        onRow={(record, rowIndex) => {
                            if (!record.is_active) {
                                return {
                                    style: {
                                        background: colorBgContainerDisabled,
                                        color: colorTextDisabled
                                    },
                                }
                            }
                        }}

                        onChange={(pagination, filters, sorter) => { setFilteredInfo(filters) }}

                    // onChange={tablePropOnChange} 
                    />
                </Form>
            </div>
        </>
    )
}

export default Fota
