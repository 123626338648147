import React, { useEffect, useState } from 'react'

import LightThemeBg from '../../../assets/larry/LightThemeBg.jpg'
import LDLogo1 from '../../../assets/larry/LDLogo1.png';
import { Slide, toast } from 'react-toastify';

import PageLoading from '../../../components/loadings/PageLoading'
import './Login.css'
import { Button, Col, Form, Input, Row, Select } from 'antd'
import { useTranslation } from 'react-i18next';
import { SEO_HELMET } from '../../../core/utils/helmet';
import { login } from '../../../core/actions/auth/authActions';
import { useDispatch } from 'react-redux';
import { reset } from '../../../core/stores/auth/authSlice';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


// const history = createBrowserHistory();

function Login() {
    /* -------------------------------------------------------------------------- */
    //TODO -                             i18n                                */
    /* -------------------------------------------------------------------------- */
    const { t, i18n } = useTranslation();

    /* -------------------------------------------------------------------------- */
    //TODO -                           STATES                                     */
    /* -------------------------------------------------------------------------- */
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const location = useLocation();
    const language = document.cookie
                        .split('; ')
                        .find(row => row.startsWith('language')) ?
                        document.cookie
                            .split('; ')
                            .find(row => row.startsWith('language'))
                            .split('=')[1]
                        : 'en'

    const {
        authReducers: { loading, error, loginSuccess },
    } = useSelector((state) => state);


    /* -------------------------------------------------------------------------- */
    //TODO -                             USE-FORM                                   */
    /* -------------------------------------------------------------------------- */

    const [form] = Form.useForm();
    

    /* -------------------------------------------------------------------------- */
    //TODO -                           FUNCTIONS                                     */
    /* -------------------------------------------------------------------------- */
    const loginHandle = async () => {
        dispatch(login(username, password))
    }

    useEffect(() => {
        i18n.changeLanguage(language)
    }, [])

    // useEffect(() => {
    //     window.location.reload()
    // }, [location.pathname])

    useEffect(() => {
        error && toast.error(`${error}`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            transition: Slide
        })
    }, [error])

    useEffect(() => {
        if (loginSuccess) {
            navigate('/')
        }
        dispatch(reset())
    }, [loginSuccess])


    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        document.cookie = `language=${lang}; max-age=31536000;`
    }


    // const findLanguage = () => {
    //     if (i18n.language === 'tr-TR' || i18n.language === 'tr') {
    //         return 'tr'
    //     }
    //     else if (i18n.language === 'en-US' || i18n.language === 'en') {
    //         return 'en'
    //     }
    //     else if (i18n.language === 'de-DE' || i18n.language === 'de') {
    //         return 'de'
    //     }
    // }

    return (
        <div id='LoginPage'>
            <SEO_HELMET title={`LOGIN`} />
            {loading === true ? <div id='LoginLoading'><PageLoading /></div> : ''}
            <div className='LoginPage_Bg_Img' style={{ backgroundImage: `url(${LightThemeBg})` }}>
            </div>
            <div className='LoginPage_LoginCard'>
                <div className='LoginPage_LoginCard_blur'></div>
                <div className='LoginPage_LoginCard_content'>
                    <img style={{ width: '150px' }} src={LDLogo1} srcSet='' />
                    <h3>
                        Larry Dashboard
                    </h3>
                    <h6>
                        {t('pages.login.login_title')} <br />
                    </h6>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={loginHandle}
                    >
                        <Row gutter={[10, 0]} >
                            <Col span={24}>
                                <Form.Item
                                    name='username'
                                    label={t('pages.login.username')}
                                    required tooltip={t('required')}
                                    rules={
                                        [
                                            { required: true, message: t('messages.info.required_username') },
                                        ]
                                    }>
                                    <Input
                                        onChange={(e) => setUsername(e.target.value)}
                                        name='username'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name='password'
                                    label={t('pages.login.password')}
                                    required tooltip={t('required')}
                                    rules={
                                        [
                                            { required: true, message: t('messages.info.required_password') },
                                        ]
                                    }>
                                    <Input.Password
                                        onChange={(e) => setPassword(e.target.value)}
                                        name='password'
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name='language'
                                    label={t('pages.login.language')}
                                    initialValue={language}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        options={[
                                            {
                                                value: 'tr',
                                                label: 'Türkçe',
                                            },
                                            {
                                                value: 'en',
                                                label: 'English',
                                            },
                                            {
                                                value: 'de',
                                                label: 'Deutsch',
                                            },
                                        ]}
                                        onChange={(value) => changeLanguage(value)}
                                    />
                                </Form.Item>

                            </Col>

                        </Row>
                        <Row className='footer_buttons_part' justify="center" align="center" >
                            <Button type='primary' htmlType="submit">
                                {t('pages.login.buttons')}
                            </Button>
                        </Row>


                    </Form>
                </div>

            </div >
        </div >
    )
}


export default Login  
