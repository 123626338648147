import { createSlice } from '@reduxjs/toolkit'


export const antdModalControllerSlice = createSlice({
    name: 'antdModalController',
    initialState: {
        modalId: '',
        okText: '',
        onOk: null,
        isModalOpen: false,
        modalData: <></>,
        modalCaption: "",
        modalOnClose: null,
        closeButton: true,
        backButton: false,
        backButtonFn: null,
        backCompData: <></>,
        footer: null,
        style: {},
        width: '100%',
        destroyOnClose: true,
        closable: true,
        onlineOperators: [],
        runFuncHandle: () => { }
    },

    reducers: {
        setFooterAntd: (state, action) => {
            state.footer = action.payload
        },
        setDestroyOnCloseAntd: (state, action) => {
            state.destroyOnClose = action.payload
        },
        setOnOkAntd: (state, action) => {
            state.onOk = action.payload
        },
        setOkTextAntd: (state, action) => {
            state.okText = action.payload
        },
        setWidthAntd: (state, action) => {
            state.width = action.payload
        },
        setStyleAntd: (state, action) => {
            state.style = action.payload
        },
        setModalIdAntd: (state, action) => {
            state.modalId = action.payload
        },
        setIsModalOpenAntd: (state, action) => {
            state.isModalOpen = action.payload
        },
        setModalDataAntd: (state, action) => {
            state.modalData = action.payload
        },
        setModalCaptionAntd: (state, action) => {
            state.modalCaption = action.payload
        },
        setModalOnCloseAntd: (state, action) => {
            state.modalOnClose = action.payload
        },
        setIsCloseButtonAntd: (state, action) => {
            state.closeButton = action.payload
        },
        setBackButtonAntd: (state, action) => {
            state.backButton = action.payload
        },
        setBackButtonFnAntd: (state, action) => {
            state.backButtonFn = action.payload
        },
        setBackCompDataAntd: (state, action) => {
            state.backCompData = action.payload
        },
        setRunFuncHandle: (state, action) => {
            state.runFuncHandle = action.payload
        },

        setClosableAntd: (state, action) => {
            state.closable = action.payload
        },

        setOnlineOperatorsAntd: (state, action) => {
            state.onlineOperators = action.payload
        }
    }
})


export const { setRunFuncHandle, setFooterAntd, setDestroyOnCloseAntd, setOnOkAntd, setOkTextAntd, setWidthAntd, setStyleAntd, setModalIdAntd, setIsModalOpenAntd, setModalDataAntd, setModalCaptionAntd, setModalOnCloseAntd, setIsCloseButtonAntd, setBackButtonAntd, setBackButtonFnAntd, setBackCompDataAntd, setClosableAntd, setOnlineOperatorsAntd } = antdModalControllerSlice.actions

export default antdModalControllerSlice.reducer