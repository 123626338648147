import { createSlice } from "@reduxjs/toolkit";

export const fotaLogSlice = createSlice({
	name: "fotaLogReducers",
	initialState: {
		error: null,
		loading: false,
		success: false,
		fotaLog: {},
		fotaLogs: [],
	},
	reducers: {
		// Fota Logs
		fotaLogListRequest: (state) => {
			state.loading = true;
		},
		fotaLogListSuccess: (state, action) => {
			state.loading = false;
			state.fotaLogs = action.payload;
			state.success = true;
			state.more_data = action.payload.more_data;
		},
		fotaLogListFail: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.more_data = false;
		},

		// Reset Actions
		fotaLogListReset: (state) => {
			state.loading = false;
			state.success = false;
			state.error = null;
			state.more_data = false;
		},

		// Reset Slice
		fotaLogSliceReset: (state) => {
			state.loading = false;
			state.success = false;
			state.error = null;
			state.fotaLog = null;
			state.fotaLogs = null;
		},
	},
});

export const {
	fotaLogListRequest,
	fotaLogListSuccess,
	fotaLogListFail,
	fotaLogListReset,

	fotaLogSliceReset,
} = fotaLogSlice.actions;
