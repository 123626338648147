import { Button, Table, Typography, theme } from 'antd';
import React from 'react'
import { useState } from 'react';
import { routes } from '../../../core/route/router';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { t } from 'i18next';


function SetupLogTable({setupLogs, loading, gatewayGMT}) {

    const [filteredInfo, setFilteredInfo] = useState({});
    const location = useLocation();

	const {
		token: {
			colorInfo,
			colorSuccess,
			colorError,
			colorWarning,
			colorErrorActive
		}
	} = theme.useToken();

    const columns = [
		{
			title: t("tables.date"),
			dataIndex: "timestamp",
			key: "timestamp",
			width: 100,
			sorter: (a, b) => a.timestamp - b.timestamp,
			render: (text, record) => {
				if (gatewayGMT)
					return (
						<span>
							{moment(parseInt(record?.timestamp)).utcOffset(gatewayGMT - 12).format("DD/MM/YY HH:mm")}
						</span>
					);
				return (
					<span>
						{moment(parseInt(record?.timestamp)).format("DD/MM/YY HH:mm")}
					</span>
				);
			},
		},
		{
			title: t("tables.status"),
			dataIndex: "setup_status",
			key: "setup_status",
			filters: [
				...new Set(setupLogs.map((item) => item.setup_status)),
			].map((item) => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: t(`tables.setup_table.filters.${item}`), value: item })),
			filterSearch: true,
			filteredValue: filteredInfo.setup_status || null,
			onFilter: (value, record) =>
				record.setup_status.indexOf(value) === 0,
			render: (text, record) => {
				return (
					record?.setup_status && (
						<div>
							{text === "success" ? (
								<span style={{color: colorSuccess}}>
									{t(
										`tables.setup_table.filters.${text}`
									).toUpperCase()}
								</span>
							) : text === "cancelled" ? (
								<span style={{color: colorError}}>
									{t(
										`tables.setup_table.filters.${text}`
									).toUpperCase()}
								</span>
							) : text === "failed" ? (
								<span style={{color: colorErrorActive}}>
									{t(
										`tables.setup_table.filters.${text}`
									).toUpperCase()}
								</span>
							) : (
								<span style={{color: colorWarning}}>
									{t("tables.unknown").toUpperCase()}
								</span>
							)}
						</div>
					)
				);
			},
			width: 110,
		},
		{
			title: t("tables.gateway_id"),
			dataIndex: "device_serial",
			key: "device_serial",
			filters: [
				...new Set(setupLogs.map((item) => item.device_serial)),
			].map((item) => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })),
			filterSearch: true,
			filteredValue: filteredInfo.device_serial || null,
			onFilter: (value, record) =>
				record.device_serial.indexOf(value) === 0,
			render: (text, record) => {
				return (
					<div>
						<Typography.Link
							href={`${process.env.REACT_APP_MEDIA_URL}${routes.gateway_detail.path}/${text}`}
							target="_blank"
							rel="noreferrer"
						>
							{text}
						</Typography.Link>
					</div>
				);
			},
			width: 150,
		},
		{
			title: t("tables.customer_id"),
			dataIndex: "user_uuid",
			key: "user_uuid",
			filters: [...new Set(setupLogs.map((item) => item.user_uuid))].map(
				(item) => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })
			),
			filterSearch: true,
			filteredValue: filteredInfo.user_uuid || null,
			onFilter: (value, record) => record.user_uuid.indexOf(value) === 0,
			render: (text, record) =>
				text === "Unknown" ? t("tables.unknown") : text,
			width: 170,
		},
		{
			title: t("tables.location"),
			dataIndex: ["setup_location", "countryCode"],
			key: "countryCode",
			width: 100,
			filters: [
				...new Set(
					setupLogs.map((item) => item.setup_location?.countryCode)
				),
			].map((item) => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })),
			filterSearch: true,
			filteredValue: filteredInfo.countryCode || null,
			onFilter: (value, record) =>
				record.setup_location?.countryCode.indexOf(value) === 0,
			render: (text, record) =>
				text === "Unknown" || !text ? t("tables.unknown") : text,
		},
		{
			title: t("tables.tenant_id"),
			dataIndex: "tenant_id",
			key: "tenant_id",
			filters: [...new Set(setupLogs.map((item) => item.tenant_id))].map(
				(item) => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })
			),
			filterSearch: true,
			filteredValue: filteredInfo.tenant_id || null,
			onFilter: (value, record) => record.tenant_id.indexOf(value) === 0,
			render: (text, record) =>
				text === "Unknown" ? t("tables.unknown") : text,
			width: 100,
		},
		{
			title: t("tables.app_version"),
			dataIndex: "app_version",
			key: "app_version",
			filters: [
				...new Set(setupLogs.map((item) => item.app_version)),
			].map((item) => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })),
			filterSearch: true,
			filteredValue: filteredInfo.app_version || null,
			onFilter: (value, record) =>
				record.app_version.indexOf(value) === 0,
			render: (text, record) =>
				text === "Unknown" ? t("tables.unknown") : text,
			width: 100,
		},
		{
			title: "",
			dataIndex: "",
			key: "detail",
			width: 50,
			render: (text, record) => {
				return (
					<div className="text-center">
						<Typography.Link
							href={`/${routes.setup_log.path}/${record.device_serial}/${record.id}`}
							target="_blank"
							rel="noreferrer"
							style={{color: colorInfo}}
						>
							<i class="fa-solid fa-circle-info fa-xl"></i>
						</Typography.Link>
					</div>
				);
			},
		},
	];

    if (location.pathname.includes(`${routes.setup_log.path}/`) || location.pathname.includes(`${routes.gateway_list.path}`)) {
        columns.splice(2, 1);
    }

    let scroll = { y: '', x: 'max-content' };

    if (location.pathname === `${routes.setup_logs.path}`) {
        columns.splice(2, 0,
            {
                title: t('tables.setup_table.user_name'),
                dataIndex: 'user_name',
                key: 'user_name',
                filters: [...new Set(setupLogs.map(item => item.user_name))].map(item => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })),
                filterSearch: true,
                filteredValue: filteredInfo.user_name || null,
                onFilter: (value, record) => record.user_name.indexOf(value) === 0,
                render: (text, record) => text === 'Unknown' ? t('tables.unknown') : text,
				width: 100,
            },
            {
                title: t('tables.setup_table.user_email'),
                dataIndex: 'user_mail',
                key: 'user_mail',
                filters: [...new Set(setupLogs.map(item => item.user_mail))].map(item => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })),
                filterSearch: true,
                filteredValue: filteredInfo.user_mail || null,
                onFilter: (value, record) => record.user_mail.indexOf(value) === 0,
                render: (text, record) => text === 'Unknown' ? t('tables.unknown') : text,
				width: 100,
            },
        );

        columns.splice(columns.length - 1, 0, {
            title: t('tables.setup_table.device_os'),
            dataIndex: 'device_os',
            key: 'device_os',
            filters: [...new Set(setupLogs.map(item => item.device_os))].map(item => (item === "Unknown" ? { text: t('tables.unknown'), value: item } : { text: item, value: item })),
            filterSearch: true,
            filteredValue: filteredInfo.device_os || null,
            onFilter: (value, record) => record.device_os.indexOf(value) === 0,
            width: 100,
            render: (text, record) => text === 'Unknown' ? t('tables.unknown') : text,
        })

        scroll = { y: 'calc(100vh - 210px)', x: 'max-content' };
    }

    return (
        <Table
            dataSource={[...setupLogs].sort((a, b) => b.timestamp - a.timestamp)}
            columns={columns}
            loading={loading}
            pagination={false}
            onChange={(pagination, filters, sorter) => { setFilteredInfo(filters) }}

            scroll={scroll}

            footer={
                (data) =>
                <div className='d-flex align-items-center justify-content-between'>

                    <div>{t('tables.total_data')}: <b>{data.length}</b> </div>
                    <Button onClick={() => { setFilteredInfo({}); }}>{t('tables.clear_filters')}</Button>
                </div>
            }
        />
    )
}

export default SetupLogTable
