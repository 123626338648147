import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice';
import { createMessageToTicket, getGatewayGMT, getSingleSupportTicketData, updateTicketStatus } from '../../../core/apis/Apis';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Avatar, Badge, Button, Card, Col, Collapse, Descriptions, Divider, Form, Image, Input, Row, Select, Space, Tag, Typography, theme } from 'antd';
import PageLoading from '../../../components/loadings/PageLoading';
import './SupportTicketDetail.scss'
import { useMediaQuery } from 'react-responsive';
import { routes } from '../../../core/route/router';
import { SEO_HELMET } from '../../../core/utils/helmet';


function SupportTicketDetail() {

    const { t } = useTranslation();
    const {
        token: {
            colorPrimary,
            colorTextHeading,
            colorTextLabel,
            colorBgContainer,
            colorTextDescription,
            colorBgBlur
        }
    } = theme.useToken();

    const dispatch = useDispatch();
    const isMobile = useMediaQuery({ query: '(max-width: 576px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 991px)' });
    const isMobileOrTablet = isMobile || isTablet;

    const { gtw_id, ticketID } = useParams();
    const {
        authReducers: { user_info }
    } = useSelector(state => state)
    
    const [ticketData, setTicketData] = React.useState([]);
    const [gatewayGMT, setGatewayGMT] = React.useState(0);
    const [isUpdateButtonActive, setIsUpdateButtonActive] = React.useState(false);
    const [deviceList, setDeviceList] = React.useState([]);
    const [gtwID, setGtwID] = React.useState("None");

    const [messageForm] = Form.useForm();
    const [ticketForm] = Form.useForm();

    const getData = () => {
        getSingleSupportTicketData(ticketID)
            .then(response => {
                if (response.status === 200) {
                    setTicketData(response.data)
                }
            })
            .catch(error => {
                toast.error(t('toast.error_text'), {
                    autoClose: 2000
                })
                console.log("err:",error);
            })

        if (gtw_id)
            getGatewayGMT(gtw_id).then(response => {
                if (response.status === 200) {
                    setGatewayGMT(response.data);
                }
            }).catch(error => {
                console.log("err:",error);
            })
    }

    React.useEffect(() => {
        dispatch(setTitle(t('pages.support_ticket.title')));
        getData();
    }, [dispatch, ticketID]);

    const getLastUpdateTimestamp = () => {
        const lastStatusChangeTimestamp = ticketData?.status_changes?.length > 0 
            ? ticketData?.status_changes[ticketData?.status_changes?.length - 1].ts 
            : null;
    
        const lastMessageTimestamp = ticketData?.messages?.length > 0 
            ? ticketData?.messages[ticketData?.messages?.length - 1].ts 
            : null;
    
        if (lastStatusChangeTimestamp && lastMessageTimestamp) {
            return Math.max(lastStatusChangeTimestamp, lastMessageTimestamp);
        } else if (lastStatusChangeTimestamp) {
            return lastStatusChangeTimestamp;
        } else if (lastMessageTimestamp) {
            return lastMessageTimestamp;
        } else {
            return ticketData?.ticket?.ts;
        }
    }

    const formatLastUpdateTimestamp = () => {
        const lastUpdateTimestamp = moment.unix(getLastUpdateTimestamp());
        const formattedDate = gatewayGMT ? lastUpdateTimestamp.utcOffset(gatewayGMT - 12).format("DD/MM/YYYY, HH:mm") : lastUpdateTimestamp.format("DD/MM/YY, HH:mm");
        return formattedDate;
    }

    React.useEffect(() => {

        if(ticketData?.ticket?.user_device_list?.length > 1)
            gtw_id !== 'None' ? setDeviceList(ticketData?.ticket?.user_device_list) : setDeviceList(["None", ...ticketData?.ticket?.user_device_list])

        setGtwID(ticketData?.ticket?.device_serial ? ticketData?.ticket?.device_serial : "None")
    }, [ticketData, gtw_id])


    const messageHandler = (messageData) => {

        createMessageToTicket(ticketID, messageData)
            .then(response => {
                if (response.status === 200) {
                    messageForm.resetFields();
                    toast.success(t('pages.support_ticket.message_section.message_success_toast'), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })

                    getData();
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(error => {
                console.log("err:",error);
            });
    };

    const ticketUpdateHandler = (ticketFormData) => {

        if (Object.values(ticketFormData).filter((field) => field !== undefined).length === 0) setIsUpdateButtonActive(false);
        else {
            const fieldsToUpdate = ["level", "status", "device_serial"];
            const updatedTicketData = {};

            fieldsToUpdate.forEach(field => {
                if (ticketFormData[field] !== ticketData?.ticket[field] && ticketFormData[field] !== undefined) {
                    updatedTicketData[field] = ticketFormData[field];
                }
            });

            updateTicketStatus(ticketID, updatedTicketData)
                .then(response => {
                    if (response.status === 200) {
                        setIsUpdateButtonActive(false)
                        toast.success(t('pages.support_ticket.ticket_info_section.description_items.ticket_update_toast'), {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                        getData()
                    }
                })
                .catch(error => {
                    console.log("err:",error);
                });
        }
    };


    if (!ticketData) {
        return <PageLoading />;
    };

    const statusMap = {
        "new": <Tag style={{ margin: '0' }} color={'orange'}>{t('tables.support_tickets_table.filters.new')}</Tag>,
        "work_in_proggress": <Tag style={{ margin: '0' }} color={'green'}>{t('tables.support_tickets_table.filters.work_in_proggress')}</Tag>,
        "resolved": <Tag style={{ margin: '0' }} color={'blue'}>{t('tables.support_tickets_table.resolved')}</Tag>,
        "unresolved": <Tag style={{ margin: '0' }} color={'red'}>{t('tables.support_tickets_table.filters.unresolved')}</Tag>,
        "unknown": <Tag style={{ margin: '0' }} color={'gray'}>{t('general.unknown')}</Tag>,
    }

    const descriptionItems = [
        {
            label: t('tables.gateway_id'),
            key: 'device_serial',
            children: isUpdateButtonActive && deviceList.length > 0 ?
                <Form.Item
                    name='device_serial'
                    className='m-0'
                >
                    <Select
                        defaultValue={gtwID !== 'None' ? gtwID : null}
                        options={deviceList.map((device) => ({
                            label: device !== "None" ? 'GTWY-'+device : t('general.unknown'),
                            value: device !== "None" ? 'GTWY-'+device : null,
                        }))}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                :
                <Typography.Link
                    href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/${gtwID !== 'None' ? gtwID : ''}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {gtwID !== 'None' ? gtwID : t('general.unknown')}
                </Typography.Link>
        },
        {
            label: t('tables.customer_id'),
            key: 'customer_id',
            children: ticketData?.ticket?.customer_id
        },
        {
            label: t('tables.support_tickets_table.ticket_id'),
            key: 'ticket_id',
            children: ticketData?.ticket?.id
        },
        {
            label: t('tables.support_tickets_table.contact_info'),
            key: 'contact_info',
            children: ticketData?.ticket?.contact_mail && ticketData?.ticket?.contact_mail.trim() !== ''  ?
                <Typography.Link href={`mailto:${ticketData?.ticket?.contact_mail}`}>{ticketData?.ticket?.contact_mail}</Typography.Link> : t('general.unknown')
        },
        {
            label: t('tables.support_tickets_table.contact_info'),
            key: 'contact_info',
            children: ticketData?.ticket?.contact_phone && ticketData?.ticket?.contact_phone.trim() !== '' ?
                <Typography.Link href={`mailto:${ticketData?.ticket?.contact_phone}`}>{ticketData?.ticket?.contact_phone}</Typography.Link> : t('general.unknown')
        },
        {
            label: t('tables.support_tickets_table.support_level'),
            key: 'support_level',
            children: isUpdateButtonActive ?
                <Form.Item
                    name='level'
                    className='m-0'
                >
                    <Select
                        defaultValue={ticketData?.ticket?.level || t('general.unknown')}
                        options={[
                            { label: 1, value: 1 },
                            { label: 2, value: 2 },
                            { label: 3, value: 3 }
                        ]}
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                :
                ticketData?.ticket?.level || t('general.unknown')
        },
        {
            label: t('tables.status'),
            key: 'status',
            children: isUpdateButtonActive ?
                <Form.Item
                    name='status'
                    className='m-0'
                >
                    <Select
                        defaultValue={ticketData?.ticket?.status || "unknown"}
                        options={
                            Object.keys(statusMap).filter((status) => status !== "unknown").map((status) => ({
                                label: statusMap[status],
                                value: status,
                            }))
                        }
                        style={{ width: "100%" }}
                    />
                </Form.Item>
                :
                statusMap[ticketData?.ticket?.status] || t('general.unknown')
        },
        {
            label: t('pages.support_ticket.ticket_info_section.description_items.ticket_sent_page'),
            key: 'ticket_page',
            children: ticketData?.ticket?.ticket_page || t('general.unknown')
        },
        {
            label: t('tables.app_version'),
            key: 'app_version',
            children: ticketData?.ticket?.app_version || t('general.unknown')
        },
        {
            label: t('tables.setup_table.device_os'),
            key: 'device_os',
            children: ticketData?.ticket?.device_os || t('general.unknown')
        },
        {
            label: t('pages.support_ticket.ticket_info_section.description_items.device_version'),
            key: 'device_version',
            children: ticketData?.ticket?.device_system_version || t('general.unknown')
        },
        {
            label: t('pages.support_ticket.ticket_info_section.description_items.last_update'),
            key: 'last_update',
            children: formatLastUpdateTimestamp()
        }
    ]

    const changeTypeTranslationMap = {
        "status": t('tables.status'),
        "level": t('tables.support_tickets_table.support_level'),
        "device": t('tables.gateway_id')
    }

    const changeTypeTagMap = {
        "status": {
            "new": <Tag style={{ margin: '0' }} color={'orange'}>{t('tables.support_tickets_table.filters.new')}</Tag>,
            "work_in_proggress": <Tag style={{ margin: '0' }} color={'green'}>{t('tables.support_tickets_table.filters.work_in_proggress')}</Tag>,
            "resolved": <Tag style={{ margin: '0' }} color={'blue'}>{t('tables.support_tickets_table.resolved')}</Tag>,
            "unresolved": <Tag style={{ margin: '0' }} color={'red'}>{t('tables.support_tickets_table.filters.unresolved')}</Tag>,
            "unknown": <Tag style={{ margin: '0' }} color={'gray'}>{t('general.unknown')}</Tag>,
        },
        "level": {
            "1": <Tag style={{ margin: '0' }} color={'orange'}>1</Tag>,
            "2": <Tag style={{ margin: '0' }} color={'green'}>2</Tag>,
            "3": <Tag style={{ margin: '0' }} color={'blue'}>3</Tag>,
            "unknown": <Tag style={{ margin: '0' }} color={'gray'}>{t('general.unknown')}</Tag>,
        },
        "device": {
            "None": <Tag style={{ margin: '0' }} color={'gray'}>{t('general.unknown')}</Tag>,
        }
    }

    return (
        <>
            <SEO_HELMET title={`${t('pages.support_ticket.title')} / ${gtwID !== 'None' ? gtwID : t('general.unknown')}`} />
            <Row className='justify-content-center h-100 py-4'>
                <Col sm={24} md={22} lg={10}>
                    <Row>
                        <Row className='w-100'>
                            <div className="owner-info">
                                <Avatar size={48} shape='square' className='shadow-sm me-3' style={{background: colorBgContainer}} icon={<i style={{color: colorPrimary}} className="fa-solid fa-user"></i>} />
                                <div className='owner-info-2'>
                                    <div className='fullname-cover'>
                                        <span className='fullname' style={{color: colorTextHeading}}>{ticketData?.ticket && ticketData?.ticket?.customer_id && ticketData?.ticket?.customer_id}</span>
                                    </div>
                                    <div>
                                        <Badge size='small' status="success" color={colorPrimary} text={<span className='user-company' style={{color: colorTextLabel}}>{ticketData?.ticket && ticketData?.ticket?.tenant_name}</span>} />
                                        <Badge size='small' status="success" color={colorPrimary} text={
                                            <span className='time' style={{color: colorTextLabel}}>{
                                                ticketData?.ticket && (
                                                    gatewayGMT ? moment.unix(ticketData?.ticket?.ts).utcOffset(gatewayGMT - 12).format("DD/MM/YY, HH:mm")
                                                    : moment.unix(ticketData?.ticket?.ts).format("DD/MM/YY, HH:mm")
                                                )
                                            }</span>
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </Row>
                        
                        <Row className='ms-1'>
                            <div className='ticket-message-font w-100 my-4 px-4 border-start border-3 border-secondary' style={{color: colorTextHeading}}>
                                {ticketData?.ticket?.first_message}
                            </div>

                            {ticketData?.ticket?.image &&
                                <div className='rounded-5 shadow' style={{background: colorBgBlur}}>
                                    <Image
                                        width={isMobileOrTablet ? 200 : 300}
                                        preview={{
                                            src: ticketData?.ticket?.image,
                                            
                                        }}
                                        alt=""
                                        src={ticketData?.ticket?.image}
                                        className='img-fluid rounded-5 shadow'
                                    />
                                </div>
                            }
                        </Row>
                    </Row>

                    <Divider orientation='center' className='mt-4' plain>{t('pages.support_ticket.message_section.messages')}</Divider>

                    <Row>
                        <small style={{color: colorTextLabel}}>{t('pages.support_ticket.message_section.messages')}: &nbsp;&nbsp;<strong>{ticketData?.messages?.length || '0'}</strong></small>

                        {ticketData?.messages?.map((message, index) => (
                            <Card key={index} className='my-2 w-100'>
                                <Card.Meta
                                    avatar={<i className="fa-solid fa-user"></i>}
                                    title={
                                        <>
                                            <Row className='align-items-center justify-content-between'>
                                                <div className='left'>
                                                    <span className='me-3'>{message?.user_id}</span>
                                                    <Badge size='small' status="success" color={colorPrimary} text={<span style={{ fontSize: '13px', color: colorTextLabel }}>{message?.tenant_name}</span>} />
                                                </div>
                                                <div className='right'>
                                                    <span style={{ fontSize: '13px', color: colorTextLabel }}>{
                                                        gatewayGMT ? moment.unix(message.ts).utcOffset(gatewayGMT - 12).format("DD/MM/YY, HH:mm")
                                                        : moment.unix(message.ts).format("DD/MM/YY, HH:mm")
                                                    }</span>
                                                </div>
                                            </Row>
                                            {message?.user_phone && <span style={{ fontSize: '13px', color: colorTextDescription }}>{t('pages.support_ticket.message_section.phone')}: <Typography.Link style={{fontSize: '13px'}} href={`tel:${message.user_phone}`}>+{message.user_phone}</Typography.Link><br/></span>}
                                            {message?.user_email && <span style={{ fontSize: '13px', color: colorTextDescription }}>{t('pages.support_ticket.message_section.email')}: <Typography.Link style={{fontSize: '13px'}} href={`mailto:${message.user_email}`}>{message.user_email}</Typography.Link></span>}
                                        </>
                                    }
                                    description={
                                        <p style={{color: colorTextHeading}}>
                                            {message.message_body}
                                        </p>
                                    }
                                />
                            </Card>
                        ))}
                    </Row>

                    <Divider orientation='center' plain>{t('pages.support_ticket.message_section.send_message')}</Divider>

                    <Form
                        layout='vertical'
                        form={messageForm}
                        className='w-100'
                        onFinish={messageHandler}
                    >
                        <Form.Item
                            name='message_body'
                            label={t('pages.support_ticket.message_section.message_label')}
                            required tooltip={t('general.required_field')}
                            rules={
                                [
                                    { required: true, message: t('pages.support_ticket.message_section.message_required') },
                                ]
                            }
                        >
                            <Input.TextArea showCount maxLength={1000} />
                        </Form.Item>

                        <Form.Item className='mt-5'>
                            <Button type="primary" htmlType="submit" className='float-end'>
                                {t('pages.support_ticket.message_section.send')}
                            </Button>
                        </Form.Item>

                    </Form>
                </Col>
                
                <Col sm={24} md={22} lg={1}>
                    <Divider type={isMobileOrTablet ? "horizontal" : "vertical"} className={isMobileOrTablet ? 'mb-5' : 'h-100 mb-4 mx-4'} style={{backgroundColor: '#6c757d', width: isMobile ? '300px' : '2px'}}/>
                </Col>

                <Col sm={24} md={22} lg={10} xl={7}>
                    <Row>
                        <Form
                            form={ticketForm}
                            onFinish={ticketUpdateHandler}
                            className='w-100'
                        >
                            <Descriptions
                                title={t('pages.support_ticket.ticket_info_section.description_items.title')}
                                size='small'
                                column={1}
                                bordered
                                items={descriptionItems}
                                extra={
                                    <>
                                        {isUpdateButtonActive === false ?
                                            <Button
                                                onClick={() => {
                                                    if (user_info?.permissions?.support_tickets_detail_page_ticket_level_update || user_info?.permissions?.support_tickets_detail_page_ticket_status_update) {
                                                        setIsUpdateButtonActive(true)
                                                    } else {
                                                        toast.error("You do not have the necessary authorization", {
                                                            position: "top-right",
                                                            autoClose: false,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                        });
                                                    }
                                                }}
                                                htmlType='button'
                                                size='small'
                                                type='primary'
                                            >
                                                {t('general.update')}
                                            </Button>
                                            :
                                            <Space>
                                                <Button
                                                    onClick={() => setIsUpdateButtonActive(false)}
                                                    size='small'
                                                    type='primary'
                                                    danger={true}
                                                >
                                                    {t('general.cancel')}
                                                </Button>
                                                <Button
                                                    htmlType='submit'
                                                    size='small'
                                                    type='primary'
                                                >
                                                    {t('general.save')}
                                                </Button>
                                            </Space>
                                        }
                                    </>
                                }
                            />
                        </Form>
                    </Row>

                    {ticketData?.status_changes?.length > 0 &&
                    <Row className='my-4'>
                        <Collapse size='small' defaultActiveKey={['1']} className='w-100'>
                            <Collapse.Panel header={t('pages.support_ticket.ticket_info_section.history_panel.title')} key="1">
                                {[...ticketData?.status_changes]?.reverse().map((statusChange, index) => 
                                    <Card
                                        key={index + "statusChange"}
                                        size='small'
                                        title={statusChange.user_id ? statusChange.user_id : t('general.unknown')}
                                        extra={
                                            gatewayGMT ? moment.unix(statusChange.ts).utcOffset(gatewayGMT - 12).format("DD/MM/YY, HH:mm")
                                            : moment.unix(statusChange.ts).format("DD/MM/YY, HH:mm")
                                        }
                                        className='my-3'
                                    >
                                        <Row className='gap-3'>
                                            <Col>
                                                <strong>{changeTypeTranslationMap[statusChange.change_type] || statusChange.change_type}</strong>:
                                            </Col>

                                            <Col>
                                                <Row className='gap-3'>
                                                    <Col>
                                                        {changeTypeTagMap[statusChange.change_type][statusChange.old_value] ||
                                                            <Tag color={'gray'}>{statusChange.old_value}</Tag>
                                                        }
                                                    </Col>
                                                    <Col>
                                                        <i className="fa-solid fa-arrow-right"></i>
                                                    </Col>
                                                    <Col>
                                                        {changeTypeTagMap[statusChange.change_type][statusChange.new_value] ||
                                                            <Tag color={'gray'}>{statusChange.new_value}</Tag>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                )}
                            </Collapse.Panel>
                        </Collapse>
                    </Row>
                    }
                </Col>
            </Row>
        </>
    )
}

export default SupportTicketDetail