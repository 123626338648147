import { createSlice } from '@reduxjs/toolkit'


export const menuControllerSlice = createSlice({
  name: 'menuController',
  initialState: {
    isOpen: true,
  },

  reducers: {
    setOpenCloseMenu: (state, action) => {
      state.isOpen = action.payload

    },
  }
})


export const { setOpenCloseMenu } = menuControllerSlice.actions

export default menuControllerSlice.reducer