import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
// import { setOpenCloseMenu } from '../../core/stores/menuControllerSlice';
import './LeftSideMenu.css'


import { routes } from '../../core/route/router'
// import permission from '../../assets/icons/permission.png'
// import modification from '../../assets/icons/modification.png'
// import database from '../../assets/icons/database.png'
// import HomePageSVG from '../svg/HomePageSVG';
// import RouteSVG from '../svg/RouteSVG';
// import MapSVG from '../svg/MapSVG';
// import TrashSVG from '../svg/TrashSVG';
// import PlanningSVG from '../svg/PlanningSVG';
// import NotificationSVG from '../svg/NotificationSVG';
// import UsersSVG from '../svg/UsersSVG';
// import SettingsSVG from '../svg/SettingsSVG';
// import DriverSVG from '../svg/DriverSVG';
// import SetupSVG from '../svg/SetupSVG';
// import LogoSVG from '../svg/LogoSVG';
// import DeviceManagementSVG from '../svg/DeviceManagementSVG';
// import ConstanceSVG from '../svg/ConstanceSVG';
// import DeviceSettingsSVG from '../svg/DeviceSettingsSVG';
import logoWhite from '../../assets/larry/logoWhite.png';
import { useMediaQuery } from 'react-responsive';
import packageJson from '../../../package.json'
import { useTranslation } from 'react-i18next'
import { theme } from 'antd'



function LeftSideMenu() {

  const menuController = useSelector(state => state.menuController)
  const {
    authReducers : { user_info, isDarkMode }
  } = useSelector((state) => state)
  const {t} = useTranslation();

  const {
    token: {
      // colorBgElevated,
      colorBorderSecondary,
      // colorBorder,
      // colorPrimary
    }
  } = theme.useToken();


  /* -------------------------------------------------------------------------- */
  //TODO -                             MEDIAQUERY                                */
  /* -------------------------------------------------------------------------- */
  // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  // const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })



  var spanStyle = menuController.isOpen ? { display: 'none' } : { display: 'block', alignSelf: 'center', float: 'right', textAlign: 'right', marginRight: '5px' }
  var menuItemStyle = menuController.isOpen ? { justifyContent: 'center' } : { justifyContent: 'flex-start' }

  return (
    (() => {

      //todo TABLETTEN KüÜÇÜK DEĞERLERDE GÖRÜNECEK ( 1224 px küçük )
      if (isTabletOrMobile) {

        return <div id='LeftSideMenu'
          style={{background: isDarkMode ? colorBorderSecondary : '', width: menuController.isOpen ? '0px' : '60px' }}  
        >
          <div className="LeftSideMenu-cover">
            <div className="LeftSideMenu-menus">

              <NavLink to={routes.home_page.path} className="LeftSideMenu-item-logo"
                style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: menuController.isOpen ? '50px' : '0' }}
              >
                <img style={{ height: '30px', width: '35px' }} src={logoWhite} srcSet='' alt='logo'/>
              </NavLink>

              {!user_info?.password_expired &&

                <>

                  <NavLink style={{ justifyContent: 'center', display: menuController.isOpen ? 'none' : 'block', }} to={routes.gateway_list.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                    <i className="fa-solid fa-table menu-items"></i>
                  </NavLink>

                  {user_info?.permissions?.support_tickets_page_read &&
                    <NavLink style={{ justifyContent: 'center', display: menuController.isOpen ? 'none' : 'block', }} to={routes.support_ticket.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-ticket menu-items"></i>
                    </NavLink>
                  }

                  {user_info?.permissions?.settings_page_read &&
                    <NavLink style={{ justifyContent: 'center', display: menuController.isOpen ? 'none' : 'block', }} to={routes.settings.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-gears menu-items"></i>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={{ justifyContent: 'center', display: menuController.isOpen ? 'none' : 'block', }} to={routes.notifications.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-bell menu-items"></i>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={{ justifyContent: 'center', display: menuController.isOpen ? 'none' : 'block', }} to={routes.setup_logs.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-satellite-dish menu-items"></i>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={{ justifyContent: 'center', display: menuController.isOpen ? 'none' : 'block', }} to={routes.fota.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-code-compare menu-items"></i>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={{ justifyContent: 'center', display: menuController.isOpen ? 'none' : 'block', }} to={routes.node_list.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-list-ul menu-items"></i>
                    </NavLink>
                  }
                </>
              }

              <span style={{position: 'fixed', bottom: '5px', color: 'white', fontWeight: 'bold'}}>v{packageJson.version}</span>

            </div>
          </div>
        </div>
      }
      //todo TABLETTEN BÜYÜK DEĞERLERDE GÖRÜNECEK ( 1224 px büyük )
      else if (isDesktopOrLaptop) {
        return <div id='LeftSideMenu' style={{background: isDarkMode ? colorBorderSecondary : '', width: menuController.isOpen ? '60px' : '140px' }}>
          <div className="LeftSideMenu-cover">
            <div className="LeftSideMenu-menus">

              <NavLink to={routes.home_page.path} className="LeftSideMenu-item-logo" style={{ height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ height: '30px', width: '35px' }} src={logoWhite} srcSet='' alt='logo'/>
              </NavLink>

              <NavLink style={menuItemStyle} to={routes.gateway_list.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                <i className="fa-solid fa-table menu-items"></i>
                <span style={spanStyle}>{t(`left_side_menu.${routes.gateway_list.title}`)}</span>
              </NavLink>

              {!user_info?.password_expired &&
                <>
                  {user_info?.permissions?.support_tickets_page_read &&
                    <NavLink style={menuItemStyle} to={routes.support_ticket.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-ticket menu-items"></i>
                      <span style={spanStyle}>{t(`left_side_menu.${routes.support_ticket.title}`)}</span>
                    </NavLink>
                  }

                  {user_info?.permissions?.settings_page_read &&
                    <NavLink style={menuItemStyle} to={routes.settings.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-gears menu-items"></i>
                      <span style={spanStyle}>{t(`left_side_menu.${routes.settings.title}`)}</span>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={menuItemStyle} to={routes.notifications.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-bell menu-items"></i>
                      <span style={spanStyle}>{t(`left_side_menu.${routes.notifications.title}`)}</span>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={menuItemStyle} to={routes.setup_logs.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-satellite-dish menu-items"></i>
                      <span style={spanStyle}>{t(`left_side_menu.${routes.setup_logs.title}`)}</span>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={menuItemStyle} to={routes.fota.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-code-compare menu-items"></i>
                      <span style={spanStyle}>{t(`left_side_menu.${routes.fota.title}`)}</span>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={menuItemStyle} to={routes.node_list.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-list-ul menu-items"></i>
                      <span style={spanStyle}>{t(`left_side_menu.${routes.node_list.title}`)}</span>
                    </NavLink>
                  }

                  {user_info?.is_superuser &&
                    <NavLink style={menuItemStyle} to={routes.status_analytics.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                      <i className="fa-solid fa-magnifying-glass-chart menu-items"></i>
                      <span style={spanStyle}>{t(`left_side_menu.${routes.status_analytics.title}`)}</span>
                    </NavLink>
                  }

                  <NavLink style={menuItemStyle} to={routes.hotline.path} className={({ isActive }) => "LeftSideMenu-item" + (isActive ? " LeftSideMenu-item_active" : "")}>
                    <i className="fa-solid fa-headset menu-items"></i>
                    <span style={spanStyle}>{t(`left_side_menu.${routes.hotline.title}`)}</span>
                  </NavLink>
                  
                </>
              }

              <span style={{position: 'fixed', bottom: '5px', color: 'white', fontWeight: 'bold'}}>v{packageJson.version}</span>
            </div>
          </div>
        </div>
      }
    })()

  )
}

export default LeftSideMenu
