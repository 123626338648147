import { Button, Descriptions, Form, Input, Radio, Row, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setIsModalOpenAntd } from "../../../core/stores/antdModalControllerSlice";


function LastAlertModal({ alert, mqttClient, userID }) {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isInProgress, setIsInProgress] = useState(alert.ticket_status === 2);
    const [canSubmit, setCanSubmit] = useState(false);

    const monitoredUserDescriptionItems = [
        {
            key: 'monitored_user',
            label: t('description_items.monitored_user'),
            children: alert.hotline_details.monitored_user_name || 'N/A'
        },
        {
            key: 'monitored_user_phone',
            label: t('pages.hotline.hotline_details.phone_number'),
            children: alert.hotline_details.phone_number || 'N/A'
        },
        {
            key: 'monitored_user_email',
            label: t('pages.hotline.hotline_details.email'),
            children: alert.hotline_details.email || 'N/A'
        },
        {
            key: 'monitored_user_address',
            label: t('pages.hotline.hotline_details.address'),
            children: alert.hotline_details.address || 'N/A'
        }
    ]

    const guardianAngelDescriptionItems = [
        {
            key: 'guardian_angel',
            label: t('description_items.guardian_angel'),
            children: alert.hotline_details.guardian_angel_name || 'N/A'
        },
        {
            key: 'guardian_angel_phone',
            label: t('pages.hotline.hotline_details.guardian_angel_phone'),
            children: alert.hotline_details.guardian_angel_phone || 'N/A'
        },
        {
            key: 'guardian_angel_email',
            label: t('pages.hotline.hotline_details.guardian_angel_email'),
            children: alert.hotline_details.guardian_angel_email || 'N/A'
        },
        {
            key: 'guardian_angel_address',
            label: t('pages.hotline.hotline_details.guardian_angel_address'),
            children: alert.hotline_details.guardian_angel_address || 'N/A'
        }
    ]

    const getLastAlertModalBody = () => {
        const alertDescriptionItems = [
            {
                key: 'gateway',
                label: t('description_items.gateway_id'),
                children: alert.hotline_details.gateway
            },
            {
                key: 'alert_type',
                label: t('pages.hotline.alert_type'),
                children: t(`notification_activities.${alert.notif_activity}`)
            },
            {
                key: 'date',
                label: t('description_items.date'),
                children: new Date(alert.created_date).toLocaleString()
            }
        ]
    
        const handleIgnoreAlert = () => {
            dispatch(setIsModalOpenAntd(false))
        }
    
        const handleProcessAlert = () => {
            setIsInProgress(true)
            
            const updatedAlertData = {
                ...alert,
                ticket_status: 2,
                user_id: userID
            }
    
            mqttClient.publish(`sub/${alert.hotline_details.gateway}/hotline`, JSON.stringify(updatedAlertData), { qos: 2 }, (err) => {
                if (err) {
                    console.log(err)
                }
            })
        }

        return <Row gutter={[0,15]} justify={'center'}>
                <Descriptions bordered column={3} style={{width: '100%'}} layout="vertical" items={alertDescriptionItems} />
                
                <Descriptions bordered column={3} style={{width: '100%'}} layout="vertical" items={monitoredUserDescriptionItems} />

                <Descriptions bordered column={3} style={{width: '100%'}} layout="vertical" items={guardianAngelDescriptionItems} />

                <Button type="primary" onClick={handleProcessAlert} className="me-3"> {t('pages.hotline.process_alert')} </Button>

                <Button type="primary" danger onClick={handleIgnoreAlert}> {t('pages.hotline.ignore_alert')} </Button>
            </Row>;
    };

    const getInProgressModalBody = () => {

        const onFinish = (values) => {

            const updatedAlertData = {
                ...alert,
                ...values,
                ticket_status: 3,
                user_id: userID
            }

            mqttClient.publish(`sub/${alert.hotline_details.gateway}/hotline`, JSON.stringify(updatedAlertData), { qos: 2 }, (err) => {
                if (err) {
                    console.log(err)
                }
            })

            dispatch(setIsModalOpenAntd(false))
        };

        const formDescriptionItems = [
            {
                key: 'action_status',
                label: t('pages.hotline.action_status'),
                children:
                    <Form.Item
                        name={'action_status'}
                        initialValue={alert.action_status}
                        style={{marginBottom: '0'}}
                    >
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={1} disabled> {t('pages.hotline.action_statuses.1')} </Radio>
                                <Radio value={2}> {t('pages.hotline.action_statuses.2')} </Radio>
                                <Radio value={3}> {t('pages.hotline.action_statuses.3')} </Radio>
                                <Radio value={4}> {t('pages.hotline.action_statuses.4')} </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
            },
            {
                key: 'call_status',
                label: t('pages.hotline.call_status'),
                children:
                    <Form.Item
                        name={'call_status'}
                        initialValue={alert.call_status}
                        style={{marginBottom: '0'}}
                    >
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={1} disabled> {t('pages.hotline.call_statuses.1')} </Radio>
                                <Radio value={2}> {t('pages.hotline.call_statuses.2')} </Radio>
                                <Radio value={3}> {t('pages.hotline.call_statuses.3')} </Radio>
                                <Radio value={4}> {t('pages.hotline.call_statuses.4')} </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
            },
            {
                key: 'guardian_angel_status',
                label: t('pages.hotline.guardian_angel_status'),
                children:
                    <Form.Item
                        name={'guardian_angel_status'}
                        initialValue={alert.guardian_angel_status}
                        style={{marginBottom: '0'}}
                    >
                        <Radio.Group>
                            <Space direction="vertical">
                                <Radio value={1} disabled> {t('pages.hotline.guardian_angel_statuses.1')} </Radio>
                                <Radio value={2}> {t('pages.hotline.guardian_angel_statuses.2')} </Radio>
                                <Radio value={3}> {t('pages.hotline.guardian_angel_statuses.3')} </Radio>
                                <Radio value={4}> {t('pages.hotline.guardian_angel_statuses.4')} </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
            },
            {
                key: 'report',
                label: t('pages.hotline.event_report'),
                children:
                    <Form.Item
                        name={'report'}
                        initialValue={alert.report}
                        style={{marginBottom: '0'}}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
            }
        ];

        const contactDescriptionItems = [
            monitoredUserDescriptionItems.slice(0, -1),
            guardianAngelDescriptionItems.slice(0, -1)
        ].flat();

        return <Row gutter={[0,15]} justify={'center'}>
            <Form
                layout="vertical"
                style={{width: '100%'}}
                onFinish={onFinish}
                onValuesChange={(_, allValues) => {
                    const hasChanges = Object.keys(allValues).every(key => allValues[key] !== alert[key]);
                    setCanSubmit(hasChanges);
                }}
            >

                <Descriptions bordered column={1} style={{width: '100%'}} size="small" className="mb-3" items={contactDescriptionItems} />
                <Descriptions bordered column={1} style={{width: '100%'}} items={formDescriptionItems} />
                
                <Form.Item
                    style={{textAlign: 'center', paddingTop: '25px', marginBottom: '0'}}
                >
                    <Button type="primary" htmlType="submit" disabled={!canSubmit}> {t('pages.hotline.send_as_resolved')} </Button>
                </Form.Item>
            </Form>
        </Row>;
    };

    if (!isInProgress) return getLastAlertModalBody();
    else return getInProgressModalBody();
};

export default LastAlertModal;