import { SEO_HELMET } from '../../../core/utils/helmet'
import { Button, Col, Descriptions, Row, Spin, Table, Typography } from 'antd'
import useMQTT from '../../../core/utils/useMQTT';
import { setClosableAntd, setIsModalOpenAntd, setModalCaptionAntd, setModalDataAntd, setOnlineOperatorsAntd, setStyleAntd } from '../../../core/stores/antdModalControllerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { listHotlines } from '../../../core/actions/hotline/hotlineActions';
import { useTranslation } from 'react-i18next';
import OnlineOperatorsModal from './OnlineOperatorsModal';
import HotlineDetailModal from './HotlineModal';
import LastAlertModal from './LastAlertModal';

function Hotline() {

    const {
        mqttClient,
        // isConnected,
        // isDisconnected,
        // isReconnecting,
        user
    } = useMQTT();

    const dispatch = useDispatch();
    const [alertTimer, setAlertTimer] = useState(0);
    const [lastAlert, setLastAlert] = useState(null);
    const [dataSource, setDataSource] = useState([]);
    const [totalAlerts, setTotalAlerts] = useState(0)
    const [activeAlerts, setActiveAlerts] = useState(0)
    const [inprogressAlerts, setInprogressAlerts] = useState(0)
    const [onlineGateways, setOnlineGateways] = useState(0)
    const intervalRef = useRef(null);

    const { hotlines, loading, gateways, online_gateways, active_hotlines, inprogress_hotlines, total_hotlines } = useSelector(state => state.hotlineReducers);
    const {
        antdModalController: {
            onlineOperators
        }
    } = useSelector(state => state);
    const { t } = useTranslation();

    const startTimer = () => {
        intervalRef.current = setInterval(() => {
            setAlertTimer(prev => prev + 1);
        }, 1000);
    }

    const stopTimer = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        setAlertTimer(0);
    }

    useEffect(() => {

        if (mqttClient) {

            mqttClient.subscribe("pub/+/hotline", { qos: 0 }, (err) => {
                if (!err) {
                    console.log("Subscribed to pub/+/hotline");
                } else {
                    console.error("Failed to subscribe to pub/+/hotline", err);
                }
            });

            mqttClient.subscribe("sub/+/hotline", { qos: 0 }, (err) => {
                if (!err) {
                    console.log("Subscribed to sub/+/hotline");
                } else {
                    console.error("Failed to subscribe to sub/+/hotline", err);
                }
            });

            mqttClient.on("message", (topic, message) => {
                console.log("Received message from ", topic, message.toString());

                if (topic === "pub/users/hotline") {
                    const online_operators = JSON.parse(message.toString());
                    console.log("Data: ", online_operators);
                    dispatch(setOnlineOperatorsAntd(online_operators));
                } else if (topic.startsWith("sub/") && topic.endsWith("hotline")) {
                    const updatedAlertData = JSON.parse(message.toString());

                    if (updatedAlertData.ticket_status === 2) {
                        setInprogressAlerts(prev => prev + 1);
                        setActiveAlerts(prev => prev - 1);
                    } else if (updatedAlertData.ticket_status === 3) {
                        setInprogressAlerts(prev => prev - 1);
                    }

                    console.log("Updated Alert: ", updatedAlertData);

                    setDataSource(prev => prev.map((alert) => {
                        if (alert.id === updatedAlertData.id) {
                            return updatedAlertData;
                        }
                        return alert;
                    }));
                }
                else {
                    stopTimer();
                    startTimer();
                    setLastAlert(JSON.parse(message.toString()));
                }
            });
        }
    }, [mqttClient, dispatch]);

    useEffect(() => {
        dispatch(listHotlines());
    }, [dispatch])

    useEffect(() => {
        if (hotlines.length > 0) {
            if (hotlines[0]?.ticket_status === 1) {
                setAlertTimer(Math.floor((new Date().getTime() - new Date(hotlines[0].created_date).getTime()) / 1000));
                startTimer();
            }
            console.log("Hotlines: ", hotlines)
            setDataSource(hotlines);
            setTotalAlerts(total_hotlines);
            setActiveAlerts(active_hotlines);
            setInprogressAlerts(inprogress_hotlines);
            setOnlineGateways(online_gateways);
        }
    }, [hotlines])

    useEffect(() => {
        if (lastAlert) {
            getLastAlertModal();
            setDataSource(prev => [lastAlert, ...prev]);
            setTotalAlerts(prev => prev + 1);
            setActiveAlerts(prev => prev + 1);
        }
    }, [lastAlert])

    const getOnlineOperatorsModal = () => {
        dispatch(setModalDataAntd(<OnlineOperatorsModal />))
        dispatch(setStyleAntd({ maxWidth: '350px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setClosableAntd(true))
        dispatch(setModalCaptionAntd(t('description_items.online_operators')))
    }

    const getHotlineDetailModal = (hotline) => {
        dispatch(setModalDataAntd(
            (hotline.ticket_status === 1 || hotline.ticket_status === 2) ?
                <LastAlertModal alert={hotline} mqttClient={mqttClient} userID={user.id} />
            : <HotlineDetailModal hotline={hotline}/>
        ))
        dispatch(setStyleAntd({ maxWidth: '600px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setClosableAntd(true))
        dispatch(setModalCaptionAntd(t('pages.hotline.event_details')))
    }

    const getLastAlertModal = () => {
        dispatch(setModalDataAntd(<LastAlertModal alert={lastAlert} mqttClient={mqttClient} userID={user.id} />))
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setClosableAntd(false))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('pages.hotline.new_alert')))
    }


    const descriptionItems = [
        {
            key: 'gateways',
            label: t('description_items.gateways'),
            children: gateways
        },
        {
            key: 'online_gateways',
            label: t('description_items.online_gateways'),
            children: onlineGateways
        },
        {
            key: 'active_alerts',
            label: t('description_items.active_alerts'),
            children: activeAlerts
        },
        {
            key: 'alerts_inprogress',
            label: t('description_items.alerts_inprogress'),
            children: inprogressAlerts
        },
        {
            key: 'total_alerts',
            label: t('description_items.total_alerts'),
            children: totalAlerts
        },
        {
            key: 'online_operators',
            label: t('description_items.online_operators'),
            children: <Row>
                    <Col span={4}>
                        <Typography.Text>{onlineOperators?.length || 0}</Typography.Text>
                    </Col>
                    <Col span={12}>
                        <Button type="text" size="small" onClick={() => getOnlineOperatorsModal()}>
                            <i className="fa-solid fa-users"></i>
                        </Button>
                    </Col>
                </Row>
        },
        {
            key: 'duration_from_last_alert',
            label: t('description_items.duration_from_last_alert'),
            children:
                (alertTimer > 3600 && alertTimer < 7200) ?
                    `${Math.floor(alertTimer / 3600)} hour ${Math.floor((alertTimer % 3600) / 60)} minutes ${alertTimer % 60} seconds`
                : alertTimer > 3600 ? 
                    `${Math.floor(alertTimer / 3600)} hours ${Math.floor((alertTimer % 3600) / 60)} minutes ${alertTimer % 60} seconds`
                : (alertTimer > 60 && alertTimer < 120) ?
                    `${Math.floor(alertTimer / 60)} minute ${alertTimer % 60} seconds`
                : (alertTimer > 60) ?
                    `${Math.floor(alertTimer / 60)} minutes ${alertTimer % 60} seconds`
                : 
                    `${alertTimer || 0} seconds`
        }
    ]

    const columns = [
        {
            key: 'created_date',
            title: t('pages.hotline.date'),
            dataIndex: 'created_date',
            render: (text) => {
                return <Typography.Text>{new Date(text).toLocaleString()}</Typography.Text>
            }
        },
        {
            title: t('pages.hotline.gateway'),
            dataIndex: ['hotline_details', 'gateway'],
            key: 'gateway'
        },
        {
            title: t('pages.hotline.alert_type'),
            key: 'alert_type',
            dataIndex: 'notif_activity',
            render: (text, record) => {
                return <Typography.Text>{t(`notification_activities.${text}`)}</Typography.Text>
            }
        },
        {
            key: 'status',
            title: t('pages.hotline.ticket_status'),
            dataIndex: 'ticket_status',
            render: (text) => {
                return <Typography.Text>
                    {t(`pages.hotline.ticket_statuses.${text}`)} &nbsp;
                    {
                        (text === 1 && <i className="fa-solid fa-exclamation-triangle text-warning"></i>) ||
                        (text === 2 && <Spin size='small' />) ||
                        (text === 3 && <i className="fa-solid fa-check-circle text-success"></i>)
                    }
                </Typography.Text>
            },
            width: 200
        },
        {
            key: 'detail',
            title: '',
            render: (text, record) => {
                return <Button type="text" disabled={record.ticket_status !== 1 && (!user.is_superuser || !user.is_staff)} onClick={() => getHotlineDetailModal(record)}><i className="fa-solid fa-info-circle"></i></Button>
            },
            width: 70
        }
    ]

    return (
        <>
            <SEO_HELMET title="Hotline" />
            
            <>
                <Row gutter={[0,15]}>
                    {!loading &&
                    <Descriptions bordered style={{width: '100%'}} column={4} size='small' items={descriptionItems} />
                    }
                    <Table
                        dataSource={dataSource}
                        loading={loading}
                        columns={columns}
                        pagination={false}
                        scroll={{ y: "68vh" }}
                        style={{ width: "100%" }}

                        rowKey={(record) => record.id}
                    />
                </Row>
            </>
        </>
    )
}

export default Hotline