import { configureStore } from '@reduxjs/toolkit'
import menuControllerSlice from "./menuControllerSlice"
import modalControllerSlice from "./modalControllerSlice"
import logger from 'redux-logger';
import nullCheckControllerSlice from './nullCheckControllerSlice';
import deviceBoxOperationControllerSlice from './deviceBoxOperationControllerSlice';
import boxShipmentControllerSlice from './boxShipmentControllerSlice';
import companyControllerSlice from './companyControllerSlice';
import toastyControllerSlice from './toastyControllerSlice';
import globalInfoControllerSlice from './globalInformationControllerSlice';
import setupFilteredDataControllerSlice from './setupFilteredDataControllerSlice';
import ModificationStepsControllerSlice from './ModificationStepsControllerSlice';
import containersRealTimeStatusControllerSlice from './containersRealTimeStatusControllerSlice';
import systemCheckControllerSlice from './systemCheckControllerSlice';
import containerStatusInfoControllerSlice from './containerStatusInfoControllerSlice';
import antdModalControllerSlice from './antdModalControllerSlice';
import mapControllerSlice from './mapControllerSclice';
import apiStatusControllerSlice from './apiStatusControllerSlice';
import navbarTitleControllerSlice from './navbarTitleControllerSlice';
import { userGroupSlice } from './userGroupSlice';
import { tenantSlice } from './tenantSlice';
import { gatewaySlice } from './gatewaySlice';
import { setupLogSlice } from './logs/setupLogSlice';
import { nodeLogSlice } from './logs/nodeLogSlice';
import { authSlice } from './auth/authSlice';
import { notificationSlice } from './logs/notificationSlice';
import { fotaLogSlice } from './logs/fotaLogSlice';
import { oldNodeSlice } from './logs/oldNodeSlice';
import deviceStatusSlice from './logs/deviceStatusSlice';
import hotlineSlice from './hotline/hotlineSlice';


export default configureStore({
	reducer: {
		menuController: menuControllerSlice,
		modalController: modalControllerSlice,
		nullCheckController: nullCheckControllerSlice,
		deviceBoxOperationController: deviceBoxOperationControllerSlice,
		boxShipmentController: boxShipmentControllerSlice,
		companyController: companyControllerSlice,
		toastyController: toastyControllerSlice,
		globalInfoController: globalInfoControllerSlice,
		setupFilteredDataController: setupFilteredDataControllerSlice,
		ModificationStepsController: ModificationStepsControllerSlice,
		containersRealTimeStatusController: containersRealTimeStatusControllerSlice,
		containerStatusInfoController: containerStatusInfoControllerSlice,
		antdModalController: antdModalControllerSlice,
		systemCheckController: systemCheckControllerSlice,
		mapController: mapControllerSlice,
		apiStatusController: apiStatusControllerSlice,
		navbarTitleController: navbarTitleControllerSlice,
		userGroupReducers: userGroupSlice.reducer,
		tenantReducers: tenantSlice.reducer,
		gatewayReducers: gatewaySlice.reducer,
		setupLogReducers: setupLogSlice.reducer,
		nodeLogReducers: nodeLogSlice.reducer,
		authReducers: authSlice.reducer,
		notificationReducers: notificationSlice.reducer,
		fotaLogReducers: fotaLogSlice.reducer,
		oldNodeReducers: oldNodeSlice.reducer,
		deviceStatusReducers: deviceStatusSlice,
		hotlineReducers: hotlineSlice,
	},
	devTools: process.env.REACT_APP_ENV === "development",
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			logger,
		}),
});
