import { createSlice } from '@reduxjs/toolkit'


export const toastyControllerSlice = createSlice({
    name: 'toastyController',
    initialState: {
        isToastyOpen: false,
        toastyData: <></>,
        toastyCaption: "",
        toastyVariant: "danger",
        position: 'center'
    },

    reducers: {
        setIsToastyOpen: (state, action) => {
            state.isToastyOpen = action.payload
        },
        setToastyData: (state, action) => {
            state.toastyData = action.payload
        },
        setToastyCaption: (state, action) => {
            state.toastyCaption = action.payload
        },
        setToastyVariant: (state, action) => {
            state.toastyVariant = action.payload
        },
        setPosition: (state, action) => {
            state.position = action.payload
        },
    }
})


export const { setIsToastyOpen, setToastyData, setToastyCaption, setToastyVariant, setPosition } = toastyControllerSlice.actions

export default toastyControllerSlice.reducer