import mqtt from "mqtt";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";



const useMQTT = () => {

    const [client, setClient] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [isDisconnected, setIsDisconnected] = useState(false);

    const {
        authReducers: { user_info: user }
    } = useSelector(state => state);

    useEffect(() => {
        let reconnectAttempts = 0;

        const userData = {
            id: user.id,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email
        }

        try {
            if (user && !client) {
                const options = {
                    host: "dash.meetlarry.io",
                    port: 443,
                    path: '/mqtt',
                    protocol: 'wss',
                    keepalive: 60,
                    clientId: `${user.username}_${user.id}_${user.tenant}_${Date.now()}`,
                    username: "homecare",
                    password: "gesk2017",
                    rejectUnauthorized: false
                };
                const mqttClient = mqtt.connect(options);
                setClient(mqttClient);
                console.log("client: ", mqttClient);

                mqttClient.on("connect", () => {
                    console.log("Connected to MQTT broker");
                    setIsConnected(true);
                    setIsReconnecting(false);
                    setIsDisconnected(false);
                    reconnectAttempts = 0;

                    mqttClient.publish("sub/users/hotline/login", JSON.stringify(userData), { qos: 0 }, (err) => {
                        if (!err) {
                            console.log("Published to sub/users/hotline/login");
                        } else {
                            console.error("Failed to publish to sub/users/hotline/login", err);
                        }
                    });

                });
                
                mqttClient.on("disconnect", () => {
                    console.log("Disconnected to MQTT broker");
                    setIsConnected(false);
                    setIsDisconnected(true);
                });
                
                mqttClient.on("close", (err) => {
                    console.log("Closed connection to MQTT broker", err);
                });

                mqttClient.on("reconnect", () => {
                    reconnectAttempts++;
                    setIsReconnecting(true);

                    if (reconnectAttempts > 5) {
                        mqttClient.end();
                        console.log("Reconnect attempts exceeded. Closing connection to MQTT broker");
                        setIsConnected(false);
                        setIsDisconnected(true);
                        return;
                    }
                    console.log("Reconnecting to MQTT broker", reconnectAttempts);
                });
                
                mqttClient.on("error", (err) => {
                    console.log("Error from MQTT broker", err); 
                });
            }   
        } catch (error) {
            console.error("Error connecting to MQTT broker", error);
        }

        return () => {
            if (client) {
                console.log("MQTT Hook cleanup")
                client.publish("sub/users/hotline/logout", JSON.stringify(userData), { qos: 0 }, (err) => {
                    if (!err) {
                        console.log("Published to sub/users/hotline/logout");
                    } else {
                        console.error("Failed to publish to sub/users/hotline/logout", err);
                    }
                });
                client.end();
                setClient(null);
            }
        }
    }, [user, client]);

    return {
        mqttClient: client,
        isConnected,
        isReconnecting,
        isDisconnected,
        user
    }
};

// const useReconnectingModal = () => {
//     const dispatch = useDispatch();

//     dispatch(setModalDataAntd(<ExportCSVLogsModal gtwID={`GTWY-${record.gtwID}`} nodes={nodes} />))
//     dispatch(setStyleAntd({ maxWidth: '800px' }))
//     dispatch(setIsModalOpenAntd(true))
//     dispatch(setModalCaptionAntd(t('modals.export_csv_modal.title')))
// }


export default useMQTT;