import { createSlice } from '@reduxjs/toolkit'


export const navbarTitleControllerSlice = createSlice({
    name: 'navbarTitleControllerSlice',
    initialState: {
        title: '',
    },

    reducers: {
        setTitle: (state, action) => {
            state.title = action.payload
        },
    }
})


export const { setTitle } = navbarTitleControllerSlice.actions

export default navbarTitleControllerSlice.reducer


