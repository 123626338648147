import React from 'react'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { setTitle } from '../../../../../core/stores/navbarTitleControllerSlice';
import { listNotification } from '../../../../../core/actions/logs/notificationActions';
import { SEO_HELMET } from '../../../../../core/utils/helmet';
import { Button, DatePicker, Descriptions, Input, Row, Table, Typography } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { routes } from '../../../../../core/route/router';
import dayjs from 'dayjs';
import { getGatewayGMT } from '../../../../../core/apis/Apis';
import { useMediaQuery } from 'react-responsive';


function Notifications() {

    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY-MM-DD';

    const {gtw_id} = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMobile = useMediaQuery({ query: '(max-width: 430px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })

    const [filteredInfo, setFilteredInfo] = React.useState(localStorage.getItem('notificationFilters') ? JSON.parse(localStorage.getItem('notificationFilters')) : {});
    const [gatewayGMT, setGatewayGMT] = React.useState(null);

    const date = new Date();

    const today = moment(date).set({ hour: 23, minute: 59, second: 59, millisecond: 59 });
    const twoDaysAgo = moment(date).subtract(2, 'days').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    const [notificationFilters, setNotificationFilters] = React.useState({
        device_serial: gtw_id ? gtw_id : null,
        created_at__gte: twoDaysAgo.format(dateFormat + ' HH:mm:ss'),
        created_at__lte: today.format(dateFormat + ' HH:mm:ss'),
    });

    React.useEffect(() => {
        dispatch(setTitle(t('pages.notifications.title')));

        if (!gtw_id) {
            setNotificationFilters(preFilters => ({
                ...preFilters,
                device_serial: null,
            }))

            const values = ['wake_up_time_exceeded', 'extreme_restroom_duration', 'max_sitting_duration_notification', 'max_bed_time_notification', 'extreme_shower_time_notification', 'extreme_sleep_time_notif', 'fall_down', 'emergency_button_pressed', 'Late Wake Up Notification']
            
            Object.entries(filteredInfo).forEach(([key, value]) => {
                if (!values.includes(value)) {
                    filteredInfo[key] = null;
                }
            });

        } else {
            setNotificationFilters(preFilters => ({
                ...preFilters,
                device_serial: gtw_id,
            }))

            getGatewayGMT(gtw_id).then(res => {
                if (res.status === 200) {
                    setGatewayGMT(res.data);
                }
            }).catch(err => {
                console.log("err:",err);
            })
        }
    
    }, [dispatch, gtw_id])


    React.useEffect(() => {
        dispatch(listNotification(notificationFilters));
    }, [notificationFilters])


    const onChangeRangePicker = (date, dateString) => {
        setNotificationFilters({
            ...notificationFilters,
            created_at__gte: moment(dateString[0]).format(dateFormat + ' HH:mm:ss'),
            created_at__lte: moment(dateString[1]).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).format(dateFormat + ' HH:mm:ss'),
        })
    }

    const {
        notificationReducers: { notifications, loading}
    } = useSelector((state) => state);


    // -----------------------PAGE HEADER------------------------- //
    const descriptionItems = [
        {
            label: t('tables.gateway_id'),
            children: gtw_id ? gtw_id : t('general.all')
        },
        {
            label: t('tables.date'),
            children: (
                <Row className={`${isMobile && 'justify-content-center'}`}>
                    <RangePicker
                        format={dateFormat}
                        onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                        defaultValue={[dayjs(twoDaysAgo.format(dateFormat), dateFormat), dayjs(today.format(dateFormat), dateFormat)]}
                    />
                </Row>
            )
        }
    ]


    // -----------------------TABLE------------------------- //

    const columns = [
        {
            title: t('tables.date'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            sorter: (a, b) => a.timestamp - b.timestamp,
            render: (text, record) => {
                if (gatewayGMT)
                    return (
                        <span>{moment.unix(parseInt(record.timestamp)).utcOffset(gatewayGMT - 12).format('DD/MM/YYYY - HH:mm:ss')}</span>
                    )
                return (
                    <span>{moment.unix(parseInt(record.timestamp)).format('DD/MM/YYYY - HH:mm:ss')}</span>
                )
            }
        },
        {
            title: t('tables.notifications_table.notification'),
            dataIndex: 'activity',
            key: 'activity',
            filters: [...new Set(notifications.map(item => item.activity))].map(item => ({ text: t(`notification_activities.${item}`), value: item })),
            filterSearch: true,
            filteredValue: filteredInfo.activity || null,
            onFilter: (value, record) => record.activity.includes(value),
            render: (text, record) => {
                if (record.activity.includes('low_battery')) {
                    const content = record.content;
                    const formatted_content = content.replace(/'/g, '"').replace(/None/g, 'null');
                    const contentJSON = JSON.parse(formatted_content);
                    return (
                        <span>{t(`notification_activities.${record.activity}`)} <br></br> ID: {contentJSON.sensor_id} &nbsp;&nbsp;&nbsp; Bat: {contentJSON.battery}%</span>
                    )
                }
                return (
                    <span>{t(`notification_activities.${record.activity}`)}</span>
                )
            },
            sorter: (a, b) => a.activity.localeCompare(b.activity),
        }
    ]


    if (!gtw_id) {
        columns.splice(1, 0, {
            title:
                <Row className='align-items-center justify-content-center gap-3'>
                    <div>{t('tables.gateway_id')}:</div>
                    <div>
                        <Input
                            placeholder={t('tables.search')}
                            value={filteredInfo.device_serial}
                            onChange={(e) => {
                                setFilteredInfo({ ...filteredInfo, device_serial: e.target.value ? [e.target.value] : [] })
                            }}
                        />
                    </div>
                </Row>
            ,
            dataIndex: 'device_serial',
            key: 'device_serial',
            filteredValue: filteredInfo.device_serial || null,
            onFilter: (value, record) => record.device_serial.includes(value.toUpperCase()),
            render: (device_serial) => {
                return  <div style={{ textAlign: 'center' }} >
                            <Typography.Link
                                href={`${routes.gateway_detail.path}/${device_serial}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {device_serial}
                            </Typography.Link>
                        </div>
            },
            sorter: (a, b) => a.device_serial.localeCompare(b.device_serial),
        },
        {
            title: t('tables.tenant_id'),
            dataIndex: 'tenant_string_id',
            key: 'tenant_string_id',
            filters: [...new Set(notifications.map(item => item.tenant_string_id))].map(item => ({ text: item, value: item })),
            filterSearch: true,
            filteredValue: filteredInfo.tenant_string_id || null,
            onFilter: (value, record) => record.tenant_string_id.includes(value),
            sorter: (a, b) => a.tenant_string_id.localeCompare(b.tenant_string_id),
        })
    }

    return (
        <>
            <SEO_HELMET title={`${t('pages.notifications.title')} ${ gtw_id ? '/' + gtw_id.substring(gtw_id.length - 4) : ''}`} />

            <div className="p-3">
                
                <div>
                    <Descriptions
                        items={descriptionItems}
                        bordered
                        column={{ xs: 1, sm: 2, md: 3, lg: 4 }}
                        layout={isMobile ? 'vertical' : 'horizontal'}
                        size='small'
                    />
                </div>

                <div className='mt-4'>
                    <Table
                        columns={columns}
                        pagination={false}
                        dataSource={notifications?.length > 0 ? [...notifications].sort((a,b) => b.timestamp - a.timestamp) : null}
                        loading={loading}
                        scroll={{
                            y: 'calc(100vh - 300px)',
                            x: isPortrait ? 'max-content' : 'calc(100vw - 280px)'
                        }}
                        rowKey={(record) => record.id}

                        onChange={(pagination, filters, sorter) => {
                            setFilteredInfo(filters);
                            localStorage.setItem('notificationFilters', JSON.stringify(filters));
                        }}

                        footer={(currentPageData) =>
                        
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                                <Button onClick={() => {setFilteredInfo({}); localStorage.removeItem('notificationFilters')}}>{t('tables.clear_filters')}</Button>
                            </div>
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default Notifications
