import { createSlice } from "@reduxjs/toolkit";


export const authSlice = createSlice({
    name: "authReducers",
    initialState: {
        user_info: null,
        loading: false,
        error: null,
        success: null,
        isDarkMode: localStorage.getItem('isDarkMode') === 'true' ? true : false,
        themeLoading: false,
    },
    reducers: {

        // LOGIN
        loginRequest: (state) => { state.loading = true; },
        loginSuccess: (state, action) => { state.loading = false; state.loginSuccess = true; },
        loginFailed: (state, action) => { state.loading = false; state.error = action.payload; state.loginSuccess = false; },

        // LOGOUT
        logout: (state) => {
            state.user_info = {};
            state.loading = false;
            state.error = null;
            state.logoutSuccess = true;
        },

        // UPDATE PROFILE
        updateProfileRequest: (state) => { state.loading = true; },
        updateProfileSuccess: (state, action) => { state.loading = false; state.user_info = action.payload; state.success = true; },
        updateProfileFailed: (state, action) => { state.loading = false; state.error = action.payload; },

        // ADD REMOVE FAV
        addRemoveFavRequest: (state) => { state.loading = true; },
        addRemoveFavSuccess: (state, action) => { state.loading = false; state.user_info.user_settings.gtw_fav_list = action.payload.gtw_fav_list; state.success = true; },
        addRemoveFavFailed: (state, action) => { state.loading = false; state.error = action.payload; },

        // GET FAV LIST
        getFavListRequest: (state) => { state.loading = true; },
        getFavListSuccess: (state, action) => { state.loading = false; state.user_info.user_settings.gtw_fav_list = action.payload.gtw_fav_list; state.success = true; },
        getFavListFailed: (state, action) => { state.loading = false; state.error = action.payload; },

        // GET PERMISSIONS
        getPermissionsRequest: (state) => { state.loading = true; },
        getPermissionsSuccess: (state, action) => { state.loading = false; state.user_info.permissions = action.payload; state.success = true; },
        getPermissionsFailed: (state, action) => { state.loading = false; state.error = action.payload; },

        // CHECK SESSION
        checkSessionRequest: (state) => { state.loading = true; },
        checkSessionSuccess: (state, action) => { state.loading = false; state.user_info = action.payload; state.checkSessionSuccess = true; },
        checkSessionFailed: (state, action) => { state.loading = false; state.error = action.payload; state.checkSessionSuccess = false; },

        // UPDATE THEME
        updateThemeRequest: (state) => { state.themeLoading = true; },
        updateThemeSuccess: (state) => { state.isDarkMode = !state.isDarkMode; state.themeLoading = false; },
        updateThemeFailed: (state) => { state.themeLoading = false; state.error = 'Theme wont update'; },

        // RESET
        reset: (state) => {
            state.loading = false;
            state.error = null;
            state.success = null;
            state.checkSessionSuccess = null;
            state.loginSuccess = null;
            state.logoutSuccess = null;
        },
    },
});


export const {
    loginRequest,
    loginSuccess,
    loginFailed,

    logout,

    updateProfileRequest,
    updateProfileSuccess,
    updateProfileFailed,

    addRemoveFavRequest,
    addRemoveFavSuccess,
    addRemoveFavFailed,

    getFavListRequest,
    getFavListSuccess,
    getFavListFailed,

    getPermissionsRequest,
    getPermissionsSuccess,
    getPermissionsFailed,

    checkSessionRequest,
    checkSessionSuccess,
    checkSessionFailed,

    updateThemeRequest,
    updateThemeSuccess,
    updateThemeFailed,

    reset
} = authSlice.actions;
