import { createSlice } from "@reduxjs/toolkit";



export const userGroupSlice = createSlice({
    name: 'userGroupReducers',
    initialState: {
        error: null,
        loading: false,
        success: false,
        group: {},
        groups: []
    },
    reducers: {

        // Groups
        groupListRequest: (state) => { state.loading = true },
        groupListSuccess: (state, action) => { state.loading = false; state.groups = action.payload; state.success = true; },
        groupListFail: (state, action) => { state.loading = false; state.error = action.payload},

        // Update Group
        groupUpdateRequest: (state) => { state.groupUpdateLoading = true },
        groupUpdateSuccess: (state, action) => { state.groupUpdateLoading = false; state.groupUpdateSuccess = true; state.group = action.payload},
        groupUpdateFail: (state, action) => { state.groupUpdateLoading = false; state.groupUpdateError = action.payload},

        // Create Group
        groupCreateRequest: (state) => { state.groupCreateLoading = true },
        groupCreateSuccess: (state, action) => { state.groupCreateLoading = false; state.groupCreateSuccess = true; state.groups = [...state.groups, action.payload] },
        groupCreateFail: (state, action) => { state.groupCreateLoading = false; state.groupCreateError = action.payload},

        // Reset Actions
        groupListReset: (state) => { state.loading = false; state.success = false; state.error = null },
        groupUpdateReset: (state) => { state.groupUpdateLoading = false; state.groupUpdateSuccess = false; state.groupUpdateError = null },
        groupCreateReset: (state) => { state.groupCreateLoading = false; state.groupCreateSuccess = false; state.groupCreateError = null },
        
        // Reset Slice
        groupSliceReset: (state) => {
            state.loading = false; state.success = false; state.error = null; state.group = null; state.groups = null;
            state.groupUpdateLoading = false; state.groupUpdateSuccess = false; state.groupUpdateError = null;
            state.groupCreateLoading = false; state.groupCreateSuccess = false; state.groupCreateError = null
        },
    }
});

export const {
groupListRequest,
groupListSuccess,
groupListFail,
groupListReset,

groupUpdateRequest,
groupUpdateSuccess,
groupUpdateFail,
groupUpdateReset,

groupCreateRequest,
groupCreateSuccess,
groupCreateFail,
groupCreateReset,

groupSliceReset,
} = userGroupSlice.actions;
