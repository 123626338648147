import React from 'react'
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import './ClassicModal.css'
import { setIsModalOpen } from '../../core/stores/modalControllerSlice';
import CloseSVG from '../svg/CloseSVG';



function ClassicModal() {

    //? STATES
    const isModalOpen = useSelector(state => state.modalController.isModalOpen)
    const modalOnClose = useSelector(state => state.modalController.modalOnClose)
    const modalData = useSelector(state => state.modalController.modalData)
    const modalCaption = useSelector(state => state.modalController.modalCaption)
    const closeButton = useSelector(state => state.modalController.closeButton)
    const backButton = useSelector(state => state.modalController.backButton)
    const backButtonFn = useSelector(state => state.modalController.backButtonFn)
    const backCompData = useSelector(state => state.modalController.backCompData)
    const dispatch = useDispatch();

    //*****?/

    //? FUNCTIONS
    const modalCloseHandle = () => {
        dispatch(setIsModalOpen(false))
    }

    //*****?/



    return (
        <ReactModal
            id='ClassicModal'
            appElement={document.getElementsByClassName('App')[0]}
            onRequestClose={modalCloseHandle}
            shouldCloseOnOverlayClick={true}
            shouldCloseOnEsc={true}
            ariaHideApp={false}
            isOpen={isModalOpen ? true : false}
            onAfterClose={modalOnClose}
        >
            <div className='ClassicModal-cover'>
                <div className='ClassicModal-top'>
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        {backButton ?
                            <div onClick={backButtonFn} className='btn btn-light' style={{ cursor: 'pointer', boxShadow: ' rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px', margin: '0 20px 0 10px' }}>
                                <i className="fa-solid fa-arrow-left" style={{}}></i>
                            </div>
                            : ''
                        }
                        {modalCaption ?? modalCaption}

                    </span>

                    {closeButton ? <div className='close-button' onClick={modalCloseHandle}>
                        {/* <CloseSVG /> */}
                        <i style={{ fontSize: '20px',margin:'0 10px' }} className="fa-solid fa-xmark"></i>
                    </div> : ''}
                </div>
                {/* <div className='ClassicModal-content' dangerouslySetInnerHTML={{ __html: modalData }}>
                </div> */}
                <div className='ClassicModal-content ps-4 pe-4' >
                    {backButton ? backCompData : modalData}
                </div>
            </div>
        </ReactModal>
    )
}


export default ClassicModal