import { api } from "../../apis/Apis";
import { batteryLogListFail, batteryLogListRequest, batteryLogListSuccess, nodeLogListFail, nodeLogListRequest, nodeLogListSuccess } from "../../stores/logs/nodeLogSlice";


export const listNodeLogs = (params) => async (dispatch) => {
    try{
        dispatch(nodeLogListRequest());

        const { data } = await api.get('data/node_logs/', {params: params});

        dispatch(nodeLogListSuccess(data));
    }catch(error){
        console.log("err:",error);
        dispatch(nodeLogListFail(
            error?.response?.data?.detail || error?.response?.data?.error || error?.message
        ));
    }
}


export const listBatteryLogs = (params) => async (dispatch) => {
    try{
        dispatch(batteryLogListRequest());

        const { data } = await api.get('logs/battery-logs/', {params: params});

        dispatch(batteryLogListSuccess(data));
    }catch(error){
        dispatch(batteryLogListFail(
            error?.response?.data?.detail || error?.response?.data?.error || error?.message
        ));
    }
}
