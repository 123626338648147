import React, { useEffect, useState } from 'react'
import { FotaApi, GET_MainPageTable } from '../../../core/apis/Apis';
import { setIsModalOpenAntd } from '../../../core/stores/antdModalControllerSlice';
import { toast } from 'react-toastify';
import { Button, Input, Space, Form, Row, Col, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

function UpdateFota({ record }) {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const dispatch = useDispatch();
    const {t} = useTranslation();
    /* -------------------------------------------------------------------------- */
    //TODO -                            STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [gtwDatas, setGtwDates] = useState()
    /* -------------------------------------------------------------------------- */
    //TODO -                             USE-FORM                                   */
    /* -------------------------------------------------------------------------- */
    const [form] = Form.useForm();

    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    const updateHandle = () => {
        const formData = form.getFieldsValue();

        FotaApi(formData.device_serial, formData)
            .then(async (val) => {
                if (val.status === 200) {
                    toast.success(t('modals.update_fota_modal.success_toast'), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(setIsModalOpenAntd(false))
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log("err : ", err)
                if (err.response.status === 400) {
                    toast.error(t('pages.fota.fota_already_active_toast'), {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                } else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
    }


    const getData = () => {
        GET_MainPageTable()
            .then(res => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        let temp = []
                        res.data.map((item, index) => {
                            temp.push({ "value": item.device_serial, "label": item.device_serial })
                        })
                        setGtwDates(temp)
                    }
                }
            })
            .catch(err => {
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={updateHandle}
            >
                <Row gutter={[20, 0]} justify="center" align="center">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} >
                        <Form.Item
                            name='version'
                            label={t('modals.update_fota_modal.version_label')}
                            initialValue={record.version}
                        >
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} >
                        <Form.Item
                            name='fota_id'
                            label={t('modals.update_fota_modal.fota_id_label')}
                            initialValue={record.id}
                        >
                            <Input readOnly disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }} >
                        <Form.Item
                            name='device_serial'
                            label={t('modals.update_fota_modal.gtw_id_label')}
                            required tooltip={t('general.required_field')}
                            rules={
                                [
                                    { required: true, message: t('modals.update_fota_modal.gtw_id_required') },
                                ]
                            }>
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={gtwDatas}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='footer_buttons_part mt-4' justify="center" align="center" >
                    <Space wrap>
                        <Button
                            icon={<i className="fa-solid fa-xmark pe-1"></i>}
                            onClick={() => dispatch(setIsModalOpenAntd(false))} >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<i className="fa-solid fa-floppy-disk pe-1"></i>}>
                            {t('general.update')}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}

export default UpdateFota
