import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import GlobalInfo from '../../../components/global_info/GlobalInfo'
import LeftSideMenu from '../../../components/menus/LeftSideMenu'
import NavbarMenu from '../../../components/menus/NavbarMenu'
import { setModalOnClose } from '../../../core/stores/modalControllerSlice'
import SystemCheck from '../system_check/SystemCheck'
import './PageStructure.css'
import { Layout, theme } from 'antd'

function PageStructure({ page }) {

  const { Content } = Layout;
  const menuController = useSelector(state => state.menuController)
  const dispatch = useDispatch();

  const {
    token : {
      colorBgContainer,
      colorPrimary,
      colorBgLayout
    }
  } = theme.useToken();

  useEffect(() => {
    dispatch(setModalOnClose())
  }, [])


  /* MEDIAQUERY */
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  return (
    <Layout>
      <SystemCheck />
      <GlobalInfo />
      <div id='PageStructure'>

        <div className="PageStructure-cover">
          {/* {isDesktopOrLaptop && */}
            <div className="PageStructure-left_side_menu">
              <LeftSideMenu />
            </div>
          {/* } */}

          <div className="PageStructure-right_side_page_content"
            style={isTabletOrMobile ? { width: '100%' } : menuController.isOpen ? { width: 'calc(100% - 60px)' } : { width: 'calc(100% - 140px)' }}
          >
            <div className="PageStructure-top_navbar_menu">
              <NavbarMenu />
            </div>
            <Content className="PageStructure-bottom_page_content" style={{ background: colorBgLayout }}>
              {page}
            </Content>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageStructure
