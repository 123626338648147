import { api } from "../../apis/Apis";
import { addRemoveFavFailed, addRemoveFavRequest, addRemoveFavSuccess, checkSessionFailed, checkSessionRequest, checkSessionSuccess, getFavListFailed, getFavListRequest, getFavListSuccess, getPermissionsFailed, getPermissionsRequest, getPermissionsSuccess, loginFailed, loginRequest, loginSuccess, logout, updateThemeFailed, updateThemeRequest, updateThemeSuccess } from "../../stores/auth/authSlice";



export const login = (username, password) => async (dispatch) => {
    try {
        dispatch(loginRequest());

        const {data} = await api.post(
            'auth/login/',
            { username, password },
        );

        localStorage.setItem('last_active_time', Date.now().toString());
        localStorage.setItem('isAuthenticated', true)

        dispatch(loginSuccess(data));
    } catch (error) {
        console.log("login error", error)
        dispatch(loginFailed(error?.response?.data?.detail || error?.response?.data?.error));
    }
}


export const logoutAction = () => async (dispatch) => {
    await api.post(
        `auth/logout/`
    );
    dispatch(logout());
};


export const addRemoveFavAction = (device_serial) => async (dispatch) => {
    try {
        dispatch(addRemoveFavRequest());

        const { data } = await api.post(
            `users/my-profile/follows/`,
            { device_serial: device_serial }
        );

        dispatch(addRemoveFavSuccess(data));
    } catch (error) {
        dispatch(addRemoveFavFailed(error?.response?.data?.error?.detail || error?.response?.data?.error));
    }
}


export const getFavListAction = () => async (dispatch) => {
    try {
        dispatch(getFavListRequest());

        const { data } = await api.get(
            `users/my-profile/follows/`
        );

        dispatch(getFavListSuccess(data));
    } catch (error) {
        dispatch(getFavListFailed(error?.response?.data?.error?.detail || error?.response?.data?.error));
    }
}


export const getPermissionsAction = () => async (dispatch) => {
    try {
        dispatch(getPermissionsRequest());

        let { data } = await api.get(
            `users/my-profile/permissions/`,
        );

        if (data.token_refreshed) {
            const response = await api.get(
                `users/my-profile/permissions/`
            );

            console.log("Token refreshed. Getting again the user permissions.");
            console.log("response", response.data);

            data = response.data;
        }

        dispatch(getPermissionsSuccess(data));
    } catch (error) {
        console.log("error", error)
        dispatch(getPermissionsFailed(error?.response?.data?.error?.detail || error?.response?.data?.error));
    }
}


export const checkSessionAction = () => async (dispatch) => {
    try {
        dispatch(checkSessionRequest());

        let { data } = await api.get(
            `users/my-profile/session-check/`
        );

        if (data.token_refreshed) {
            const response = await api.get(
                `users/my-profile/session-check/`
            );

            console.log("Token refreshed. Getting again the user info.");
            console.log("response", response.data);

            data = response.data;
        }

        dispatch(checkSessionSuccess(data));
    } catch (error) {
        dispatch(checkSessionFailed(error?.response?.data?.error?.detail || error?.response?.data?.error));
    }
}


export const updateTheme = () => async (dispatch) => {
    try {
        dispatch(updateThemeRequest());
        setTimeout(() => {
            localStorage.setItem('isDarkMode', localStorage.getItem('isDarkMode') === 'true' ? false : true);
            dispatch(updateThemeSuccess());
        }, 1000);

    } catch (error) {
        dispatch(updateThemeFailed());
    }
}
