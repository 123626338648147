import { Button, Input, Popover, Row, Table, Tag, Typography, theme } from 'antd'
import React from 'react'
import { getDeviceStatus, getNodeList } from '../../../core/apis/Apis'
import { toast } from 'react-toastify'
import { routes } from '../../../core/route/router'
import { SEO_HELMET } from '../../../core/utils/helmet'
import { useTranslation } from 'react-i18next'
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice'
import { useDispatch, useSelector } from 'react-redux'


function NodeList() {

    const [nodeList, setNodeList] = React.useState([])
    const [nodeStatusList, setNodeStatusList] = React.useState([])
    const [loading, setLoading] = React.useState(true)
    const [filteredInfo, setFilteredInfo] = React.useState({})
    const [sorteredInfo, setSorteredInfo] = React.useState({})

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        token: {
            colorPrimary,
            colorError,
            colorWarning
        }
    } = theme.useToken();

    const {
        authReducers: { isDarkMode }
    } = useSelector(state => state);

    const getData = () => {
        getNodeList()
            .then(res => {
                if (res.status === 200) {
                    setNodeList(res.data.nodes)

                    getDeviceStatus('sensor')
                        .then(res => {
                            if (res.status === 200)
                                setNodeStatusList(res.data)
                        })
                        .catch(err => {
                            console.log("error: ", err)
                        })
                }
                setLoading(false)
            })
            .catch(err => {
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                console.log("error: ", err)

                setLoading(false)
            })
    }

    React.useEffect(() => {
        dispatch(setTitle(t('pages.node_list.title')))
        getData();
    }, [])

    const sensorTypeFromID = (id) => {
        switch (id) {
            case "1":
                return t('sensor_types.motion');
            case "2":
                return t('sensor_types.door');
            case "3":
                return t('sensor_types.force');
            case "4":
                return t('sensor_types.emergency_button');
            case "5":
                return t('sensor_types.humidity_motion');
            case "6":
                return t('sensor_types.sleep_monitor');
            case "7":
                return t('sensor_types.extender');
            default:
                return "Unknown";
        }
    }

    const buttonAndDoorCalculator = (voltage) => {

        // i did not use switch case because of the decimal values and it is not possible to use switch case with decimal values

        if (voltage <= 2.8)
            return 5
        else if (voltage <= 2.835)
            return 10
        else if (voltage <= 2.87)
            return 15
        else if (voltage <= 2.905)
            return 20
        else if (voltage <= 2.94)
            return 25
        else if (voltage <= 2.946)
            return 30
        else if (voltage <= 2.950)
            return 35
        else if (voltage <= 2.955)
            return 40
        else if (voltage <= 2.959)
            return 45
        else if (voltage <= 2.964)
            return 50
        else if (voltage <= 2.968)
            return 55
        else if (voltage <= 2.973)
            return 60
        else if (voltage <= 2.977)
            return 65
        else if (voltage <= 2.982)
            return 70
        else if (voltage <= 2.986)
            return 75
        else if (voltage <= 2.991)
            return 80
        else if (voltage <= 2.995)
            return 85
        else if (voltage <= 3.000)
            return 90
        else if (voltage <= 3.004)
            return 95
        else
            return 100
    }

    const otherSensorsCalculator = (voltage) => {

        if (voltage <= 2.3)
            return 5
        else if (voltage <= 2.33)
            return 10
        else if (voltage <= 2.36)
            return 15
        else if (voltage <= 2.38)
            return 20
        else if (voltage <= 2.41)
            return 25
        else if (voltage <= 2.44)
            return 30
        else if (voltage <= 2.47)
            return 35
        else if (voltage <= 2.5)
            return 40
        else if (voltage <= 2.52)
            return 45
        else if (voltage <= 2.55)
            return 50
        else if (voltage <= 2.58)
            return 55
        else if (voltage <= 2.61)
            return 60
        else if (voltage <= 2.64)
            return 65
        else if (voltage <= 2.66)
            return 70
        else if (voltage <= 2.69)
            return 75
        else if (voltage <= 2.72)
            return 80
        else if (voltage <= 2.78)
            return 85
        else if (voltage <= 2.8)
            return 90
        else if (voltage <= 2.83)
            return 95
        else
            return 100
    }

    const getBatteryPercentage = (sensorType, voltage) => {

        switch (sensorType) {
            case "2" || "4":
                return buttonAndDoorCalculator(voltage);
            default:
                return otherSensorsCalculator(voltage);
        }
    }

    const getFormattedDuration = (status) => {
        return `${status?.hours} ${t('general.hr')}, ${status?.minutes} ${t('general.min')}, ${status?.seconds} ${t('general.sec')}`
    }

    const getNodeStatus = (device_id, gateway_device_serial) => {
        const status = nodeStatusList.find((item) => item.device_id === device_id && item.gateway_device_serial === gateway_device_serial)
        return status
    }

    const columns = [
        {
            title:
                <Row className='align-items-center justify-content-center gap-2'>
                    <div>{t('pages.node_list.node_id')}:</div>
                    <div>
                        <Input
                            placeholder={t('tables.search')}
                            value={filteredInfo.sensor_id}
                            onChange={(e) => {
                                setFilteredInfo({ ...filteredInfo, sensor_id: e.target.value ? [e.target.value] : [] })
                            }}
                        />
                    </div>
                </Row>,
            dataIndex: 'sensor_id',
            key: 'sensor_id',
            filteredValue: filteredInfo.sensor_id || null,
            onFilter: (value, record) => record.sensor_id.includes(value),
            width: 150,
            render: (text, record) => <div style={{ textAlign: 'center' }} >{text}</div>,
            fixed: 'left'
        },
        {
            title:
                <Row className='align-items-center justify-content-center gap-2'>
                    <div>{t('tables.gateway_id')}:</div>
                    <div>
                        <Input
                            placeholder={t('tables.search')}
                            value={filteredInfo.device_serial}
                            onChange={(e) => {
                                setFilteredInfo({ ...filteredInfo, device_serial: e.target.value ? [e.target.value] : [] })
                            }}
                        />
                    </div>
                </Row>
            ,
            dataIndex: 'device_serial',
            key: 'device_serial',
            filteredValue: filteredInfo.device_serial || null,
            onFilter: (value, record) => record.device_serial.includes(value.toUpperCase()),
            render: (device_serial) => {
                return  <div style={{ textAlign: 'center' }} >
                            <Typography.Link
                                href={`${routes.gateway_detail.path}/${device_serial}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {device_serial}
                            </Typography.Link>
                        </div>
            },
            width: 175
        },
        {
            title: t('pages.node_list.node_type'),
            dataIndex: 'sensor_type',
            key: 'sensor_type',
            filters: [...new Set(nodeList?.map((item) => item.sensor_type))].map((item) => ({ text: sensorTypeFromID(item), value: item })),
            filterSearch: true,
            filteredValue: filteredInfo.sensor_type || null,
            onFilter: (value, record) => record.sensor_type.indexOf(value) === 0,
            render: (text, record) => {return sensorTypeFromID(record.sensor_type)},
            width: 120
        },
        {
            title: t('pages.node_list.node_status'),
            dataIndex: 'sensor_status',
            key: 'sensor_status',
            filters: [...new Set(nodeList?.map((item) => item.sensor_status))].map((item) => ({
                text: item ? <Tag color='success'>{t('general.active')}</Tag> : <Tag color='error'>{t('general.passive')}</Tag>,
                value: item
            })),
            filteredValue: filteredInfo.sensor_status || null,
            onFilter: (value, record) => record.sensor_status === value,
            // sorter: (a, b) => a.sensor_status - b.sensor_status,
            // sortOrder: sorteredInfo.columnKey === 'sensor_status' && sorteredInfo.order,
            width: 120,
            render: (text, record) => record.sensor_status ? <Tag color='success'>{t('general.online')}</Tag> : <Tag color='error'>{t('general.offline')}</Tag>
        },
        {
            title: t('pages.node_list.gateway_installation'),
            dataIndex: 'gateway_installation_status',
            key: 'gateway_installation_status',
            filters: [...new Set(nodeList?.map((item) => item.gateway_installation_status))].map((item) => ({ text: item ? <Tag color='success'>{t('tables.gtw_list_table.filters.installed')}</Tag> : <Tag color='error'>{t('tables.gtw_list_table.filters.not_installed')}</Tag>, value: item })),
            filteredValue: filteredInfo.gateway_installation_status || null,
            onFilter: (value, record) => record.gateway_installation_status === value,
            width: 120,
            render: (text, record) => record.gateway_installation_status ? <Tag color='success'>{t('tables.gtw_list_table.filters.installed')}</Tag> : <Tag color='error'>{t('tables.gtw_list_table.filters.not_installed')}</Tag>
        },
        {
            title: t('pages.node_list.gateway_connection'),
            dataIndex: 'gateway_connection_status',
            key: 'gateway_connection_status',
            filters: [...new Set(nodeList?.map((item) => item.gateway_connection_status))].map((item) => ({ text: item ? <Tag color='success'>{t('general.online')}</Tag> : <Tag color='error'>{t('general.offline')}</Tag>, value: item })),
            filteredValue: filteredInfo.gateway_connection_status || null,
            onFilter: (value, record) => record.gateway_connection_status === value,
            width: 120,
            render: (text, record) => record.gateway_connection_status ? <Tag color='success'>{t('general.online')}</Tag> : <Tag color='error'>{t('general.offline')}</Tag>            
        },
        {
            title: t('pages.node_list.node_ver'),
            dataIndex: 'sensor_version',
            key: 'sensor_version',
            width: 90,
            sorter: (a, b, direction) => {
                if (a.sensor_version === '0' && b.sensor_version !== '0') {
                    return direction === 'ascend' ? 1 : -1;
                }

                if (a.sensor_version !== '0' && b.sensor_version === '0') {
                    return direction === 'ascend' ? -1 : 1;
                }

                if (a.sensor_version === '0' && b.sensor_version === '0') {
                    return 0
                }

                return a.sensor_version - b.sensor_version
            },
            sortOrder: sorteredInfo.columnKey === 'sensor_version' && sorteredInfo.order,
        },
        {
            title: t('pages.node_list.battery_voltage'),
            dataIndex: 'battery_voltage',
            key: 'battery_voltage',
            render: (text, record) => <Row justify={'start'} align={'middle'} className='gap-1'>
                    {text !== "Unknown" ?
                        <>
                            <Button
                                href={`/${routes.gateway_detail.path}/${record.device_serial}/${routes.single_node_logs.path}/${record.sensor_id}/battery`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: colorPrimary }}                                
                                className={`justify-content-center align-self-center ${isDarkMode ? '' : ' border-0'}`}
                                size='small'
                            >
                                <i class="fa-solid fa-battery-half fa-rotate-270"></i>
                            </Button>
                            <div style={{ marginLeft: '5px' }}>{text}V</div>
                        </>
                    :
                        <div>{t('general.unknown')}</div>
                    }
                </Row>,
            width: 120
        },
        {
            title: t('pages.node_list.battery_precentage'),
            dataIndex: 'battery_percentage',
            key: 'battery_percentage',
            sorter: (a, b, direction) => {

                if (a.battery_voltage === "Unknown" && b.battery_voltage !== "Unknown") {
                    return direction === 'ascend' ? 1 : -1;
                }

                if (a.battery_voltage !== "Unknown" && b.battery_voltage === "Unknown") {
                    return direction === 'ascend' ? -1 : 1;
                }

                if (a.battery_voltage === "Unknown" && b.battery_voltage === "Unknown") {
                    return 0
                }

                return getBatteryPercentage(a.sensor_type, a.battery_voltage) - getBatteryPercentage(b.sensor_type, b.battery_voltage)
            },
            sortOrder: sorteredInfo.columnKey === 'battery_percentage' && sorteredInfo.order,
            render: (text, record) => {
                if (record.battery_voltage !== "Unknown") {
                    const batteryPercentage =  getBatteryPercentage(record.sensor_type, record.battery_voltage)
                    const isLowBattery = batteryPercentage <= 20 ? 'fw-bold badge bg-danger text-wrap' : ''
                    return <div className={isLowBattery} >{batteryPercentage}%</div>
                }
                return t('general.unknown');
            },
            width: 120
        },
        {
            title: t('pages.node_list.pre_day_status'),
            dataIndex: 'pre_day_status',
            key: 'pre_day_status',
            render: (text, record) => {
                const status = getNodeStatus(parseInt(record.sensor_id), record.device_serial)

                const isFieldTest = record.tenant_id === "larry$fieldtest"

                const content = !status || !isFieldTest ? 
                    <div>{t('general.unknown')}</div>
                :
                    <div>
                        {t('general.online')}: {getFormattedDuration(status?.online_duration)}<br/>
                        {t('general.offline')}: {getFormattedDuration(status?.offline_duration)}
                    </div>;

                const statusColor = !status || !isFieldTest ? colorWarning :
                    status?.online_duration?.hours > status?.offline_duration?.hours ? colorPrimary :
                        status?.online_duration?.hours === status?.offline_duration?.hours ?
                            status?.online_duration?.minutes > status?.offline_duration?.minutes ? colorPrimary :
                                status?.online_duration?.minutes === status?.offline_duration?.minutes &&
                                    status?.online_duration?.seconds > status?.offline_duration?.seconds ? colorPrimary :
                                    colorError
                                : colorError
                
                return <Row justify={'start'} align={'middle'} className='gap-1'>
                    <Popover
                        content={content}
                        title={t('pages.node_list.pre_day_status')}
                    >
                        <Button
                            style={{ color: statusColor }}
                            className={`justify-content-center align-self-center ${isDarkMode ? '' : ' border-0'}`}
                            size='small'
                        >
                            <i class="fa-solid fa-heart-pulse"></i>
                        </Button>
                    </Popover>
                </Row>
            },
            width: 90,
            sorter: (a, b, direction) => {
                const aStatus = getNodeStatus(parseInt(a.sensor_id), a.device_serial)
                const bStatus = getNodeStatus(parseInt(b.sensor_id), b.device_serial)

                const isAFieldTest = a.tenant_id === "larry$fieldtest"
                const isBFieldTest = b.tenant_id === "larry$fieldtest"

                if (isAFieldTest && isBFieldTest) {
                    if (!aStatus && bStatus) {
                        return direction === 'ascend' ? 1 : -1;
                    }
    
                    if (aStatus && !bStatus) {
                        return direction === 'ascend' ? -1 : 1;
                    }
                } else {
                    if (isAFieldTest && !isBFieldTest) {
                        return direction === 'ascend' ? -1 : 1;
                    }
    
                    if (!isAFieldTest && isBFieldTest) {
                        return direction === 'ascend' ? 1 : -1;
                    }
                }

                if ((!aStatus && !bStatus) || (!isAFieldTest && !isBFieldTest)) {
                    return 0
                }

                if (aStatus.online_duration.hours === bStatus.online_duration.hours) {
                    if (aStatus.online_duration.minutes === bStatus.online_duration.minutes) {
                        return aStatus.online_duration.seconds - bStatus.online_duration.seconds
                    }
                    return aStatus.online_duration.minutes - bStatus.online_duration.minutes
                }
                return aStatus.online_duration.hours - bStatus.online_duration.hours
            },
            sortOrder: sorteredInfo.columnKey === 'pre_day_status' && sorteredInfo.order,
        },
        {
            title: t('pages.node_list.tenant_id'),
            dataIndex: 'tenant_id',
            key: 'tenant_id',
            filters: [...new Set(nodeList?.map((item) => item.tenant_id))].map((item) => ({ text: item, value: item })),
			filterSearch: true,
			filteredValue: filteredInfo.tenant_id || null,
			onFilter: (value, record) => record.tenant_id.indexOf(value) === 0,
            width: 120
        }
    ]

    return (
        <Row className='p-lg-3'>
            <SEO_HELMET title={t('pages.node_list.title')} />
            <Table
                columns={columns}
                dataSource={
                    nodeList.sort((a, b) => a.device_serial.localeCompare(b.device_serial))
                }

                pagination={false}
                loading={loading}
                
                scroll={{
                    y: 'calc(100vh - 280px)',
                    x: 'max-content'
                }}

                onChange={(pagination, filters, sorter) => {
                    setFilteredInfo(filters);
                    setSorteredInfo(sorter);
                }}

                footer={(currentPageData) => {
                    return (
                        <div className='d-flex align-items-center justify-content-between'>
                            <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                            <Row className='gap-2'>
                                <Button onClick={() => {
                                    setFilteredInfo({
                                        gateway_connection_status: [true],
                                        gateway_installation_status: [true]
                                    });
                                }} >
                                    {t('pages.node_list.active_gateways_filter')}
                                </Button>
                                <Button onClick={() => {
                                    setFilteredInfo({});
                                    setSorteredInfo({});
                                }}>
                                    {t('tables.clear_filters')}
                                </Button>
                            </Row>
                        </div>
                    )
                }}
            />
        </Row>
    )
}

export default NodeList
