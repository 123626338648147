import { createSlice } from '@reduxjs/toolkit'


export const companyControllerSlice = createSlice({
    name: 'menuController',
    initialState: {
        redContainer: 0,
        yellowContainer: 0,
        greenContainer: 0,
    },

    reducers: {
        setRedContainer: (state, action) => {
            state.redContainer = action.payload
        },
        setYellowContainer: (state, action) => {
            state.yellowContainer = action.payload
        },
        setGreenContainer: (state, action) => {
            state.greenContainer = action.payload
        },

    }
})


export const { setAuthUser, setCompany } = companyControllerSlice.actions

export default companyControllerSlice.reducer


