import { createSlice } from "@reduxjs/toolkit";


export const gatewaySlice = createSlice({
    name: 'gatewayReducers',
    initialState: {
        error: null,
        loading: false,
        success: false,
        gateway: {},
        gateways: []
    },
    reducers: {

        // Gateways
        gatewayListRequest: (state) => { state.loading = true },
        gatewayListSuccess: (state, action) => { state.loading = false; state.gateways = action.payload; state.success = true; },
        gatewayListFail: (state, action) => { state.loading = false; state.error = action.payload},

        // Update Tenant
        // tenantUpdateRequest: (state) => { state.tenantUpdateLoading = true },
        // tenantUpdateSuccess: (state, action) => { state.tenantUpdateLoading = false; state.tenantUpdateSuccess = true; state.tenant = action.payload;
        //     const index = state.tenants.findIndex(tenant => tenant.id === action.payload.id);

        //     if (index !== -1) {
        //         state.tenants[index] = state.tenant;
        //     }
        // },
        // tenantUpdateFail: (state, action) => { state.tenantUpdateLoading = false; state.tenantUpdateError = action.payload},

        // Create Tenant
        // tenantCreateRequest: (state) => { state.tenantCreateLoading = true },
        // tenantCreateSuccess: (state, action) => { state.tenantCreateLoading = false; state.tenantCreateSuccess = true; state.tenants = [...state.tenants, action.payload] },
        // tenantCreateFail: (state, action) => { state.tenantCreateLoading = false; state.tenantCreateError = action.payload},

        // Delete Tenant
        // tenantDeleteRequest: (state) => { state.tenantDeleteLoading = true },
        // tenantDeleteSuccess: (state, action) => { state.tenantDeleteLoading = false; state.tenantDeleteSuccess = true; state.tenants = state.tenants.filter((tenant) => tenant.id !== action.payload); },
        // tenantDeleteFail: (state, action) => { state.tenantDeleteLoading = false; state.tenantDeleteError = action.payload},

        // Reset Actions
        gatewayListReset: (state) => { state.loading = false; state.success = false; state.error = null },
        // tenantUpdateReset: (state) => { state.tenantUpdateLoading = false; state.tenantUpdateSuccess = false; state.tenantUpdateError = null },
        // tenantCreateReset: (state) => { state.tenantCreateLoading = false; state.tenantCreateSuccess = false; state.tenantCreateError = null },
        // tenantDeleteReset: (state) => { state.tenantDeleteLoading = false; state.tenantDeleteSuccess = false; state.tenantDeleteError = null },
        
        // Reset Slice
        gatewaySliceReset: (state) => {
            state.loading = false; state.success = false; state.error = null; state.gateway = null; state.gateways = null;
            // state.tenantUpdateLoading = false; state.tenantUpdateSuccess = false; state.tenantUpdateError = null;
            // state.tenantCreateLoading = false; state.tenantCreateSuccess = false; state.tenantCreateError = null;
            // state.tenantDeleteLoading = false; state.tenantDeleteSuccess = false; state.tenantDeleteError = null
        },
    }
});

export const {
    gatewayListRequest,
    gatewayListSuccess,
    gatewayListFail,
    gatewayListReset,

    // tenantUpdateRequest,
    // tenantUpdateSuccess,
    // tenantUpdateFail,
    // tenantUpdateReset,

    // tenantCreateRequest,
    // tenantCreateSuccess,
    // tenantCreateFail,
    // tenantCreateReset,

    // tenantDeleteRequest,
    // tenantDeleteSuccess,
    // tenantDeleteFail,
    // tenantDeleteReset,

    gatewaySliceReset,
    
} = gatewaySlice.actions;
