import { api } from "../../apis/Apis";
import {
	fotaLogListFail,
	fotaLogListRequest,
	fotaLogListSuccess,
} from "../../stores/logs/fotaLogSlice";

export const listFotaLogs = (filters) => async (dispatch) => {
	try {
		dispatch(fotaLogListRequest());

		const { data } = await api.get("logs/fota-logs/", {
			params: filters,
		});

		dispatch(fotaLogListSuccess(data));
	} catch (error) {
		dispatch(
			fotaLogListFail(
				error.response && error.response.data.detail
					? error.response.data.detail || error.response.data.error
					: error.message
			)
		);
	}
};
