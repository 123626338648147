import { Button, Table, theme } from "antd";
import React from "react";
import { useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setIsModalOpenAntd, setModalDataAntd, setStyleAntd } from "../../../../../core/stores/antdModalControllerSlice";
import { useTranslation } from "react-i18next";
import FotaLogDetailModal from "./FotaLogDetailModal";


function FotaLogsTable({ fotaLogs, loading, gatewayGMT }) {

	const {
		authReducers: { user_info }
	} = useSelector((state) => state);
	const { t } = useTranslation();

	const {
		token: { colorInfo }
	} = theme.useToken();

	const [filteredInfo, setFilteredInfo] = useState({});
	const dispatch = useDispatch();

	const detailModalHandler = (fotaLog) => {
		dispatch(setModalDataAntd(<FotaLogDetailModal fotaLog={fotaLog} gatewayGMT={gatewayGMT} />));
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setIsModalOpenAntd(true));
	}

	const columns = [
		{
			title: t("tables.date"),
			dataIndex: "timestamp",
			key: "timestamp",
			sorter: (a, b) => a.timestamp - b.timestamp,
			render: (text, record) => {
				if (gatewayGMT)
					return (
						<div>
							{moment.unix(record.timestamp).utcOffset(gatewayGMT - 12).format("DD/MM/YY HH:mm")}
						</div>
					);
				return (
					<div>
						{moment.unix(record.timestamp).format("DD/MM/YY HH:mm")}
					</div>
				);
			},
			width: 150,
		},
		{
			title: t("tables.version"),
			dataIndex: ["new_fota_parameters", "version"],
			key: "version",
			width: 150,
		},
		{
			title: t("tables.fota_table.changelog"),
			dataIndex: ["new_fota_parameters", "changelog"],
			key: "changelog",
			width: 250,
		},
	];

	if (user_info?.is_superuser)
		columns.push({
			title: "",
			dataIndex: "",
			key: "detail",
			width: 80,
			render: (text, record) => {
				return (
					<div className="text-center">
						<div
							onClick={() => {
								detailModalHandler(record);
							}}
						>
							<i
								style={{ cursor: "pointer", color: colorInfo }}
								class="fa-solid fa-circle-info fa-xl"
							></i>
						</div>
					</div>
				);
			},
		});

	return (
		<Table
			dataSource={[...fotaLogs].sort(
				(a, b) => b.timestamp - a.timestamp
			)}
			columns={columns}
			loading={loading}
			pagination={false}
			onChange={(pagination, filters, sorter) => {
				setFilteredInfo(filters);
			}}

			scroll={{
				x: 'max-content'
			}}

			footer={(data) => (
				<div className="d-flex align-items-center justify-content-between">
					<div>
						{t('tables.total_data')}: <b>{data.length}</b>{" "}
					</div>
					<Button
						onClick={() => {
							setFilteredInfo({});
						}}
					>
						{t('tables.clear_filters')}
					</Button>
				</div>
			)}
		/>
	);
}

export default FotaLogsTable;