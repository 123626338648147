import { Button, Descriptions, Divider, Input, Space, Table, Tag, DatePicker, Row, theme, Select } from 'antd';
import React, { useCallback, useRef, useState } from 'react'
import { useMemo } from 'react';
import Moment from 'moment';
import 'moment/locale/tr'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { SetLocalStorageApi, getAllLogs, getAllSupportTicketData, getftpRefresh } from '../../../../core/apis/Apis';
import { setError, setLoading, setSuccess } from '../../../../core/stores/apiStatusControllerSlice';
import Highlighter from 'react-highlight-words';
import { useEffect } from 'react';
import { SearchOutlined } from '@mui/icons-material';
import './AllLogs.scss'

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SEO_HELMET } from '../../../../core/utils/helmet';
import { setTitle } from '../../../../core/stores/navbarTitleControllerSlice';
import { useTranslation } from 'react-i18next';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';


function AllLogs() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const isModalOpen = useSelector(state => state.modalController.isModalOpen)
    const apiStatusController = useSelector(state => state.apiStatusController)
    const dispatch = useDispatch();
    const {t} = useTranslation();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [record] = useState({
        gtwID: window.location.pathname.split('GTWY-')[1].split('/')[0],
    })
    const [dateString, setDateString] = useState(['0', '0']);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [responseData, setResponseData] = useState();
    const [responseDataLoading, setResponseDataLoading] = useState(true);
    // const [ftpDownloadLoading, setFtpDownloadLoading] = useState(false);
    const [entriesPerPage, setEntriesPerPage] = useState(1000);
    const [pageNumber, setPageNumber] = useState(0);
    const [gatewayGmt, setGatewayGmt] = useState(0);

    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setTitle(t('pages.gateway_detail.buttons.all_logs')))
        // SetLocalStorageApi()
        getData()
    }, [pageNumber, entriesPerPage])
    // useEffect(() => getDataForPagination(), [pageNumber])

    const onClickFilterButton = () => {
        setResponseDataLoading(true)
        getDataWithDateRange()
    }

    const {
        token: {
            colorBgLayout,
            colorTextHeading
        }
    } = theme.useToken();

    const getData = () => {
        let params = {
            device_serial: 'GTWY-' + record.gtwID,
            n_entries: entriesPerPage,
            page: pageNumber,
        };

        if (dateString[0] !== '0' && dateString[1] !== '0') {
            params.start = dateString[0];
            params.end = dateString[1];
        }

        setResponseDataLoading(true)

        getAllLogs(params)
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data.logs)
                    setGatewayGmt(res.data.gmt)
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const getDataWithDateRange = () => {

        const params = {
            device_serial: 'GTWY-' + record.gtwID,
            start: dateString[0],
            end: dateString[1],
            n_entries: entriesPerPage,
            page: pageNumber,
        };

        getAllLogs(params)
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data.logs)
                    setGatewayGmt(res.data.gmt)
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                if (err.response.data.status === 404){
                    setResponseData(err.response.data.logs)
                    toast.error("There is no data", {
                        position: "top-right",
                        autoClose: true,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    setResponseDataLoading(false)
                    dispatch(setError(false))
                    dispatch(setSuccess(true))
                    dispatch(setLoading(false))
                }else{
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })}
            })
    }
    /* -------------------------------------------------------------------------- */
    //TODO                          FOR TABLE PROPS                               */
    /* -------------------------------------------------------------------------- */


    const tablePropOnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };
    const columns = [
        {
            title: t('tables.date'),
            dataIndex: 'date',
            key: 'date',
            width: 140,
        },
        {
            title: 'Log',
            dataIndex: 'log',
            key: 'log',
            filteredValue: filteredInfo.log || null,
            width: 500,
        },
    ];


    const dataSource = useMemo(() => {
        var list = []
        if (responseData) {
            responseData.map((res, index) => {
                list.push({
                    key: index,
                    date: Moment.unix(res.timestamp).utcOffset(gatewayGmt - 12).format("DD/MM/YY, HH:mm"),
                    log: JSON.stringify(res),
                })
            })
        }
        return list
    }, [responseData])

    var onChangeRangePicker = (date, dateString) => {
        setDateString(dateString)
    }
    return (
        <>
            <SEO_HELMET title={`${t('pages.gateway_detail.buttons.all_logs')} / ${record.gtwID}`} />
            <div id="AllLogs">
                <div className="AllLogs-cover">
                    {/* <Divider orientation='left'>{record.gtwID} - ALL LOGS</Divider> */}
                    <Descriptions size='small' bordered column={{
                        // sm: 2,
                        // md: 2,
                        // lg: 2
                    }}>
                        <Descriptions.Item label={t('tables.gateway_id')}>
                            {record.gtwID}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('tables.date')}>
                            <RangePicker
                                format={dateFormat}
                                onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: 'max-content' }}>
                            <Button disabled={!dateString && true} onClick={dateString ? () => onClickFilterButton() : null}>{t('pages.node_logs.description_items.filter')}</Button>
                            {/* <Button disabled={!dateString && true} onClick={dateString ? () => onClickFTPDownload() : null}>{t('pages.node_logs.description_items.ftp_download')}</Button> */}
                        </Descriptions.Item>
                       
                    </Descriptions>
                    <div className="AllLogs-bottom_more_info">
                        <Table
                            footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>

                                <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                                <Button onClick={() => setFilteredInfo({})}>{t('tables.clear_filters')}</Button>
                            </div>
                            }
                            size='middle'
                            bordered={true}
                            columns={columns}
                            pagination={false}
                            dataSource={dataSource}
                            loading={responseDataLoading}
                            scroll={{
                                y: 'calc(100vh - 280px)',
                            }}
                            onChange={tablePropOnChange}
                        />
                            <Row className='justify-content-center justify-content-md-between align-items-center gap-3 p-3' style={{background: colorBgLayout }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button disabled={pageNumber <= 0} onClick={() => setPageNumber(prev => prev - 1)}>{t('pages.node_logs.pagination_items.before_page')}</Button>
                                    <span style={{ margin: '0 10px' }}>{pageNumber + 1}</span>
                                    <Button onClick={() => setPageNumber(prev => prev + 1)} disabled={dataSource.length < entriesPerPage ? true : false}>{t('pages.node_logs.pagination_items.next_page')}</Button>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <label style={{ marginRight: '10px', fontWeight: 'bold', color: colorTextHeading }}>{t('pages.node_logs.pagination_items.entries_per_page')}</label>
                                    <Select
                                        options={[
                                            { label: 1000, value: 1000 },
                                            { label: 2000, value: 2000 },
                                            { label: 5000, value: 5000 },
                                        ]}
                                        onChange={setEntriesPerPage}
                                        defaultValue={entriesPerPage}
                                    />
                                </div>
                            </Row>
                    </div>
                    
                </div>
            </div>
        </>
    )
}
export default AllLogs
