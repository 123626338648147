import { useTranslation } from 'react-i18next';
import React, { lazy, Suspense, useState, useEffect } from "react";

import { useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AntdModal from "./components/antdModal/AntdModal";
import PageLoading from "./components/loadings/PageLoading";
import { routes } from "./core/route/router";

import { ConfigProvider, theme } from 'antd';
import tr_TR from 'antd/locale/tr_TR';
import en_US from 'antd/locale/en_US';
import de_DE from 'antd/locale/de_DE';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { checkSessionAction, logoutAction } from './core/actions/auth/authActions';
import { reset } from './core/stores/auth/authSlice';

import ClassicModal from './components/modal/ClassicModal';
import Toasty from './components/toasty/Toasty';
import GtwList from './containers/pages/containers/GtwList';
import Login from './containers/pages/login/Login';
import PageStructure from './containers/pages/page_structure/PageStructure';
import SupportTickets from './containers/pages/supports/SupportTickets';
import ResolvedSupportTicketsList from './containers/pages/supports/ResolvedSupportTicketsList';
import Settings from './containers/pages/settings/Settings';
import SupportTicketDetail from './containers/pages/supports/SupportTicketDetail';
import SingleGtwDetail from './containers/pages/containers/SingleGtwDetail';
import AllLogs from './containers/pages/containers/buttons/AllLogs';
import AnomalyAnalyzer from './containers/pages/containers/buttons/AnomalyAnalyzer';
import UpdateConfigs from './containers/pages/containers/buttons/UpdateConfigs';
import Sessions from './containers/pages/containers/buttons/Sessions';
import Fota from './containers/pages/fota/Fota';
import SetupLogs from './containers/pages/setup_logs/SetupLogs';
import SetupLogDetail from './containers/pages/setup_logs/SetupLogDetail';
import NodeLog from './containers/pages/containers/buttons/NodeLog';
import NonePermissionPage from './containers/pages/none_permission/NonePermissionPage';
import PasswordExpired from './containers/pages/settings/settings_more/personal_settings/PasswordExpired';
import Notifications from './containers/pages/containers/buttons/Logs/Notifications';
import NodeList from './containers/pages/NodeList/NodeList';
import DeviceStatusHistory from './containers/pages/containers/buttons/Logs/StatusHistory/DeviceStatusHistory';
import Hotline from './containers/pages/hotline/Hotline';
import DeleteAccount from './containers/pages/containers/DeleteAccount';


function App() {
  const { t, i18n } = useTranslation();

  const {
    authReducers: { user_info, checkSessionSuccess, loginSuccess, logoutSuccess, isDarkMode, themeLoading}
  } = useSelector((state) => state);


  const isAuthenticated = localStorage.getItem('isAuthenticated') === "true" ? true : false
  const [isUserInfoExist, setUserInfoExist] = useState(isAuthenticated)
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordExpired, setPasswordExpired] = useState(false)
  const passwordExpiredRef = useRef() 
  const prevPasswordExpired = passwordExpiredRef.current;

  const passwordExpiredCookie = user_info?.password_expired;

  useEffect(() => {

    passwordExpiredRef.current = passwordExpiredCookie;

    if (passwordExpiredCookie) {
      setPasswordExpired(true)
      setUserInfoExist(true)
      // setLoaded(true)
      navigate('/password-expired')
    } else {
      setPasswordExpired(false)
      setUserInfoExist(true)
      // setLoaded(true)
    }
  }, [passwordExpiredCookie])

  useEffect(() => {
    if (logoutSuccess) {
      dispatch(reset())
      localStorage.clear();
      if (prevPasswordExpired === true && passwordExpiredCookie === false) {
        toast.success(t('pages.settings.personal_section.password_section.password_updated_toast'), {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        })
      }
      // window.location.reload();
    }
  }, [logoutSuccess])

  useEffect(() => {
    if (checkSessionSuccess || loginSuccess) {
      setUserInfoExist(true)
      dispatch(reset())
      setLoaded(true)
    }

    // if (checkSessionSuccess === false) {
    //   setUserInfoExist(false)
    //   setLoaded(true)
    //   dispatch(reset())
      // dispatch(logoutAction())
    // }
  }, [checkSessionSuccess, loginSuccess, dispatch])


  useEffect(() => {

    if (!isAuthenticated) {
      setUserInfoExist(false)
      setLoaded(true)
      localStorage.clear();
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (user_info?.is_initial_password) {
      setPasswordExpired(true)
      setUserInfoExist(true)
      // setLoaded(true)
      navigate('/password-expired')
    }
  }, [user_info])

  /* -------------------------------------------------------------------------- */
  //TODO -                             i18n                                */
  /* -------------------------------------------------------------------------- */
  const findLanguage = () => {
    if (i18n.language === 'en') {
      return en_US
    }
    else if (i18n.language === 'tr') {
      return tr_TR
    }
    else if (i18n.language === 'de') {
      return de_DE
    }

  }

  const timerRef = useRef(null);
  
  const stopTimer = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const updateLastActiveTime = () => {
    localStorage.setItem('last_active_time', Date.now().toString());
  };
  
  const startTimer = () => {
  
    const lastActiveTime = parseInt(localStorage.getItem('last_active_time') || '0');
    if (Date.now() - lastActiveTime > 1800000 && user_info?.is_superuser === false) { // 60 saniye * 30 * 1000 milisaniye
      dispatch(logoutAction());
    } else {
      stopTimer();
      timerRef.current = setTimeout(startTimer, 5000); // Her 5 saniyede bir kontrol et
    }
    
  };
  
  useEffect(() => {
    if (isAuthenticated && !passwordExpiredCookie) {

      const lastActiveTime = parseInt(localStorage.getItem('last_active_time') || '0');
      if (Date.now() - lastActiveTime > 1800000 && user_info?.is_superuser === false) { // 60 saniye * 30 * 1000 milisaniye
        dispatch(logoutAction());
      }else{
        dispatch(checkSessionAction());
      }

      window.addEventListener('mousemove', updateLastActiveTime);
      window.addEventListener('keydown', updateLastActiveTime);

      updateLastActiveTime();
  
      startTimer();
  
      return () => {
        window.removeEventListener('mousemove', updateLastActiveTime);
        window.removeEventListener('keydown', updateLastActiveTime);
        stopTimer();
      };
    }
  }, [isAuthenticated]);

  return (
    !loaded || themeLoading ? <div style={{ width: '100vw', height: '100vh', margin: 'auto' }}><PageLoading /></div> :
    <>
      <ConfigProvider
        locale={findLanguage()}
        theme={{
          token: {
            colorPrimary: "#329bd7",
            colorBgBase: isDarkMode ? "#091e30" : "#fff",
            colorLink: isDarkMode ? "#D4FF91" : "#0032FF",
            colorInfo: "#329bd7"
          },
          components: {
            Tag: {
              algorithm: false
            },
            Button: {
              algorithm: true,
            },
            Switch: {
              algorithm: true,
            }
          },
          algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          cssVar: true
          // components: {
          //   Radio: {
          //     colorPrimary: '#329bd7',
          //   },
          // },
        }}
      >
        {/* <StyledCo */}
        <div className="App">
          <Suspense fallback={<div style={{ width: '100vw', height: '100vh', margin: 'auto' }}><PageLoading /></div>}>
            <AntdModal />
            <ClassicModal />
            <ToastContainer />
            <Toasty />
            <Routes>
              {
                !user_info ?
                  <>
                    <Route path={routes.login.path} element={<Login />} />
                    <Route path={routes.delete_account.path} element={<DeleteAccount />} />
                  </>
                  :
                  <>
                    <Route path={routes.home_page.path} element={<Navigate to={routes.gateway_list.path} />} />
                    <Route path={routes.gateway_list.path} element={<PageStructure page={passwordExpired || user_info?.is_initial_password ? <PasswordExpired /> : <GtwList />} />} />

                    { !passwordExpiredCookie &&
                    <>
                    <Route path={routes.gateway_detail.path + '/:gtw_id'} element={
                        !user_info?.permissions?.gtw_detail_page_read ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<SingleGtwDetail />} />
                    } />

                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.all_logs.path} element={
                      !user_info?.permissions?.gtw_detail_page_display_all_logs_button ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<AllLogs />} />
                    } />
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.anomaly_analyzer.path} element={
                      !user_info?.permissions?.gtw_detail_page_display_anomaly_analyzer_button ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<AnomalyAnalyzer />} />
                    } />
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.update_configs.path} element={
                      !user_info?.permissions?.gtw_detail_page_display_update_configs_button ?
                        <NonePermissionPage />
                        : <PageStructure page={<UpdateConfigs />} />
                    } />
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.sessions.path} element={
                      !user_info?.permissions?.gtw_detail_page_display_sessions_button ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<Sessions />} />
                    } />
                    {/* <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.node_logs.path} element={
                        <PageStructure page={<NodeLogs />} />
                    } /> */}
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.single_node_logs.path + '/:node_id'} element={
                        <PageStructure page={<NodeLog />} />
                    } />
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.single_node_logs.path + '/:node_id/charts'} element={
                        <PageStructure page={<NodeLog />} />
                    } />
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.single_node_logs.path + '/:node_id/battery'} element={
                        <PageStructure page={<NodeLog />} />
                    } />
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.single_node_logs.path + '/:node_id/charts/battery'} element={
                        <PageStructure page={<NodeLog />} />
                    } />
                    <Route path={routes.gateway_detail.path + '/:gtw_id/' + routes.single_node_logs.path + '/:node_id/status-history'} element={
                        <PageStructure page={<NodeLog />} />
                    } />
                    
                    <Route path={routes.gateway_detail.path + '/:gtw_id' + routes.notifications.path} element={
                        <PageStructure page={<Notifications />} />
                    } />

                    <Route path={routes.support_ticket.path} element={
                      !user_info?.permissions?.support_tickets_page_read ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<SupportTickets />} />
                    } />
                    <Route path={routes.resolved_support_ticket.path} element={
                      !user_info?.permissions?.resolved_support_tickets_page_read ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<ResolvedSupportTicketsList />} />
                    } />
                    <Route path={routes.single_support_ticket.path + '/:gtw_id/ticket/:ticketID'} element={
                      !user_info?.permissions?.support_tickets_detail_page_read ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<SupportTicketDetail />} />
                    } />
                    <Route path={routes.settings.path} element={
                      !user_info?.permissions?.settings_page_read ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<Settings />} />
                    } />

                    <Route path={routes.hotline.path} element={
                      // !user_info?.permissions?.hotline_member ?
                      //   <NonePermissionPage />
                      //   :
                        <PageStructure page={<Hotline />} />
                    } />

                    <Route path={routes.notifications.path} element={
                      !user_info?.is_superuser ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<Notifications />} />
                    } />

                    <Route path={routes.setup_logs.path} element={
                      !user_info?.is_superuser ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<SetupLogs />} />
                    } />
                    <Route path={routes.setup_log.path + '/:gtw_id/:log_id'} element={
                      !user_info?.is_superuser ?
                        <NonePermissionPage />
                        :
                        <PageStructure page={<SetupLogDetail />} />
                    } />
                    <Route path={routes.fota.path} element={
                      !user_info?.is_superuser ?
                        <NonePermissionPage />
                        :
                      <PageStructure page={<Fota />} />
                    } />

                    <Route path={routes.node_list.path} element={
                      !user_info?.is_superuser ?
                        <NonePermissionPage />
                        :
                      <PageStructure page={<NodeList />} />
                    } />

                    <Route path={routes.status_analytics.path} element={
                      !user_info?.is_superuser ?
                        <NonePermissionPage />
                        :
                      <PageStructure page={<DeviceStatusHistory />} />
                    } />
                    </>
                    }

                    <Route path={routes.password_expired.path} element={
                      passwordExpiredCookie || user_info?.is_initial_password ?
                        <PageStructure page={<PasswordExpired />} />
                        :
                        <NonePermissionPage />
                      } />
                    <Route path={routes.notFound.path} element={<Navigate to={routes.gateway_list.path} />} />

                  </>
              }
              <Route path={routes.notFound.path} element={<Navigate to={routes.login.path} />} />
            </Routes>
          </Suspense>
        </div>
      </ConfigProvider>
    </>
  );

}


export default App;
