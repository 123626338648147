export const routes = {
    asset_url: {
        path: `${process.env.REACT_APP_ASSET_URL}`,
        title: "Home Page"
    },
    simple_url: {
        path: `${process.env.REACT_APP_UNSPLASH_URL}`,
        title: "Home Page"
    },
    notFound: {
        path: "*",
        title: "Not Found"
    },
    login: {
        path: "/login",
        title: "Login"
    },
    home_page: {
        path: "/",
        title: "Home Page"
    },
    support_ticket: {
        path: "/support-tickets",
        title: "support_tickets"
    },
    resolved_support_ticket: {
        path: "resolved-support-tickets",
        title: "Resolved Support Tickets"
    },
    single_support_ticket: {
        path: "support-ticket",
        title: "Support Ticket"
    },
    setup_logs : {
        path: "/setup-logs",
        title: "setup_logs"
    },
    setup_log : {
        path: "setup-log",
        title: "Setup Log"
    },
    // map_route_managemenet: {
    //     path: "/route-managemenet",
    //     title: "Harita Rota Yönetimi"
    // },
    // maps: {
    //     path: "/maps",
    //     title: "Harita Ekranı"
    // },
    gateway_list: {
        path: "/gateway-list",
        title: "gateway_list"
    },
    gateway_detail: {
        path: "gateway-list",
        title: "Single Gateway Detail"
    },
    all_logs: {
        path: "all-logs",
        title: "All Logs"
    },
    anomaly_analyzer: {
        path: "anomaly-analyzer",
        title: "Anomaly Analyzer"
    },
    update_configs: {
        path: "update-configs",
        title: "Update Configs"
    },
    sessions: {
        path: "sessions",
        title: "Sessions"
    },
    node_logs: {
        path: "node-logs",
        title: "Node Logs"
    },
    single_node_logs: {
        path: "single-node-logs",
        title: "Single Node Logs"
    },
    settings: {
        path: "/settings",
        title: "settings"
    },
    fota: {
        path: "/fota",
        title: "version_management"
    },
    password_expired: {
        path: "/password-expired",
        title: "Password Expired"
    },
    notifications: {
        path: "/notifications",
        title: "notifications"
    },
    node_list: {
        path: "/node-list",
        title: "node_list"
    },
    status_analytics: {
        path: "/status-analytics",
        title: "status_analytics"
    },
    hotline: {
        path: "/hotline",
        title: "hotline"
    },
    delete_account: {
        path: "/delete-account",
        title: "delete_account"
    }
}
