import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { setTitle } from '../../../../core/stores/navbarTitleControllerSlice';
import { listBatteryLogs, listNodeLogs } from '../../../../core/actions/logs/nodeLogActions';
import { Button, Card, Col, DatePicker, Descriptions, Row, Select, theme } from 'antd';
import { useSelector } from 'react-redux';
import NodeLogTable from './NodeLogTable';
import { useMemo } from 'react';
import TimeCalculator from '../../../../core/utils/TimeCalculator';
import NodeLogChart from './NodeLogChart';
import { SEO_HELMET } from '../../../../core/utils/helmet';
import BatteryLogs from './Logs/BatteryLogs';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';


function NodeLog() {

    const {gtw_id, node_id} = useParams();

    const { RangePicker } = DatePicker;
    const dateFormat = 'DD-MM-YYYY';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {
        token: { colorBgLayout, colorTextHeading }
    } = theme.useToken();

    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 992px)` });
    const isLarge = useMediaQuery({ query: `(max-width: 1280px)` });
    // const isXLarge = useMediaQuery({ query: `(max-width: 1400px)` });

    const isMobileOrTablet = isMobile || isTablet;

    const date = new Date();

    const today = moment(date);
    const threeDaysAgo = moment(date).subtract(3, 'd');
    const defaultDate = [dayjs(threeDaysAgo.format(dateFormat), dateFormat), dayjs(today.format(dateFormat), dateFormat)];

    const [dateString, setDateString] = useState("");
    const [entriesPerPage, setEntriesPerPage] = useState(1000);
    const [pageNumber, setPageNumber] = useState(0);
    const [days, setDays] = useState(30);

    // Charts Zoom Settings
    const [resetZoomCount, setResetZoomCount] = useState(0);
    const [zoomCount, setZoomCount] = useState(0);
    const [autoScale, setAutoScale] = useState(false);

    const {
        nodeLogReducers: {
            loading,
            count,
            next,
            previous,
            nodeLogs,
            batteryLogs,
            sensor_version,
            sensor_type,
            gateway_gmt,
            room_type,
        }
    } = useSelector((state) => state)

    const location = useLocation();
    const path = useMemo(() => location.pathname.split("/"), [location.pathname])

    const convertDateToTimestamp = (date) => {
        return moment(date, dateFormat).unix();
    }

    const queryParams = {
        "device_serial": gtw_id,
        "sensor_id": node_id,
    }

    const nodeLogsQueryParams = {
        ...queryParams,
        "limit": entriesPerPage,
        "offset": pageNumber * entriesPerPage,
        "timestamp__gte": dateString[0] ? moment(dateString[0], dateFormat).unix() : convertDateToTimestamp(threeDaysAgo.set({hour: 0, minute: 0, second: 0})),
        "timestamp__lte": dateString[1] ? moment(dateString[1], dateFormat).set({hour: 23, minute: 59, second: 59}).unix() : convertDateToTimestamp(today.set({hour: 23, minute: 59, second: 59})),
    }

    const batteryLogsQueryParams = {
        ...queryParams,
        "limit": days,
        "offset": pageNumber * days,
    }

    useEffect(() => {
        
        if (path[path?.length - 1] === "battery") {
            dispatch(listBatteryLogs(batteryLogsQueryParams));
        } else {
            dispatch(listNodeLogs(nodeLogsQueryParams));
        }

    }, [gtw_id, node_id, dispatch, pageNumber])

    useEffect(() => {
        setPageNumber(0);
        if (path[path?.length - 1] === "battery") {
            dispatch(listBatteryLogs(batteryLogsQueryParams));
        }else {
            dispatch(listNodeLogs(nodeLogsQueryParams));
        }
    }, [entriesPerPage, days])

    const resetFilters = () => {
        setDateString("");
        setPageNumber(0);
        setDays(30);
        setEntriesPerPage(1000);
    }

    useEffect(() => {
        resetFilters();
        if (path[path?.length - 1] === "charts") {
            dispatch(setTitle(t('pages.node_logs.charts')));
            dispatch(listNodeLogs(nodeLogsQueryParams));
        } else if (path[path?.length - 1] === "battery") {
            dispatch(setTitle(t('pages.node_logs.battery_charts')));
            dispatch(listBatteryLogs(batteryLogsQueryParams));
        } else {
            dispatch(setTitle(t('pages.node_logs.title')));
            dispatch(listNodeLogs(nodeLogsQueryParams));
        }
    }, [path])


    const roomTypeFromID = (id) => {
        switch (id) {
            case "1":
                return t('room_types.living_room');
            case "2":
                return t('room_types.bedroom');
            case "3":
                return t('room_types.bathroom');
            case "4":
                return t('room_types.kitchen');
            case "5":
                return t('room_types.entrance');
            case "6":
                return t('room_types.other');
            case "7":
                return t('room_types.lavatory');
            default:
                return t('general.unknown');
        }
    }

    const sensorTypeFromID = (id) => {
        switch (id) {
            case "1":
                return t('sensor_types.motion');
            case "2":
                return t('sensor_types.door');
            case "3":
                return t('sensor_types.force');
            case "4":
                return t('sensor_types.emergency_button')
            case "5":
                return t('sensor_types.humidity_motion');
            case "6":
                return t('sensor_types.sleep_monitor');
            case "7":
                return t('sensor_types.extender');
            default:
                return t('general.unknown');
        }
    }

    var onChangeRangePicker = (date, dateString) => {
        setDateString(dateString)
    }

    const descriptionItems = [
        {
            label:
                <div className='text-center text-md-start'>
                    {t('pages.node_logs.description_items.gateway_id')}
                </div>,
            children: gtw_id
        },
        {
            label:
                <div className='text-center text-md-start'>
                    {t('pages.node_logs.description_items.room_type')}
                </div>,
            children: room_type ? roomTypeFromID(room_type) : t('general.unknown')
        },
        {
            label:
                <div className='text-center text-md-start'>
                    {t('pages.node_logs.description_items.node_id')}
                </div>,
            children:
                <Row className='justify-content-center justify-content-md-start'>
                    <Col>{node_id}</Col>
                    <Col className='ms-2'>-</Col>
                    <Col className='ms-2'>{sensor_type ? sensorTypeFromID(sensor_type) : t('general.unknown')}</Col>
                </Row>
        },
        {
            label:
                <div className='text-center text-md-start'>
                    {t('pages.node_logs.description_items.gateway_local_time')}
                </div>,
            children: <TimeCalculator gateway_gmt={gateway_gmt} />
        },
    ]

    if (path[path?.length - 1] !== "battery") {

        isMobile ?
        descriptionItems.splice(1, 0, {
            label:
                <div className='text-center text-md-start'>
                    {t('pages.node_logs.description_items.date')}
                </div>,
            children: 
                <>
                    <RangePicker
                        format={dateFormat}
                        onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                        defaultValue={defaultDate}
                    />
                    <Button className='ms-3 align-self-center' disabled={!dateString && true} onClick={() => {dateFilter()}}>{t('pages.node_logs.description_items.filter')}</Button>
                    {path[path?.length - 1] !== "battery" && nodeLogs?.length > 0 &&  <Button className='ms-3 align-self-center' onClick={() => {navigate('./battery')}}>{t('pages.node_logs.description_items.battery_logs')}</Button>}
                </>
        })
        :
        descriptionItems.push(
            {
                label:
                    <div className='text-center text-md-start'>
                        {t('pages.node_logs.description_items.date')}
                    </div>,
                children: 
                    <>
                        <RangePicker
                            format={dateFormat}
                            onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                            defaultValue={defaultDate}
                        />
                        <Button className='ms-3 align-self-center' disabled={!dateString && true} onClick={() => {dateFilter()}}>{t('pages.node_logs.description_items.filter')}</Button>
                        {path[path?.length - 1] !== "battery" && nodeLogs?.length > 0 &&  <Button className='ms-3 align-self-center' onClick={() => {navigate('./battery')}}>{t('pages.node_logs.description_items.battery_logs')}</Button>}
                    </>
            },
            {
                label:
                    <div className='text-center text-md-start'>
                        {t('pages.node_logs.description_items.total_entries')}
                    </div>,
                children: count
            }
        )
        
    } else {
        descriptionItems.push(
            {
                label: t('pages.node_logs.description_items.node_ver'),
                children: sensor_version || t('general.unknown')
            }
        )
    }

    const dateFilter = () => {
        setPageNumber(0);
        dispatch(listNodeLogs(nodeLogsQueryParams));
    }

    const isThereData = (logs) => {
        if (logs.length > 0) {
            return true;
        }
        return false;
    }

    const isSensorTypeSleepMonitor = (sensor_type) => {
        if (sensor_type === "6") {
            return true;
        }
        return false;
    }

    const getLogsByPath = () => {
        if (path[path?.length - 1] === "battery") {
            return batteryLogs;
        } else {
            return nodeLogs;
        }
    }

    const renderContent = () => {
        if (path[path?.length - 1] === "charts") {
            return <>
                    <SEO_HELMET title={`${gtw_id.substring(gtw_id?.length - 4)} - ${node_id}`} />
                    <Card>
                        <NodeLogChart dataSource={nodeLogs} loading={loading} sensor_type={sensor_type} reset_zoom={resetZoomCount} zoom={zoomCount} autoScale={autoScale} gateway_gmt={gateway_gmt}/>
                    </Card>
                </>
        } else if (path[path?.length - 1] === "battery") {
            return <>
                <SEO_HELMET title={`${gtw_id.substring(gtw_id?.length - 4)} - ${node_id}`} />
                <Card>
                    <BatteryLogs dataSource={batteryLogs} loading={loading} reset_zoom={resetZoomCount} zoom={zoomCount} autoScale={autoScale} />
                </Card>
            </>
        } else {
            return <>
                    <SEO_HELMET title={`${gtw_id.substring(gtw_id?.length - 4)} - ${node_id}`} />
                    <NodeLogTable
                        dataSource={nodeLogs}
                        loading={loading}
                        sensor_type={sensor_type}
                        table_name={sensorTypeFromID(sensor_type)}
                        gateway_gmt={gateway_gmt}
                    />
                </>
        }
    }

    return (
        <>
            <Descriptions
                items={descriptionItems}
                bordered
                column={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 4 }}
                size='small'
                layout={
                    isMobileOrTablet ?
                    'vertical'
                    :
                    'horizontal'
                }
                className='text-center text-md-start'
            />
            <Col className='mt-3' style={{
                marginBottom: isMobile ?
                    path[path?.length - 1] === "charts" || path[path?.length - 1] === "battery" ? '225px' : '125px'
                    : isTablet ?
                    path[path?.length - 1] === "charts" || path[path?.length - 1] === "battery" ? '225px' : '125px'
                    : isLarge ?
                    path[path?.length - 1] === "charts" || path[path?.length - 1] === "battery" ? '125px' : '75px'
                    : ''
            }}>
                {renderContent()}
            </Col>

            <Row className='justify-content-center justify-content-xl-between px-xl-5 ms-xl-5 flex-md-column flex-xl-row gap-3 align-items-center fixed-bottom py-3' style={{background: colorBgLayout }}>
                
                <Row className='justify-content-center align-items-center'>
                    {path[path?.length - 1] === "charts" || path[path?.length - 1] === "battery" ?
                        <>
                            <Button onClick={() => setPageNumber(prev => prev + 1)} disabled={!next || loading}>{t('pages.node_logs.pagination_items.next_page_for_charts')}</Button>
                            <span style={{ margin: '0 10px' }}>{pageNumber + 1}</span>
                            <Button disabled={pageNumber <= 0 || !previous || loading} onClick={() => setPageNumber(prev => prev - 1)}>{t('pages.node_logs.pagination_items.before_page_for_charts')}</Button>
                        </>
                    :
                        <>
                            <Button disabled={pageNumber <= 0 || loading || !previous} onClick={() => setPageNumber(prev => prev - 1)}>{t('pages.node_logs.pagination_items.before_page')}</Button>
                            <span style={{ margin: '0 10px' }}>{pageNumber + 1}</span>
                            <Button onClick={() => setPageNumber(prev => prev + 1)} disabled={!next || loading}>{t('pages.node_logs.pagination_items.next_page')}</Button>
                        </>
                    }
                </Row>

                <Row className='gap-2 justify-content-center flex-md-column flex-lg-row'>
                    { (path[path.length - 1] === "battery" || path[path?.length - 1] === "charts") &&
                        <Row className='gap-2'>
                            <Col>
                                <Button disabled={!isThereData(getLogsByPath()) || loading} onClick={() => {setResetZoomCount(prev => prev + 1); setZoomCount(0)}}>{t('pages.node_logs.pagination_items.reset_zoom')}</Button>
                            </Col>
                            <Col>
                                <Button disabled={!isThereData(getLogsByPath()) || loading || isSensorTypeSleepMonitor(sensor_type)} onClick={() => setAutoScale(prev => !prev)}>{autoScale ? t('pages.node_logs.pagination_items.disable_auto_scale') : t('pages.node_logs.pagination_items.enable_auto_scale')}</Button>
                            </Col>
                        </Row>
                    }

                    {path[path?.length - 1] === "charts" &&           
                    <Row className='gap-2'>
                        <Col>
                            <Button disabled={nodeLogs?.length === 0 || loading} onClick={() => setZoomCount(prev => prev + 1)}>{t('pages.node_logs.pagination_items.zoom_x')}</Button>
                        </Col>
                        <Col>
                            <Button disabled={nodeLogs?.length === 0 || loading || zoomCount === 0} onClick={() => setZoomCount(prev => prev - 1)}>{t('pages.node_logs.pagination_items.zoom_out_x')}</Button>
                        </Col>
                    </Row>
                    }
                </Row>
                
                { path[path?.length - 1] === "battery" ?
                    <Row className='align-items-center'>
                        <label style={{ marginRight: '10px', fontWeight: 'bold', color: colorTextHeading }}>{t('pages.node_logs.pagination_items.days')}</label>
                        <Select
                            options={[
                                { label: 30, value: 30 },
                                { label: 60, value: 60 },
                                { label: 90, value: 90 },
                                { label: 180, value: 180 },
                                { label: 365, value: 365 },
                            ]}
                            onChange={setDays}
                            defaultValue={days}
                        />
                    </Row>
                    :
                    <Row className='align-items-center'>
                        <label style={{ marginRight: '10px', fontWeight: 'bold', color: colorTextHeading }}>{t('pages.node_logs.pagination_items.entries_per_page')}</label>
                        <Select
                            options={[
                                { label: 1000, value: 1000 },
                                { label: 2000, value: 2000 },
                                { label: 5000, value: 5000 },
                            ]}
                            onChange={setEntriesPerPage}
                            defaultValue={entriesPerPage}
                        />
                    </Row>
                }
            </Row>
        </>
    )
}

export default NodeLog
