import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
  BarElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Spin, theme } from 'antd';
import { useSelector } from 'react-redux';

import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import hammer from "hammerjs";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';


function BatteryLogs({dataSource, reset_zoom, autoScale}) {

    ChartJS.register(
        CategoryScale,
        TimeScale,
        LinearScale,
        BarElement,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend,
        zoomPlugin
    );

    const {
        token: {
            colorError,
            colorTextHeading,
            colorTextLabel
        }
    } = theme.useToken();

    const {
        nodeLogReducers: { loading }
    } = useSelector((state) => state);
    const {t} = useTranslation();
    const [reversedDataSource, setReversedDataSource] = useState([]);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1280px)' });
    const isMobileOrTablet = isMobile || isTablet;

    const chartRef = React.useRef(null);

    const updateChartRef = (chart) => {
        chartRef.current = chart;
    };
    
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            point: {
                radius: 5,
                hoverRadius: 10,
            },
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    color: colorTextLabel
                }
            },
            title: {
                display: true,
                text: `${t('tables.total_data')} => ${dataSource?.length}`,
                position: 'bottom',
                align: 'end',
                padding: {
                    top: 30,
                },
                color: colorTextHeading
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            zoom: {
                pan: {
                    enabled: true,
                },
                limits: {
                    x: { min: 'original', max: 'original' },
                    y: { min: 'original', max: 'original' },
                },
                zoom: {
                    mode: 'xy',
                    wheel: {
                        enabled: true,
                        modifierKey: 'ctrl',
                    },
                    pinch: {
                        enabled: true,
                    }
                },
            }
        },
        hover: {
            mode: 'index',
            intersect: false
        },
        scales: {
            y: {
                suggestedMin: 2,
                suggestedMax: 3.5,
                ticks: {
                    stepSize: 0.1,
                    color: colorTextLabel
                }
            },
            x: {
                ticks: {
                    color: colorTextLabel,
                    callback: function(value, index, values) {
                        const label = this.getLabelForValue(value);
                        return label.split('-').join('/');
                    }
                }
            }
        }
    };

    const [times, setTimes] = useState([]);
    const [voltageValues, setVoltageValues] = useState([]);

    function formatDate(input) {
        const splittedDate = input.split('T')[0];
        
        const year = splittedDate.split('-')[0];
        const month = splittedDate.split('-')[1];
        const day = splittedDate.split('-')[2];

        return `${day}-${month}-${year}`;
    }

    useEffect(() => {
        
        if (dataSource?.length > 0) {

            const reversedDataSource = [...dataSource].reverse();

            setReversedDataSource(reversedDataSource);
            
            const times = reversedDataSource.map((log) => {
                return formatDate(log.created_date);
            });
            setTimes(times);

            const batteryVoltages = reversedDataSource.map((log) => {
                return log.battery_voltage;
            });
            setVoltageValues(batteryVoltages);
        }

    }, [dataSource])

    const chartData = {
        labels: times,
        datasets: [
            {
                label: t('pages.node_charts.battery_chart.battery_voltage'),
                data: voltageValues,
                fill: false,
                borderColor: colorError,
                backgroundColor: 'rgba(255, 0, 0, 0.3)',
                pointBackgroundColor: (context) => {
                    if (context.dataIndex === 0) return 'rgba(255, 0, 0, 0.3)';

                    const currentValue = reversedDataSource[context.dataIndex]?.timestamp;
                    const previousValue = reversedDataSource[context.dataIndex - 1]?.timestamp;

                    if (currentValue === previousValue) return 'white';

                    return 'rgba(255, 0, 0, 0.3)';
                },
            }
        ],
    };

    useEffect(() => {

        const chart = chartRef?.current;

        if (chart) {
            const chartOptions = chart?.options;

            chartOptions.scales.y = autoScale ? {} : options.scales.y;

            chart.resetZoom();
            updateChartRef(chart);
        }
    }, [autoScale, reset_zoom])

    const renderChart = () => {
        if (loading) return <Spinner />;
        if (!dataSource || dataSource.length === 0) return <NoDataMessage />;

        return (
            <div style={{ height: isMobile ? '50vh' : isTablet ? '40vh' : '70vh' }}>
                <Line ref={chartRef} data={chartData} options={options} />
            </div>
        )
    };

    return (
        <>
            {renderChart()}
        </>
    );
}

const Spinner = () => (
    <div className='d-flex justify-content-center align-items-center user-select-none' style={{ height: '70vh' }}>
        <Spin size='large' />
    </div>
);

const NoDataMessage = () => {
    const {t} = useTranslation();

    return (
        <div className='d-flex justify-content-center align-items-center user-select-none' style={{ height: '70vh' }}>
            <p className='h1'>{t('pages.node_logs.no_data')}</p>
        </div>
    );
}

export default BatteryLogs;
