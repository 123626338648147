import { createSlice } from '@reduxjs/toolkit'


export const modalControllerSlice = createSlice({
  name: 'modalController',
  initialState: {
    modalId: '',
    isModalOpen: false,
    modalData: <></>,
    modalCaption: "",
    modalOnClose: null,
    closeButton: true,
    backButton: false,
    backButtonFn: null,
    backCompData: <></>,
  },

  reducers: {
    setModalId: (state, action) => {
      state.modalId = action.payload
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload
    },
    setModalData: (state, action) => {
      state.modalData = action.payload
    },
    setModalCaption: (state, action) => {
      state.modalCaption = action.payload
    },
    setModalOnClose: (state, action) => {
      state.modalOnClose = action.payload
    },
    setIsCloseButton: (state, action) => {
      state.closeButton = action.payload
    },
    setBackButton: (state, action) => {
      state.backButton = action.payload
    },
    setBackButtonFn: (state, action) => {
      state.backButtonFn = action.payload
    },
    setBackCompData: (state, action) => {
      state.backCompData = action.payload
    },

  }
})


export const { setBackCompData, setBackButtonFn, setBackButton, setIsCloseButton, setIsModalOpen, setModalData, setModalCaption, setModalId, setModalOnClose } = modalControllerSlice.actions

export default modalControllerSlice.reducer