import React, { useEffect, useState } from 'react';
import moment from 'moment';

function TimeCalculator({ gateway_gmt }) {
    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Şu anki lokal saati al
            const now = moment();

            // Saati gateway_gmt değerine göre ayarla
            now.utc().add(gateway_gmt - 12, 'hours');
            
            // Formatla ve kaydet
            const formattedTime = now.format('HH:mm:ss');
            setCurrentTime(formattedTime);
        }, 1000);

        return () => clearInterval(intervalId);  // Cleanup
    }, [gateway_gmt]);

    return <>{currentTime || "Unknown"}</>;
}

export default TimeCalculator;
