import { List } from "antd"
import { useSelector } from "react-redux";


function OnlineOperatorsModal() {
    
    const {
        antdModalController: { onlineOperators }
    } = useSelector(state => state);

    return <>
        <List
            pagination={{
                position: 'bottom',
                align: 'center',
                pageSize: 7,
                pageSizeOptions: ['5', '7', '10'],
                size: 'small',
                showSizeChanger: true,
                showQuickJumper: false,
                hideOnSinglePage: true,
                showTotal: (total, range) => {return `${range[0]}-${range[1]} of ${total} operators`}
            }}
            dataSource={onlineOperators}
            size='small'
            renderItem={(operator, index) => (
                <List.Item key={index} className='text-center'>
                    <List.Item.Meta
                        title={`${operator.first_name} ${operator.last_name}`}
                        description={operator?.email || `${operator.first_name.toLowerCase()}@larry.com`}
                    />
                </List.Item>
            )}
        />
    </>
}

export default OnlineOperatorsModal