import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table, theme } from 'antd';
import { routes } from '../../../../../core/route/router';
import { Link } from 'react-router-dom';
import moment from 'moment';


function OldNodesTable({ oldNodes, loading }) {

    const { t } = useTranslation();
    const {
        token: {
            colorInfo
        }
    } = theme.useToken();

    const roomTypeMapping = {
        1: t('room_types.living_room'),
        2: t('room_types.bedroom'),
        3: t('room_types.bathroom'),
        4: t('room_types.kitchen'),
        5: t('room_types.entrance'),
        6: t('room_types.other'),
        7: t('room_types.lavatory')
    };

    const nodeTypeMapping = {
        1: t('sensor_types.motion'),
        2: t('sensor_types.door'),
        3: t('sensor_types.force'),
        4: t('sensor_types.emergency_button'),
        5: t('sensor_types.humidity_motion'),
        6: t('sensor_types.sleep_monitor'),
        7: t('sensor_types.extender')
    }

    const columns = [
        {
            'title': t('pages.gateway_detail.rooms_and_sensors_table.node_id'),
            'dataIndex': 'sensor_id',
            'key': 'sensor_id'
        },
        {
            'title': t('pages.gateway_detail.rooms_and_sensors_table.node_name'),
            'dataIndex': 'sensor_type',
            'key': 'sensor_type',
            'render': (text) => nodeTypeMapping[text]
        },
        {
            'title': t('pages.gateway_detail.rooms_and_sensors_table.room_id'),
            'dataIndex': 'room_id',
            'key': 'room_id'
        },
        {
            'title': t('pages.gateway_detail.rooms_and_sensors_table.room_type'),
            'dataIndex': 'room_type',
            'key': 'room_type',
            'render': (text) => roomTypeMapping[text]
        },
        {
            'title': t('pages.gateway_detail.gateway_old_node_last_record').toUpperCase(),
            'dataIndex': 'timestamp',
            'key': 'timestamp',
            'render': (text) => moment.unix(text).format('DD/MM/YYYY')
        },
        {
            'title': '',
            'dataIndex': 'logs',
            'key': 'logs',
            'render': (text, record) => <Link to={routes.single_node_logs.path + '/' + record.sensor_id} className='d-flex justify-content-center align-items-center'>
                <i style={{color: colorInfo}} class="fa-solid fa-circle-info fa-xl"></i>
            </Link>,
            width: 120
        }
    ]

    return (
        <>
            <Table
                dataSource={[...oldNodes].sort((a, b) => b.timestamp - a.timestamp)}
                columns={columns}
                loading={loading}
                pagination={false}
                bordered

                footer={(currentPageData) => (
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            {t('tables.total_data')}: <b>{currentPageData.length}</b>{" "}
                        </div>
                    </div>
                )}
            />
        </>
    )
}

export default OldNodesTable