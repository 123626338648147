import { api } from "../../apis/Apis";
import { setupLogDetailFail, setupLogDetailRequest, setupLogDetailSuccess, setupLogListFail, setupLogListRequest, setupLogListSuccess } from "../../stores/logs/setupLogSlice";


export const listSetupLogs = (filters) => async (dispatch) => {
    try{
        dispatch(setupLogListRequest());

        const { data } = await api.get('logs/setup-logs/', { params: filters });

        dispatch(setupLogListSuccess(data));
    }catch(error){
        dispatch(setupLogListFail(
            error.response && error.response.data.detail
            ? error.response.data.detail || error.response.data.error
            : error.message,
        ));
    }
}


export const detailSetupLog = (id) => async (dispatch) => {
    try{
        dispatch(setupLogDetailRequest());

        const { data } = await api.get(`logs/setup-logs/${id}/`);

        dispatch(setupLogDetailSuccess(data));
    }catch(error){
        dispatch(setupLogDetailFail(
            error.response && error.response.data.detail
            ? error.response.data.detail || error.response.data.error
            : error.message,
        ));
    }
}
