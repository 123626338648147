import React from 'react'
import { setIsToastyOpen, setToastyCaption, setToastyData } from '../../core/stores/toastyControllerSlice';
import { useDispatch, useSelector } from 'react-redux'
// import WarningIcon from '../../assets/icons/icon-warning.png'
import WarningIcon from '../../assets/icons/complain.png'
import DangerIcon from '../../assets/icons/warning.png'
import TickIcon from '../../assets/icons/checked.png'
import './Toasty.css'

function Toasty() {

    //? STATES
    const isToastyOpen = useSelector(state => state.toastyController.isToastyOpen)
    const toastyVariant = useSelector(state => state.toastyController.toastyVariant)
    const toastyData = useSelector(state => state.toastyController.toastyData)
    const toastyCaption = useSelector(state => state.toastyController.toastyCaption)
    const position = useSelector(state => state.toastyController.position)

    const dispatch = useDispatch();

    const closeToasty = (e) => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
            dispatch(setIsToastyOpen(false))
        }
    }


    const positionHandle = () => {
        if (position === 'rightTop') {
            return {
                top: '0',
                right: '0'
            }
        }
        else if (position === 'centerTop') {
            return {
                top: '0',
                left: '50%',
                transform: 'translateX(-50%)',
            }
        }
        else if (position === 'center') {
            return {
                top: '50%',
                left: '50%',
                transform: 'translateX(-50%) translateY(-80%)'
            }
        }
        else {
            return {
                top: '0',
                left: '50%',
                transform: 'translateX(-50%)',
            }
        }
    }
    const variantHandle = () => {
        if (toastyVariant === 'success') {
            return {
                image: TickIcon,
                backgroundColor: '#32ba7d54',
                borderLeft: '#32ba7c',
                borderBottom: '#0280681e',
                headerText: 'Başarılı',
            }
        }
        else if (toastyVariant === 'warning') {
            return {
                image: WarningIcon,
                backgroundColor: '#fff7d1f8',
                borderLeft: '#febb08',
                borderBottom: '#f9f1cc',
                headerText: 'Uyarı',
            }
        }
        else if (toastyVariant === 'danger') {
            return {
                image: DangerIcon,
                backgroundColor: '#e500262c',
                borderLeft: '#fd003a',
                borderBottom: '#e5002617',
                headerText: 'Hata',
            }
        }
    }

    return (
        <div id='Toasty' onClick={(e) => closeToasty(e)} style={isToastyOpen ? { display: 'block' } : { display: 'none' }}>
            <div className='Toasty_cover' style={{ ...positionHandle() }}>
                <div className='title d-flex align-items-center justify-content-between flex-row'
                    style={{
                        backgroundColor: variantHandle().backgroundColor,
                        borderLeftColor: variantHandle().borderLeft,
                        borderBottomColor: variantHandle().borderBottom,
                    }}
                >
                    <div className='d-flex align-items-center justify-content-center flex-row'>
                        <img src={variantHandle().image} alt="" width={"30px"} />
                        <span style={{ color: variantHandle().borderLeft }}>{variantHandle().headerText}</span>
                    </div>
                    <div onClick={() => dispatch(setIsToastyOpen(false))} style={{ userSelect: 'none', cursor: 'pointer', fontSize: '30px', marginRight: '20px', fontWeight: 'bold', color: variantHandle().borderLeft }}>X</div>
                </div>
                <div className='Toasty_content'>
                    {/* <span className='Toasty_content_toastyCaption'>
                        {toastyCaption}
                    </span> */}
                    <div className='Toasty_content_toastyData'>
                        {toastyData}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Toasty