import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { setIsModalOpenAntd } from '../../../core/stores/antdModalControllerSlice';
import { useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { updateRoom } from '../../../core/apis/Apis';
import { toast } from 'react-toastify';
import ImageDropdown from './ImageDropdown';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function EditRoomModal({ gtwID, room, room_image_id }) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [roomType, setRoomType] = useState('');

    const {
        authReducers: {user_info}
    } = useSelector((state) => state)

    const [form] = Form.useForm();
    const room_id = Form.useWatch('room_id', form);
    const room_name = Form.useWatch('room_name', form);
    const room_type = Form.useWatch('room_type', form);
    const room_image = Form.useWatch('room_image', form);

    const roomTypeOptions = [
		{ label: t("room_types.living_room"), value: 1 },
		{ label: t("room_types.bedroom"), value: 2 },
		{ label: t("room_types.bathroom"), value: 3 },
		{ label: t("room_types.kitchen"), value: 4 },
		{ label: t("room_types.entrance"), value: 5 },
		{ label: t("room_types.other"), value: 6 },
		{ label: t("room_types.lavatory"), value: 7 },
	];

    useEffect(() => {
        switch (room.type) {
            case 1:
                setRoomType(t("room_types.living_room"));
                break;
            case 2:
                setRoomType(t("room_types.bedroom"));
                break;
            case 3:
                setRoomType(t("room_types.bathroom"));
                break;
            case 4:
                setRoomType(t("room_types.kitchen"));
                break;
            case 5:
                setRoomType(t("room_types.entrance"));
                break;
            case 6:
                setRoomType(t("room_types.other"));
                break;
            case 7:
                setRoomType(t("room_types.lavatory"));
                break;
            default:
                break;
        }
    }, [room])
    

    const editRoomHandle = () => {

        const updateData = {
            "updated_room": {
                "id": room_id,
                "name": room_name,
                "type": room_type
            }
        };

        if (room_type === 6) {
            updateData.room_image = {
                "room_id": room_id,
                "image_id": room_image,
            }
        }

        updateRoom(gtwID, room.id, updateData)
            .then(res => {
                if (res.status === 200) {
                    cancel()
                    toast.success("Room is updated", {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(res.data.detail, {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log("edit room error:",err);
                toast.error(err.response.data?.detail || "An error occured", {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const cancel = () => {
        dispatch(setIsModalOpenAntd(false))
    }

    /* -------------------------------------------------------------------------- */
    //TODO -                    ALL ROOMS AND TYPES                               */
    /* -------------------------------------------------------------------------- */
    const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(true);


    return <div>
        <Form
            form={form}
            layout='vertical'
            onFinish={editRoomHandle}
        >
            <Row gutter={[15, 0]}>

                { user_info.is_superuser &&

                    <Col xs={{ span: 4 }}>
                        <Form.Item
                            name='room_id'
                            label={t('modals.edit_room_modal.room_id_label')}
                            initialValue={room.id}
                        >
                            <InputNumber min={1}/>
                        </Form.Item>
                    </Col>
                
                }

                <Col xs={{ span: user_info.is_superuser ? 20 : 24 }}>
                    <Form.Item
                        name='room_name'
                        label={t('modals.edit_room_modal.room_name_label')}
                        initialValue={room.name}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 24 }}>
                    <Form.Item
                        name='room_type'
                        label={t('modals.edit_room_modal.room_type_label')}
                        initialValue={room.type}
                    >
                        <Select
                            options={roomTypeOptions}
                        />
                    </Form.Item>
                </Col>

                { room_type === 6 &&
                <Col xs={{ span: 24 }}>
                    <Form.Item
                        name='room_image'
                        label={t('modals.edit_room_modal.room_image_label')}
                        required tooltip={t('general.required_field')}
                        rules={
                            [
                                { required: true, message: t('modals.add_room_modal.room_image_required') },
                            ]
                        }
                    >
                        <ImageDropdown roomImage={room_image_id} />
                    </Form.Item>
                </Col>
                }
            </Row>
            <Row className='mb-2 w-100'>
                <div className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-center', flexDirection: 'column', textAlign: 'left', width: '100%' }}>
                    <span><strong>{t('tables.gtw_id')}</strong> --------> <strong>{gtwID}</strong></span>
                    <span><strong>{t('pages.gateway_detail.rooms_and_sensors_table.room_id')}</strong> --------> <strong>{room.id}</strong></span>
                    <span><strong>{t('pages.gateway_detail.rooms_and_sensors_table.room_name')}</strong> --------> <strong>{room.name}</strong></span>
                    <span><strong>{t('pages.gateway_detail.rooms_and_sensors_table.room_type')}</strong> --------> <strong>{roomType}</strong></span>
                    {t('modals.edit_room_modal.edit_room_text')}
                    <span className='text-danger fw-bold mt-3 mb-3'>{gtwID}/{room.id}</span>
                    <Input onChange={(e) => {
                        if (e.target.value === `${gtwID}/${room.id}`) {
                            setIsEditButtonDisabled(false)
                        }
                        else {
                            setIsEditButtonDisabled(true)
                        }
                    }} />
                </div>
            </Row>
            <Row className='footer_buttons_part' justify="center" align="center"  >
                <Button
                    className='me-2'
                    icon={<i className="fa-solid fa-xmark pe-1"></i>}
                    onClick={() => cancel()} >
                    {t('general.cancel')}
                </Button>
                <Button
                    onClick={() => editRoomHandle()}
                    type="primary"
                    danger
                    disabled={isEditButtonDisabled}
                    icon={<i className="fa-solid fa-floppy-disk pe-1"></i>}>
                    {t('general.update')}
                </Button>
            </Row>
        </Form>
    </div>
}

export default EditRoomModal
