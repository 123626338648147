import React from 'react'
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { Col, Descriptions, Row } from 'antd';

function FotaLogDetailModal({fotaLog, gatewayGMT}) {
	
	const {t} = useTranslation();

	const items = [
		{
			key: "fotaLog.id",
			label: t('modals.fota_log_detail_modal.id_label'),
			span: 2,
			children: fotaLog.id,
		},
		{
			key: "fotaLog.timestamp",
			label: t('modals.fota_log_detail_modal.date_label'),
			span: 2,
			children: gatewayGMT ? Moment.unix(fotaLog.timestamp).utcOffset(gatewayGMT - 12).format("DD/MM/YY HH:mm") : Moment.unix(fotaLog.timestamp).format("DD/MM/YY HH:mm"),
		},
		{
			key: "fotaLog.device_serial",
			label: t('modals.fota_log_detail_modal.device_serial_label'),
			span: 2,
			children: fotaLog.device_serial,
		},
		{
			key: "fotaLog.user",
			label: t('modals.fota_log_detail_modal.username_label'),
			children: fotaLog.user,
		},
		{
			key: "fotaLog.old_fota_parameters",
			label: t('modals.fota_log_detail_modal.old_fota_params_label'),
			span: 3,
			children: <>
				<Row className="flex-column gap-2 mb-2">
					<Col className="fw-bold">{t('tables.version')}:</Col>
					<Col>{fotaLog.old_fota_parameters.version !== "Unknown" ? fotaLog.old_fota_parameters.version : t('general.unknown')}</Col>
				</Row>
				<Row className="flex-column gap-2 mb-2">
					<Col className="fw-bold">{t('tables.fota_table.command')}:</Col>
					<Col>{fotaLog.old_fota_parameters.command}</Col>
				</Row>
				<Row className="flex-column gap-2">
					<Col className="fw-bold">{t('tables.fota_table.changelog')}:</Col>
					<Col>{fotaLog.old_fota_parameters.changelog !== "Unknown" ? fotaLog.old_fota_parameters.changelog : t('general.unknown')}</Col>
				</Row>
			</>,
		},
		{
			key: "fotaLog.new_fota_parameters",
			label: t('modals.fota_log_detail_modal.new_fota_params_label'),
			span: 3,
			children: <>
				<Row className="flex-column gap-2 mb-2">
					<Col className="fw-bold">{t('tables.version')}:</Col>
					<Col>{fotaLog.new_fota_parameters.version}</Col>
				</Row>
				<Row className="flex-column gap-2 mb-2">
					<Col className="fw-bold">{t('tables.fota_table.command')}:</Col>
					<Col>{fotaLog.new_fota_parameters.command}</Col>
				</Row>
				<Row className="flex-column gap-2">
					<Col className="fw-bold">{t('tables.fota_table.changelog')}:</Col>
					<Col>{fotaLog.new_fota_parameters.changelog}</Col>
				</Row>
			</>,
		},
	];

	return (
		<Descriptions bordered title={t('modals.fota_log_detail_modal.title')} items={items} />
	)
}

export default FotaLogDetailModal