import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './NavbarMenu.css'


import { setOpenCloseMenu } from '../../core/stores/menuControllerSlice';
import ClassicButton from '../buttons/classic_buttons/ClassicButton';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPermissionsAction, logoutAction, updateTheme } from '../../core/actions/auth/authActions';
import { useTranslation } from 'react-i18next';
import { setIsModalOpenAntd, setModalCaptionAntd, setModalDataAntd, setStyleAntd } from '../../core/stores/antdModalControllerSlice';
import { Button, Switch, theme } from 'antd';




function NavbarMenu() {

  const menuController = useSelector(state => state.menuController)
  const isModalOpen = useSelector(state => state.modalController.isModalOpen)
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true' ? true : false

  const {
    authReducers : { user_info, isDarkMode }
  } = useSelector((state) => state)

  const {t} = useTranslation()

  const {
    token: {
      colorBgContainer,
      colorPrimary,
      colorBgSpotlight
    }
  } = theme.useToken();


  /* -------------------------------------------------------------------------- */
  //TODO -                             MEDIAQUERY                                */
  /* -------------------------------------------------------------------------- */
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


  const menuOpenCloseHandle = () => {
    dispatch(setOpenCloseMenu(!menuController.isOpen))
  }


  const logOutModalHandle = () => {
    dispatch(setModalDataAntd(<LogOutModal />))
    dispatch(setStyleAntd({ maxWidth: '800px' }))
    dispatch(setIsModalOpenAntd(true))
    dispatch(setModalCaptionAntd(t('modals.logout_modal.title')))
  }

  const menuTitle = useSelector(state => state.navbarTitleController.title)

  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated){
      dispatch(getPermissionsAction())
    }
  }, [location.pathname])

  if (isTabletOrMobile) {
    return (
      <div id='NavbarMenu' style={{ background: isDarkMode ? colorBgSpotlight : colorPrimary}}>
        <div className="NavbarMenu-menu_icon" style={{marginLeft: menuController.isOpen ? '40px' : ''}} >
          <i class="fa-solid fa-xl fa-bars" style={{color: "#ffffff"}} onClick={menuOpenCloseHandle}></i>
        </div>
        <div className="NavbarMenu-items">
          <Switch
            className='mx-3'
            checkedChildren={<i class="fa-regular fa-sun"></i>}
            unCheckedChildren={<i class="fa-regular fa-moon"></i>}
            checked={isDarkMode}
            onChange={() => dispatch(updateTheme())}
          />
          <div className="NavbarMenu-logout" onClick={logOutModalHandle}>
            <i className="fa-solid fa-arrow-right-from-bracket"></i>
          </div>
        </div>
      </div>
    )
  }
  else if (isDesktopOrLaptop) {
    return (
      <div id='NavbarMenu' style={{ background: isDarkMode ? colorBgSpotlight : colorPrimary}}>
        <div className="NavbarMenu-menu_icon" >
          <i class="fa-regular fa-solid fa-bars fa-lg" style={{color: "#ffffff"}} onClick={menuOpenCloseHandle}></i>
          <span>
            {menuTitle}
          </span>
        </div>
        <div className="NavbarMenu-items">
          <Switch
            className='mx-3'
            checkedChildren={<i class="fa-regular fa-sun"></i>}
            unCheckedChildren={<i class="fa-regular fa-moon"></i>}
            checked={isDarkMode}
            onChange={() => dispatch(updateTheme())}
          />
          <div className="NavbarMenu-username">
            <span>{user_info?.username}</span>
          </div>
          <div className="NavbarMenu-region">
            <span>{user_info?.tenant_name}</span>
          </div>
          <div className="NavbarMenu-logout" onClick={logOutModalHandle}>
            <i className="fa-solid fa-arrow-right-from-bracket"></i>
          </div>
        </div>
      </div>
    )
  }
}

function LogOutModal() {

  const dispatch = useDispatch();
  const {t} = useTranslation();

  const logOutHandle = () => {
    localStorage.clear();
    dispatch(logoutAction());
    cancel();
    window.location.href = '/login';
  }

  const cancel = () => {
    dispatch(setIsModalOpenAntd(false));
  }

  return (
    <div id='DeleteModal'>
      <div className='DeleteModal-content row'>
        <div className='DeleteModal-input_item text-center'>
          {t('modals.logout_modal.logout_text')}
        </div>
      </div>
      <div className='DeleteModal-footer gap-2'>
        <div className='DeleteModal-button_item'>
          <Button onClick={() => cancel()} type='primary' danger>{t('general.cancel')}</Button>
        </div>
        <div className='DeleteModal-button_item'>
          <Button onClick={() => logOutHandle()} type='primary'>{t('modals.logout_modal.logout_button')}</Button>
        </div>
      </div>
    </div>
  )
}

export default NavbarMenu

