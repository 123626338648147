import { Button, Col, Input, Row, Table, Tag, Typography } from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import React from 'react'
import { routes } from '../../../../../../core/route/router'
import { getLastAnomalyTime } from '../../../../../../core/apis/Apis'


function GatewayStatusTable({ dataSource, loading, secondsToHHmmss, setTotalOnlinePercentage, calculatePercentage, secondsToMMDDHHmmss }) {

    const [mainData, setMainData] = React.useState([])
    const [filteredInfo, setFilteredInfo] = React.useState({})
    const [sorteredInfo, setSorteredInfo] = React.useState({})


    async function getMainData (groupedData, lastDayGroupedData, lastWeekGroupedData, allTimeGroupedData, firstLogsGroupedData) {
        const newMainData = Object.values(groupedData)?.map(async (item) => {
            const key = item.key
            const lastDayData = lastDayGroupedData[key]
            const lastWeekData = lastWeekGroupedData[key]
            const allTimeData = allTimeGroupedData[key]
            const firstLogs = firstLogsGroupedData[key]

            const durationFromFirstLog = firstLogs ? moment().diff(moment(firstLogs.first_log_date), 'seconds') : 0

            try {
                const res = await getLastAnomalyTime(key);
                const lastAnomalyTimestamp = res?.data?.last_anomaly;

                const durationFromLastAnomalyTimestamp = lastAnomalyTimestamp ? moment().unix() - lastAnomalyTimestamp : null;

                return {
                    ...item,
                    last_day_online_percentage: lastDayData ? lastDayData.online_percentage : 0,
                    last_week_online_percentage: lastWeekData ? lastWeekData.online_percentage : 0,
                    all_time_online_percentage: allTimeData ? allTimeData.online_percentage : 0,
                    duration_from_first_log: firstLogs ? durationFromFirstLog : t('general.unknown'),
                    duration_from_last_anomaly: durationFromLastAnomalyTimestamp
                };
            } catch (err) {
                console.log("Error: ", err);
                return {
                    ...item,
                    last_day_online_percentage: lastDayData ? lastDayData.online_percentage : 0,
                    last_week_online_percentage: lastWeekData ? lastWeekData.online_percentage : 0,
                    all_time_online_percentage: allTimeData ? allTimeData.online_percentage : 0,
                    duration_from_first_log: firstLogs ? durationFromFirstLog : t('general.unknown')
                };
            }
        });

        const results = await Promise.all(newMainData);
        return results;
    };

    React.useEffect(() => {

        let totalOnlineDuration = 0
        let totalOfflineDuration = 0

        const groupedData = dataSource?.filtered_data?.reduce((acc, item) => {
            const key = item?.gateway
            if (!acc[key]) {
                acc[key] = {
                    key: key,
                    gateway: key,
                    current_status: item?.current_status,
                    fota_id: item?.fota_id,
                    total_online_duration: 0,
                    total_offline_duration: 0,
                    online_percentage: 0,
                    offline_percentage: 0,
                    expandedRowData: []
                }
            }

            acc[key].total_online_duration += item.online_duration
            acc[key].total_offline_duration += item.offline_duration

            acc[key].online_percentage = calculatePercentage(acc[key].total_online_duration, acc[key].total_offline_duration)
            acc[key].offline_percentage = (100 - acc[key].online_percentage).toFixed(2)

            const item_online_percentage = calculatePercentage(item.online_duration, item.offline_duration)
            const item_offline_percentage = (100 - item_online_percentage).toFixed(2)

            const new_item = {
                ...item,
                online_percentage: item_online_percentage,
                offline_percentage: item_offline_percentage
            }

            acc[key].expandedRowData.push(new_item)

            totalOnlineDuration += item.online_duration
            totalOfflineDuration += item.offline_duration

            return acc
        }, {})

        const otherDataHandler = (acc, item) => {
            const key = item.gateway
            
            if (!acc[key]) {
                acc[key] = {
                    key: key,
                    online_duration: 0,
                    offline_duration: 0,
                    online_percentage: 0
                }
            }

            acc[key].online_duration += item.online_duration
            acc[key].offline_duration += item.offline_duration

            const item_online_percentage = calculatePercentage(acc[key].online_duration, acc[key].offline_duration)

            acc[key].online_percentage = item_online_percentage

            return acc
        }

        const lastDayGroupedData = dataSource?.last_24_hours?.reduce((acc, item) => {
            acc = otherDataHandler(acc, item)
            return acc
        }, {})

        const lastWeekGroupedData = dataSource?.last_week?.reduce((acc, item) => {
            acc = otherDataHandler(acc, item)
            return acc
        }, {})

        const allTimeGroupedData = dataSource?.all_time?.reduce((acc, item) => {
            acc = otherDataHandler(acc, item)
            return acc
        }, {})

        const firstLogsGroupedData = dataSource?.first_logs?.reduce((acc, item) => {
            const key = item.gateway

            if (!acc[key]) {
                acc[key] = {
                    key: key,
                    first_log_date: item.created_at
                }
            }

            acc[key].first_log_date = acc[key].first_log_date < item.created_at ? acc[key].first_log_date : item.created_at

            return acc
        }, {})

        getMainData(groupedData, lastDayGroupedData, lastWeekGroupedData, allTimeGroupedData, firstLogsGroupedData).then((res) => {
            setMainData(res)
        })
        
        setTotalOnlinePercentage(calculatePercentage(totalOnlineDuration, totalOfflineDuration))
    }, [dataSource])


    const expandedRowRender = (data) => {

        const columns = [
            {
                title: t('tables.date'),
                dataIndex: 'created_at',
                key: 'created_at',
                render: (text) => moment(text).utc().format('DD-MM-YYYY')
            },
            {
                title: t('tables.version'),
                dataIndex: 'fota_id',
                key: 'fota_id',
                render: (text) => text ? text : t('general.unknown')
            },
            {
                title: t('pages.device_status_analytics.tables.online_duration'),
                dataIndex: 'online_duration',
                key: 'online_duration',
                render: (duration) => duration !== null ? secondsToHHmmss(duration) : t('general.unknown'),
                sorter: (a, b) => a.online_duration - b.online_duration
            },
            {
                title: t('pages.device_status_analytics.tables.offline_duration'),
                dataIndex: 'offline_duration',
                key: 'offline_duration',
                render: (duration) => duration !== null ? secondsToHHmmss(duration) : t('general.unknown'),
                sorter: (a, b) => a.offline_duration - b.offline_duration
            },
            {
                title: t('pages.device_status_analytics.tables.online_percentage'),
                dataIndex: 'online_percentage',
                key: 'online_percentage',
                render: (percentage) => percentage !== null ? `${percentage}%` : t('general.unknown'),
                sorter: (a, b) => a.online_percentage - b.online_percentage
            },
            {
                title: t('pages.device_status_analytics.tables.offline_percentage'),
                dataIndex: 'offline_percentage',
                key: 'offline_percentage',
                render: (percentage) => percentage !== null ? `${percentage}%` : t('general.unknown'),
                sorter: (a, b) => a.offline_percentage - b.offline_percentage
            }
        ]

        return <Table columns={columns} dataSource={data} pagination={false} />
    }

    const columns = [
        {
            title: <Row className='align-items-center justify-content-center gap-2'>
                    <Col>{t('tables.gateway_id')}</Col>
                    <Col>
                        <Input
                            placeholder={t('tables.search')}
                            value={filteredInfo.gateway}
                            onChange={(e) => {
                                setFilteredInfo({ ...filteredInfo, gateway: e.target.value ? [e.target.value] : [] })
                            }}
                        />
                    </Col>
                </Row>,
            dataIndex: 'gateway',
            key: 'gateway',
            filteredValue: filteredInfo.gateway || null,
            onFilter: (value, record) => record.gateway.includes(value.toUpperCase()),
            render: text => <Typography.Link
                    href={`${routes.gateway_detail.path}/${text}`}
                    target="_blank"
                    rel="noreferrer"
                    className='d-flex justify-content-center'
                >
                    {text}
                </Typography.Link>
        },
        {
            title: t('tables.version'),
            dataIndex: 'fota_id',
            key: 'fota_id',
            render: (text) => text ? text : t('general.unknown'),
            filters: [...new Set(mainData.map(item => item.fota_id))].map(item => ({ text: item ? item : t('general.unknown'), value: item })),
            filteredValue: filteredInfo.fota_id || null,
            onFilter: (value, record) => record.fota_id === value,
            filterSearch: true,
            width: 120
        },
        {
            title: t('pages.device_status_analytics.tables.current_status'),
            dataIndex: 'current_status',
            key: 'current_status',
            render: (_, record) => <span>{record?.current_status ? <Tag color="green">{t('general.online')}</Tag> : <Tag color="red">{t('general.offline')}</Tag>}</span>,
            filters: [{ text: <Tag color="green">{t('general.online')}</Tag>, value: true }, { text: <Tag color="red">{t('general.offline')}</Tag>, value: false }],
            filteredValue: filteredInfo.current_status || null,
            onFilter: (value, record) => record.current_status === value,
            width: 150
        },
        {
            title: t('pages.device_status_analytics.tables.online_percentage'),
            dataIndex: 'online_percentage',
            key: 'online_percentage',
            render: (percentage) => percentage !== null ? `${percentage}%` : t('general.unknown'),
            sorter: (a, b) => a.online_percentage - b.online_percentage,
            sortOrder: sorteredInfo.columnKey === 'online_percentage' && sorteredInfo.order
        },
        {
            title: t('pages.device_status_analytics.tables.last_day_online_percentage'),
            dataIndex: 'last_day_online_percentage',
            key: 'last_day_online_percentage',
            render: (percentage) => percentage !== null ? `${percentage}%` : t('general.unknown'),
            sorter: (a, b) => a.last_day_online_percentage - b.last_day_online_percentage,
            sortOrder: sorteredInfo.columnKey === 'last_day_online_percentage' && sorteredInfo.order
        },
        {
            title: t('pages.device_status_analytics.tables.last_7_days_online_percentage'),
            dataIndex: 'last_week_online_percentage',
            key: 'last_week_online_percentage',
            render: (percentage) => percentage !== null ? `${percentage}%` : t('general.unknown'),
            sorter: (a, b) => a.last_week_online_percentage - b.last_week_online_percentage,
            sortOrder: sorteredInfo.columnKey === 'last_week_online_percentage' && sorteredInfo.order
        },
        {
            title: t('pages.device_status_analytics.tables.all_time_online_percentage'),
            dataIndex: 'all_time_online_percentage',
            key: 'all_time_online_percentage',
            render: (percentage) => percentage !== null ? `${percentage}%` : t('general.unknown'),
            sorter: (a, b) => a.all_time_online_percentage - b.all_time_online_percentage,
            sortOrder: sorteredInfo.columnKey === 'all_time_online_percentage' && sorteredInfo.order
        },
        {
            title: t('pages.device_status_analytics.tables.logging_duration'),
            dataIndex: 'duration_from_first_log',
            key: 'duration_from_first_log',
            render: (duration) => duration !== null ? secondsToMMDDHHmmss(duration) : t('general.unknown'),
            sorter: (a, b) => a.duration_from_first_log - b.duration_from_first_log,
            sortOrder: sorteredInfo.columnKey === 'duration_from_first_log' && sorteredInfo.order
        },
        {
            title: t('pages.device_status_analytics.tables.duration_last_anomaly'),
            dataIndex: 'duration_from_last_anomaly',
            key: 'duration_from_last_anomaly',
            render: (duration) => duration !== null ? secondsToMMDDHHmmss(duration) : t('general.unknown'),
            sorter: (a, b) => a.duration_from_last_anomaly - b.duration_from_last_anomaly,
            sortOrder: sorteredInfo.columnKey === 'duration_from_last_anomaly' && sorteredInfo.order
        }
    ]

    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={mainData}
            pagination={false}

            expandable={{
                expandedRowRender: (record) => expandedRowRender(record?.expandedRowData),
                rowExpandable: record => record?.expandedRowData?.length > 0
            }}

            rowKey={(record) => record.gateway}
            

            scroll={{ y: "68vh" }}
            style={{ width: "100%" }}

            onChange={(pagination, filters, sorter) => {
                setFilteredInfo(filters)
                setSorteredInfo(sorter)
            }}

            footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>

                    <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                    <Button
                        onClick={() => {
                            setFilteredInfo({})
                            setSorteredInfo({})
                        }}
                    >
                        {t('tables.clear_filters')}
                    </Button>
                </div>
            }
        />
    )
}

export default GatewayStatusTable