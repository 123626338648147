import React, { useEffect, useState } from 'react'

import {
    Button,
    Descriptions,
    Form,
    Input,
    Row,
    Space,
} from 'antd';


import './PersonalPassword.scss'
import { api, updateProfile } from '../../../../../core/apis/Apis';
import { toast } from 'react-toastify';
import { SEO_HELMET } from '../../../../../core/utils/helmet';
import { setTitle } from '../../../../../core/stores/navbarTitleControllerSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';


function PasswordExpired() {

    const [user_info, setUser_info] = useState(null);


    const [form] = Form.useForm();
    const oldPass = Form.useWatch('oldPass', form);
    const pass1 = Form.useWatch('pass1', form);
    const pass2 = Form.useWatch('pass2', form);

    const dispatch = useDispatch();
    const {t} = useTranslation();


    const onFinishHandle = () => {
        if (pass1 !== pass2) {
            toast.error(t('pages.password_expired.new_password_identical'), {
                position: "top-right",
                // autoClose: false,
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else if (oldPass === pass1){
            toast.error(t('pages.password_expired.old_password_identical'), {
                position: "top-right",
                // autoClose: false,
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            })
        } else {
            var newData = {
                "username": user_info?.username,
                "password": pass1,
                "old_password": oldPass,
                "password_expired": true
            }
            updateProfile(newData)
                .then(res => {
                    if (res.status === 200) {
                        toast.success(t('pages.password_expired.password_updated_toast'), {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        setTimeout(() => {
                            window.location.href = '/gateway-list'
                        }, 1000);
                    } else {
                        toast.error(t('toast.error_text'), {
                            position: "top-right",
                            // autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        })
                    }
                })
                .catch(err => {
                    console.log("err ->", err.response.data)
                    toast.error(`${err.response.data.old_password || err.response.data.password}`, {
                        position: "top-right",
                        // autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                })
        }
        
    }

    useEffect(() => {
        dispatch(setTitle(t('pages.password_expired.title')))
        getUserName();
    }, [])

    const getUserName = async () => {
        await api.get('users/my-profile/')
            .then(res => {
                setUser_info(res.data)
            })
    }

    return (
        <div className='w-100 h-100 d-flex justify-content-center flex-column align-items-center'>
            <SEO_HELMET title={t('pages.password_expired.title')} />
            {/* <PageLoading /> */}
            <section className='mb-4'>
                <h1 className='text-center'>{t('pages.password_expired.title')}</h1>
                <h5 className='text-center text-muted'>{t('pages.password_expired.h5')}</h5>
            </section>
            <Form
                form={form}
                name="register"
                scrollToFirstError
                layout="vertical"
                onFinish={onFinishHandle}
            >
                <Descriptions>
                    <Descriptions.Item>
                        <Space direction="vertical" style={{ width: '100%' }}>

                            <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                <Form.Item
                                    style={{ textAlign: 'center', width: '100%', maxWidth: '400px' }}
                                    name="oldPass"
                                    label={t('pages.password_expired.password_label')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('pages.password_expired.password_required'),
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Row>

                            <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                <Form.Item
                                    style={{ textAlign: 'center', width: '100%', maxWidth: '400px' }}
                                    name="pass1"
                                    label={t('pages.password_expired.new_password_label')}
                                    rules={[
                                        {
                                            required: true,
                                            message: t('pages.password_expired.new_password_required'),
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Row>

                            <Row style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                                <Form.Item
                                    style={{ textAlign: 'center', width: '100%', maxWidth: '400px' }}
                                    name="pass2"
                                    label={t('pages.password_expired.new_password_again_label')}
                                    dependencies={['pass1']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: t('pages.password_expired.new_password_again_required'),
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('pass1') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error(t('pages.password_expired.new_password_identical')));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Row>
                            
                            <Row style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                <Button type='primary' htmlType='submit' >{t('general.save')}</Button>
                            </Row>
                        </Space>
                    </Descriptions.Item>
                </Descriptions>
            </Form>

        </div>
    )
}

export default PasswordExpired
