import { api } from "../../apis/Apis";
import { getDeviceStatusListFailed, getDeviceStatusListRequest, getDeviceStatusListSuccess } from "../../stores/logs/deviceStatusSlice";


export const getDeviceStatusList = (query_params) => async (dispatch) => {
    try {
        dispatch(getDeviceStatusListRequest());

        const { data } = await api.get("logs/daily-status-logs/", {
            params: query_params
        });

        dispatch(getDeviceStatusListSuccess(data));
    } catch (error) {
        dispatch(getDeviceStatusListFailed(
            error.response && error.response.data.detail
                ? error.response.data.detail || error.response.data.error
                : error.message
        ));
    };
};