import { createSlice } from '@reduxjs/toolkit'


export const globalInfoControllerSlice = createSlice({
    name: 'menuController',
    initialState: {
        globalInfoStatus: localStorage.getItem('globalInfo') && JSON.parse(localStorage.getItem('globalInfo')).status == true ? true : false,
        globalInfoBody: <>{localStorage.getItem('globalInfo') && JSON.parse(localStorage.getItem('globalInfo')).body}</>
    },

    reducers: {
        setGlobalInfoStatus: (state, action) => {
            state.globalInfoStatus = action.payload
        },
        setGlobalInfoBody: (state, action) => {
            state.globalInfoBody = action.payload
        },
    }
})


export const { setGlobalInfoBody, setGlobalInfoStatus } = globalInfoControllerSlice.actions

export default globalInfoControllerSlice.reducer


