import React, { lazy, useState } from 'react'
import { Col, Divider, Row, Card, Breadcrumb, theme } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import './Settings.scss'
import { SEO_HELMET } from '../../../core/utils/helmet'
import { useEffect } from 'react'
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice'
import { useTranslation } from 'react-i18next'


const PersonalProfile = lazy(() => import('./settings_more/personal_settings/PersonalProfile'))
const PersonalPassword = lazy(() => import('./settings_more/personal_settings/PersonalPassword'))
// const WebsiteTheme = lazy(() => import('./settings_more/website_settings/WebsiteTheme'))
// const WebsiteFonts = lazy(() => import('./settings_more/website_settings/WebsiteFonts'))
const UsersPermissions = lazy(() => import('./settings_more/users_settings/UsersPermissions'))
// const UsersEdit = lazy(() => import('./settings_more/users_settings/UsersEdit'))
const NewUserEdit = lazy(() => import('./settings_more/users_settings/NewUserEdit'))
// const CompanyPermissions = lazy(() => import('./settings_more/company_settings/CompanyPermissions'))
const TenantEdit = lazy(() => import('./settings_more/tenant_settings/TenantEdit'))
// const GatewayRegister = lazy(() => import('./settings_more/tenant_settings/GatewayRegister'))
const GatewayEdit = lazy(() => import('./settings_more/gateway_settings/GatewayEdit'))
const NodeList = lazy(() => import('../NodeList/NodeList'))
const LarrySocialMedia = lazy(() => import('./settings_more/larry_settings/LarrySocialMedia'))
const LarryApps = lazy(() => import('./settings_more/larry_settings/LarryApps'))
const LarryWeb = lazy(() => import('./settings_more/larry_settings/LarryWeb'))
const LarryLogo = lazy(() => import('./settings_more/larry_settings/LarryLogo'))


function Settings() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        token: {
            colorBgLayout,
            colorBgContainer,
            colorBgSpotlight,
            colorTextLabel,
            colorTextHeading
        }
    } = theme.useToken();


    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [clickedMenu, setClickedMenu] = useState({
        activeMenu: 'Personal-Profile',
        path: [
            {
                breadcrumbName: t('pages.settings.personal_section.title'),
            },
            {
                breadcrumbName: t('pages.settings.personal_section.profile'),
            }
        ]
    })



    /* -------------------------------------------------------------------------- */
    //TODO -                          FUCNTIONS                                   */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setTitle(t('pages.settings.title')))
        // SetLocalStorageApi()
    }, [])

    const onClickMenuItemHandle = (activeMenu, path) => {
        setClickedMenu({
            activeMenu,
            path
        })
    }

    const isMenuActiveControl = () => {
        var array = document.getElementsByClassName('cord-menu-item')
        if (array) {
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                element.classList.remove('active')
            }
            var activeMenu = document.getElementsByClassName(clickedMenu.activeMenu)[0]
            if (activeMenu)
                activeMenu.classList.add('active')
        }
    }

    const {
        authReducers: { user_info: userInfo }
    } = useSelector((state) => state)

    /* -------------------------------------------------------------------------- */
    //TODO -                        MENU PAGE                                     */
    /* -------------------------------------------------------------------------- */
    const menuPage = () => {
        isMenuActiveControl()

        switch (clickedMenu.activeMenu)
        {
            case 'Personal-Profile':
                return <PersonalProfile />
            case 'Personal-Password':
                return <PersonalPassword />
            // case 'Website-Theme':
            //     return <WebsiteTheme />
            // case 'Website-Fonts':
            //     return <WebsiteFonts />
            case 'Users-Permissions':
                return <UsersPermissions />
            case 'Users-User-Edit':
                return <NewUserEdit />
                // return <UsersEdit />
            // case 'Gateway-Register':
            //     return <GatewayRegister />
            case 'Node-List':
                return <NodeList />
            case 'Tenant-Edit':
                return <TenantEdit />
            case 'Gateway-Edit':
                return <GatewayEdit />
            case 'Larry-Settings-Social-Media':
                return <LarrySocialMedia />
            case 'Larry-Settings-Apps':
                return <LarryApps />
            case 'Larry-Settings-Web':
                return <LarryWeb />
            case 'Larry-Settings-Logo':
                return <LarryLogo />
            default:
                return <PersonalProfile />
        }
    }

    return (
        <div id='Settings'>
            <SEO_HELMET title={t('pages.settings.title')} />
            <div className="Settings-cover">
                <div className="container settings-container">
                    <Row className='settings-row'>
                        <Col
                            className='settings-col col-left'
                            xs={{ span: 24 }} sm={{ span: 6 }} lg={{ span: 6 }}
                        >
                            <div
                                className='title'
                                onClick={() => onClickMenuItemHandle(
                                    'Personal-Profile',
                                    [
                                        {
                                            breadcrumbName: t('pages.settings.personal_section.title'),
                                        },
                                        {
                                            breadcrumbName: t('pages.settings.personal_section.profile')
                                        }
                                    ]
                                )}
                                style={{ color: colorTextHeading }}
                            >
                                {t('pages.settings.general_settings')}
                            </div>
                            <Divider />
                            {userInfo?.permissions.settings_page_personal_profile_read &&
                                <Card
                                    title={t('pages.settings.personal_section.title')}
                                    className='card-settings'
                                    bordered={false}
                                    style={{
                                        backgroundColor: colorBgContainer,
                                        width: '100%',
                                    }}
                                >
                                    <div className='cord-menu-item Personal-Profile'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Personal-Profile',
                                                [
                                                    {
                                                        breadcrumbName: t('pages.settings.personal_section.title'),
                                                    },
                                                    {
                                                        breadcrumbName: t('pages.settings.personal_section.profile'),
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-user"></i>
                                            <span>{t('pages.settings.personal_section.profile')}</span>
                                        </div>
                                    </div>
                                    <div className='cord-menu-item Personal-Password'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Personal-Password',
                                                [
                                                    {
                                                        breadcrumbName: t('pages.settings.personal_section.title'),
                                                    },
                                                    {
                                                        breadcrumbName: t('pages.settings.personal_section.password'),
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-key"></i>
                                            <span>{t('pages.settings.personal_section.password')}</span>
                                        </div>
                                    </div>
                                </Card>
                            }

                            {userInfo?.is_superuser ? //userInfo?.is_root || userInfo?.is_staff ?
                            <>
                                <Card
                                    title={t('pages.settings.users_section.title')}
                                    className='card-settings'
                                    bordered={false}
                                    style={{
                                        backgroundColor: colorBgContainer,
                                        width: '100%',
                                    }}
                                >

                                    {userInfo?.is_superuser &&
                                    <div className='cord-menu-item Users-Permissions'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Users-Permissions',
                                                [
                                                    {
                                                        breadcrumbName: t('pages.settings.users_section.title'),
                                                    },
                                                    {
                                                        breadcrumbName: t('pages.settings.users_section.permissions'),
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-shield"></i>
                                            <span>{t('pages.settings.users_section.permissions')}</span>
                                        </div>
                                    </div>
                                    }
                                    <div className='cord-menu-item Users-User-Edit my-0 my-md-3 my-lg-0'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Users-User-Edit',
                                                [
                                                    {
                                                        breadcrumbName: t('pages.settings.users_section.title'),
                                                    },
                                                    {
                                                        breadcrumbName: t('pages.settings.users_section.user_edit'),
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-pen-to-square"></i>
                                            <span className='p-0 p-md-3 p-lg-0'>{t('pages.settings.users_section.user_edit')}</span>
                                        </div>
                                    </div>

                                </Card>
                                <Card
                                    title={t('pages.settings.tenants_section.title')}
                                    className='card-settings'
                                    bordered={false}
                                    style={{
                                        backgroundColor: colorBgContainer,
                                        width: '100%',
                                    }}
                                >
                                    <div className='cord-menu-item Tenant-Edit my-0 my-md-3 my-lg-0'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Tenant-Edit',
                                                [
                                                    {
                                                        breadcrumbName: t('pages.settings.tenants_section.title'),
                                                    },
                                                    {
                                                        breadcrumbName: t('pages.settings.tenants_section.tenant_edit'),
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-pen-to-square"></i>
                                            <span className='p-0 p-md-3 p-lg-0'>{t('pages.settings.tenants_section.tenant_edit')}</span>
                                        </div>
                                    </div>
                                </Card>

                                <Card
                                    title={t('pages.settings.gateways_section.title')}
                                    className='card-settings'
                                    bordered={false}
                                    style={{
                                        backgroundColor: colorBgContainer,
                                        width: '100%',
                                    }}
                                >
                                    <div className='cord-menu-item Gateway-Edit my-0 my-md-3 my-lg-0'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Gateway-Edit',
                                                [
                                                    {
                                                        breadcrumbName: t('pages.settings.gateways_section.title'),
                                                    },
                                                    {
                                                        breadcrumbName: t('pages.settings.gateways_section.gateway_edit'),
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-pen-to-square"></i>
                                            <span className='p-0 p-md-3 p-lg-0'>{t('pages.settings.gateways_section.gateway_edit')}</span>
                                        </div>
                                    </div>
                                </Card>
                                {/* <Card
                                    title="Larry Settings"
                                    className='card-settings'
                                    bordered={false}
                                    style={{
                                        backgroundColor: colorBgContainer,
                                        width: '100%',
                                    }}
                                >
                                    <div className='cord-menu-item Larry-Settings-Social-Media'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Larry-Settings-Social-Media',
                                                [
                                                    {
                                                        breadcrumbName: 'Larry Settings',
                                                    },
                                                    {
                                                        breadcrumbName: 'Social Media',
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-share-nodes"></i>
                                            <span>Social Media</span>
                                        </div>
                                    </div>
                                    <div className='cord-menu-item Larry-Settings-Apps'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Larry-Settings-Apps',
                                                [
                                                    {
                                                        breadcrumbName: 'Larry Settings',
                                                    },
                                                    {
                                                        breadcrumbName: 'Apps',
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-brands fa-app-store"></i>
                                            <span>Apps</span>
                                        </div>
                                    </div>
                                    <div className='cord-menu-item Larry-Settings-Web'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Larry-Settings-Web',
                                                [
                                                    {
                                                        breadcrumbName: 'Larry Settings',
                                                    },
                                                    {
                                                        breadcrumbName: 'Web Urls',
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-paperclip"></i>
                                            <span>Web Urls</span>
                                        </div>
                                    </div>
                                    <div className='cord-menu-item Larry-Settings-Logo'>
                                        <div
                                            onClick={() => onClickMenuItemHandle(
                                                'Larry-Settings-Logo',
                                                [
                                                    {
                                                        breadcrumbName: 'Larry Settings',
                                                    },
                                                    {
                                                        breadcrumbName: 'Logo',
                                                    }
                                                ]
                                            )}
                                        >
                                            <i className="fa-solid fa-images"></i>
                                            <span>Logo</span>
                                        </div>
                                    </div>

                                </Card> */}
                            </>
                            : ''
                            }
                            { !userInfo?.permissions.settings_page_personal_profile_read &&
                                <Card
                                    title={t('pages.settings.personal_section.title')}
                                    className='card-settings'
                                    bordered={false}
                                    style={{
                                        backgroundColor: colorBgContainer,
                                        width: '100%',
                                    }}
                                >
                                    <div className='cord-menu-item Personal-Profile'>
                                        <div className='text-muted'><strong>{t('pages.settings.no_permission')}</strong></div>
                                    </div>
                                </Card>
                            }
                        </Col>
                        
                        <Col
                            className='settings-col col-right'
                            xs={{ span: 24 }} sm={{ span: 18 }} lg={{ span: 18 }}
                        >
                            <div className='title'>
                                <Breadcrumb
                                    routes={clickedMenu.path}
                                    style={{ color: colorTextLabel }}
                                />
                            </div>
                            <Divider />
                            <div className='settings-col-right-content-cover' style={{background: colorBgContainer}}>
                                {menuPage()}
                                { !userInfo?.permissions.settings_page_personal_profile_read && 
                                    <div className='text-muted'><strong>{t('pages.settings.no_permission')}</strong></div>
                                }
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default Settings
