import { createSlice } from '@reduxjs/toolkit'


export const setupFilteredDataControllerSlice = createSlice({
  name: 'setupFilteredDataController',
  initialState: {
    filteredContainerData: [],
    filtereddeviceData: [],
  },

  reducers: {
    setFilteredContainerData: (state, action) => {
      state.filteredContainerData = action.payload
    },
    setFiltereddeviceData: (state, action) => {
      state.filtereddeviceData = action.payload
    },
  }
})


export const { setFilteredContainerData, setFiltereddeviceData } = setupFilteredDataControllerSlice.actions

export default setupFilteredDataControllerSlice.reducer