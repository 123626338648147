import { api } from "../../apis/Apis";
import { notificationListFail, notificationListRequest, notificationListSuccess } from "../../stores/logs/notificationSlice";



export const listNotification = (filters) => async (dispatch) => {
    try{
        dispatch(notificationListRequest());

        const { data } = await api.get('logs/notification-list/', {params: filters});

        dispatch(notificationListSuccess(data));
    }catch(error){
        dispatch(notificationListFail(
            error.response && error.response.data.detail
            ? error.response.data.detail || error.response.data.error
            : error.message,
        ));
    }
}
