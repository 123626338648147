import { createSlice } from "@reduxjs/toolkit";


const setCommonState = (state, action) => {

    state.next = action.payload.next;
    state.previous = action.payload.previous;
    state.count = action.payload.count;
    state.sensor_version = action.payload.sensor_version;
    state.sensor_type = action.payload.sensor_type;
    state.room_type = action.payload.room_type;
    state.gateway_gmt = action.payload.gateway_gmt;

    return state;
}


export const nodeLogSlice = createSlice({
    name: 'nodeLogReducers',
    initialState: {
        error: null,
        loading: false,
        success: false,
        nodeLog: {},
        nodeLogs: [],
        batteryLogs: [],
        next: null,
        previous: null,
        count: null,
        sensor_version: null,
        sensor_type: null,
        gateway_gmt: null
    },
    reducers:{
        // NodeLogs
        nodeLogListRequest: (state) => { state.loading = true },
        nodeLogListSuccess: (state, action) => {
            state.loading = false;
            state.nodeLogs = action.payload.results;
            state.success = true;
            setCommonState(state, action);
        },
        nodeLogListFail: (state, action) => { state.loading = false; state.error = action.payload; state.more_data = false;},

        // BatteryLogs
        batteryLogListRequest: (state) => { state.loading = true },
        batteryLogListSuccess: (state, action) => {
            state.loading = false;
            state.batteryLogs = action.payload.results;
            state.success = true;
            setCommonState(state, action);
        },
        batteryLogListFail: (state, action) => { state.loading = false; state.error = action.payload },

        // Reset Actions
        nodeLogListReset: (state) => { state.loading = false; state.success = false; state.error = null; state.more_data = false; },

        // Reset Slice
        nodeLogSliceReset: (state) => {
            state.loading = false; state.success = false; state.error = null; state.nodeLog = null; state.nodeLogs = null;
            state.batteryLogs = null;
        },
    }
});

export const {

    nodeLogListRequest,
    nodeLogListSuccess,
    nodeLogListFail,
    nodeLogListReset,

    batteryLogListRequest,
    batteryLogListSuccess,
    batteryLogListFail,

    nodeLogSliceReset,
    
} = nodeLogSlice.actions;
