import { createSlice } from '@reduxjs/toolkit';


export const hotlineSlice = createSlice({
    name: 'hotlineReducers',
    initialState: {
        error: null,
        loading: false,
        success: false,
        hotline: {},
        hotlines: [],
        total_hotlines: 0,
        active_hotlines: 0,
        inprogress_hotlines: 0,
        gateways: 0,
        online_gateways: 0,
    },
    reducers: {
            
        // Hotlines
        hotlineListRequest: (state) => { state.loading = true },
        hotlineListSuccess: (state, action) => {
            state.loading = false;
            state.hotlines = action.payload.hotlines;
            state.gateways = action.payload.gateways;
            state.online_gateways = action.payload.online_gateways;
            state.total_hotlines = action.payload.total_hotlines;
            state.active_hotlines = action.payload.active_hotlines;
            state.inprogress_hotlines = action.payload.inprogress_hotlines;
            state.success = true;
        },
        hotlineListFail: (state, action) => { state.loading = false; state.error = action.payload},

        // Hotline
        hotlineDetailRequest: (state) => { state.hotlineDetailLoading = true },
        hotlineDetailSuccess: (state, action) => { state.hotlineDetailLoading = false; state.hotline = action.payload; state.hotlineDetailSuccess = true; },
        hotlineDetailFail: (state, action) => { state.hotlineDetailLoading = false; state.hotlineDetailError = action.payload},

        // Reset Actions
        hotlineListReset: (state) => { state.loading = false; state.success = false; state.error = null },
        hotlineDetailReset: (state) => { state.hotlineDetailLoading = false; state.hotlineDetailSuccess = false; state.hotlineDetailError = null },
        
        // Reset Slice
        hotlineSliceReset: (state) => {
            state.loading = false; state.success = false; state.error = null; state.hotline = null; state.hotlines = null;
            state.hotlineDetailLoading = false; state.hotlineDetailSuccess = false; state.hotlineDetailError = null;
        },
    }
});

export const {
    
    hotlineListRequest,
    hotlineListSuccess,
    hotlineListFail,
    hotlineListReset,

    hotlineDetailRequest,
    hotlineDetailSuccess,
    hotlineDetailFail,
    hotlineDetailReset,

    hotlineSliceReset

} = hotlineSlice.actions;

export default hotlineSlice.reducer;
