import { createSlice } from "@reduxjs/toolkit";


export const notificationSlice = createSlice({
    name: 'notificationReducers',
    initialState: {
        error: null,
        loading: false,
        success: false,
        notification: {},
        notifications: [],
    },
    reducers:{
        // Notifications
        notificationListRequest: (state) => { state.loading = true },
        notificationListSuccess: (state, action) => { state.loading = false; state.notifications = action.payload; state.success = true; state.more_data = action.payload.more_data; },
        notificationListFail: (state, action) => { state.loading = false; state.error = action.payload; state.more_data = false;},

        // Reset Actions
        notificationListReset: (state) => { state.loading = false; state.success = false; state.error = null; state.more_data = false; },

        // Reset Slice
        notificationSliceReset: (state) => {
            state.loading = false; state.success = false; state.error = null; state.notification = null; state.notifications = null;
        },
    }
});

export const {

    notificationListRequest,
    notificationListSuccess,
    notificationListFail,
    notificationListReset,

    notificationSliceReset,
    
} = notificationSlice.actions;
