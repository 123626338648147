import { createSlice } from '@reduxjs/toolkit'


export const deviceBoxOperationControllerSlice = createSlice({
  name: 'deviceBoxOperationController',
  initialState: {
    addedDeviceParam: {},
    deviceCountInSingleBox: 0,
  },

  reducers: {
    setAddedDeviceParam: (state, action) => {
      state.addedDeviceParam = action.payload
    },
    setDeviceCountInSingleBox: (state, action) => {
      state.deviceCountInSingleBox = action.payload
    },
  }
})


export const { setAddedDeviceParam, setDeviceCountInSingleBox } = deviceBoxOperationControllerSlice.actions

export default deviceBoxOperationControllerSlice.reducer