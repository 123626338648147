import { api } from "../../apis/Apis";
import { oldNodeListFail, oldNodeListRequest, oldNodeListSuccess } from "../../stores/logs/oldNodeSlice";


export const listOldNodes = (device_serial) => async (dispatch) => {
    try{
        dispatch(oldNodeListRequest());

        const { data } = await api.get(`/logs/old-node-list/${device_serial}/`);

        dispatch(oldNodeListSuccess(data));
    }catch(error){
        dispatch(oldNodeListFail(
            error.response && error.response.data.detail
            ? error.response.data.detail || error.response.data.error
            : error.message,
        ));
    }
}