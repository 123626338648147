import { createSlice } from '@reduxjs/toolkit'


export const containersRealTimeStatusControllerSlice = createSlice({
    name: 'containersRealTimeStatusController',
    initialState: {
        redContainer: [],
        yellowContainer: [],
        greenContainer: [],
        fireAlarmContainer: [],
        openCoverAlarmContainer: [],
        deviceNoneAssaignContainer: [],
    },

    reducers: {
        setRedContainer: (state, action) => {
            state.redContainer = action.payload
        },
        setYellowContainer: (state, action) => {
            state.yellowContainer = action.payload
        },
        setGreenContainer: (state, action) => {
            state.greenContainer = action.payload
        },
        setFireAlarmContainer: (state, action) => {
            state.fireAlarmContainer = action.payload
        },
        setOpenCoverAlarmContainer: (state, action) => {
            state.openCoverAlarmContainer = action.payload
        },
        setDeviceNoneAssignContainer: (state, action) => {
            state.deviceNoneAssaignContainer = action.payload
        },

    }
})


export const { setRedContainer, setYellowContainer, setGreenContainer, setFireAlarmContainer, setOpenCoverAlarmContainer,setDeviceNoneAssignContainer } = containersRealTimeStatusControllerSlice.actions

export default containersRealTimeStatusControllerSlice.reducer


