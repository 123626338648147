import { createSlice } from '@reduxjs/toolkit'


export const nullCheckControllerSlice = createSlice({
    name: 'nullCheckController',
    initialState: {
        isNull: false,
    },

    reducers: {
        setIsNull: (state, action) => {
            state.isNull = action.payload
        },
    }
})


export const { setIsNull } = nullCheckControllerSlice.actions

export default nullCheckControllerSlice.reducer