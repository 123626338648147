import React from 'react'
import { AddFotaApi } from '../../../core/apis/Apis';
import { setIsModalOpenAntd } from '../../../core/stores/antdModalControllerSlice';
import { toast } from 'react-toastify';
import { Button, Input, Space, Form, Row, Col, InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

function AddFota() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const dispatch = useDispatch();
    const {t} = useTranslation();

    /* -------------------------------------------------------------------------- */
    //TODO -                             USE-FORM                                   */
    /* -------------------------------------------------------------------------- */
    const [form] = Form.useForm();

    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    const addNewHandle = async () => {
        let formData = await form.getFieldsValue()

        AddFotaApi(formData)
            .then(async (val) => {
                if (val.status === 201) {
                    toast.success(t('modals.add_fota_modal.success_toast'), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                    dispatch(setIsModalOpenAntd(false))
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {

                if (err.response.status === 400)
                    toast.error(t('modals.add_fota_modal.fota_already_exists_toast'), {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                else
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
            })
    }




    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={addNewHandle}
            >
                <Row gutter={[20, 0]} justify="center" align="center">
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }} >
                        <Form.Item
                            name='version'
                            label={t('modals.add_fota_modal.version_label')}
                            required tooltip={t('general.required_field')}
                            rules={
                                [
                                    { required: true, message: t('modals.add_fota_modal.version_required') },
                                    { max: 10, message: t('modals.add_fota_modal.version_length') }
                                ]
                            }>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }} >
                        <Form.Item
                            name='command'
                            label={t('modals.add_fota_modal.command_label')}
                            required tooltip={t('general.required_field')}
                            rules={
                                [
                                    { required: true, message: t('modals.add_fota_modal.command_required') },
                                ]
                            }>
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} >
                        <Form.Item
                            name='esp_ver'
                            label={t('modals.add_fota_modal.esp_ver_label')}
                            required
                            tooltip={t('modals.add_fota_modal.esp_ver_length')}
                            rules={
                                [
                                    { required: true, message: t('modals.add_fota_modal.esp_ver_required') },
                                ]
                            }>
                            <InputNumber
                                style={{ width: '100%' }}
                                controls={false}
                                type='number'
                                max={9999}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }} >
                        <Form.Item
                            name='st_ver'
                            label={t('modals.add_fota_modal.st_ver_label')}
                            required tooltip={t('modals.add_fota_modal.st_ver_length')}
                            rules={
                                [
                                    { required: true, message: t('modals.add_fota_modal.st_ver_required') },
                                ]
                            }>
                            <InputNumber
                                style={{ width: '100%' }}
                                controls={false}
                                type='number'
                                max={9999}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 24 }} >
                        <Form.Item
                            name='changelog'
                            label={t('modals.add_fota_modal.changelog_label')}
                            required tooltip={t('general.required_field')}
                            rules={
                                [
                                    { required: true, message: t('modals.add_fota_modal.changelog_required') },
                                ]
                            }>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className='footer_buttons_part mt-4' justify="center" align="center" >
                    <Space wrap>
                        <Button
                            icon={<i className="fa-solid fa-xmark pe-1"></i>}
                            onClick={() => dispatch(setIsModalOpenAntd(false))} >
                            {t('general.cancel')}
                        </Button>
                        <Button
                            htmlType="submit"
                            type="primary"
                            icon={<i className="fa-solid fa-floppy-disk pe-1"></i>}>
                            {t('general.add')}
                        </Button>
                    </Space>
                </Row>
            </Form>
        </div>
    )
}

export default AddFota
