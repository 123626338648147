import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice';
import { listSetupLogs } from '../../../core/actions/logs/setupLogActions';
import { useSelector } from 'react-redux';
import { SEO_HELMET } from '../../../core/utils/helmet';
import SetupLogTable from './SetupLogTable';
import { useTranslation } from 'react-i18next';

function SetupLogs() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        setupLogReducers: { setupLogs, loading },
    } = useSelector((state) => state);

    useEffect(() => {
        dispatch(setTitle(t('pages.setup_logs.title')))
        dispatch(listSetupLogs())
    }, [dispatch]);


    return (
        <>
            <SEO_HELMET title={t('pages.setup_logs.title')} />
            <SetupLogTable setupLogs={setupLogs} loading={loading} />
        </>
    )
}

export default SetupLogs
