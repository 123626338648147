/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Input, Select, Space, Table, Tag, Descriptions, Image, Popconfirm, Form, Row, Col, InputNumber, Collapse, DatePicker, theme, Typography, Popover, } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import Moment from 'moment';
import moment from 'moment-timezone';
// import 'moment/locale/de'
import { toast } from 'react-toastify';
import { SearchOutlined } from '@ant-design/icons';

import './SingleGtwDetail.scss'
import PageLoading from '../../../components/loadings/PageLoading';
import { useMediaQuery } from 'react-responsive';
import { addNodeApi, addRoomApi, deleteHome, deleteNodeApi, deleteRoomApi, getDeviceStatus, getGatewayGMT, getGatewayUsers, getLastLogTimes, getSingleAllSupportTicketData, getSingleGatewayData, nodeLogsApi, refreshLogs, resetST } from '../../../core/apis/Apis';
import Highlighter from 'react-highlight-words';
import { setError, setLoading, setSuccess } from '../../../core/stores/apiStatusControllerSlice';
import { routes } from '../../../core/route/router';
import { SEO_HELMET } from '../../../core/utils/helmet';
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice';
import { setIsModalOpenAntd, setModalCaptionAntd, setModalDataAntd, setRunFuncHandle, setStyleAntd } from '../../../core/stores/antdModalControllerSlice';
import EditRoomModal from './EditRoomModal';
import ImageDropdown from './ImageDropdown';
import SetupLogTable from '../setup_logs/SetupLogTable';
import { listSetupLogs } from '../../../core/actions/logs/setupLogActions';
import { addRemoveFavAction } from '../../../core/actions/auth/authActions';
import { CSVLink } from 'react-csv';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import FotaLogsTable from './buttons/Logs/FotaLogsTable';
import { listFotaLogs } from '../../../core/actions/logs/fotaLogActions';
import { useTranslation } from 'react-i18next';
import { listOldNodes } from '../../../core/actions/logs/oldNodeActions';
import OldNodesTable from './buttons/Logs/OldNodesTable';
import { useNavigate } from 'react-router-dom';


function SingleGtwDetail() {
    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const apiStatusController = useSelector(state => state.apiStatusController)
    const dispatch = useDispatch();
    const {
        authReducers: { user_info, isDarkMode },
        setupLogReducers: { setupLogDetailLoading, setupLogs },
        fotaLogReducers: { fotaLogs, loading: fotaLogsLoading },
        oldNodeReducers: { oldNodes, loading: oldNodesLoading },
    } = useSelector((state) => state);

    const { t } = useTranslation();

    const {
        token: {
            colorBgContainer,
            colorPrimary,
            colorError,
            colorTextBase,
            colorText,
            colorWarning
        },
    } = theme.useToken();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */
    // const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(max-width: 850px)' })
    // const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [record] = useState({
        gtwID: window.location.pathname.split('GTWY-')[1]
    })
    const [filteredInfo, setFilteredInfo] = useState({});
    const [supportTicketData, setSupportTicketData] = useState();
    const [supportTicketDataLoading, setSupportTicketDataLoading] = useState(true);
    // const [systemInitialized, setSystemInitialized] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [singleGtwDetailData, setSingleGtwDetailData] = useState(
        {
            gateway_info: null,
            last_activity: null,
            shadow: null,
            conn_status: false,
        }
    )
    const [singleGtwDetailDataIsLoadFinished, setsingleGtwDetailDataIsLoadFinished] = useState(
        {
            gateway_info: false,
            last_activity: false,
            shadow: false,
        }
    )

    const [lastLogTime, setLastLogTime] = useState(null);
    const [lastSetupTime, setLastSetupTime] = useState(null);
    // const [firstSetupTime, setFirstSetupTime] = useState(null);
    const [logLoading, setLogLoading] = useState(true);
    const [gatewayGMT, setGatewayGMT] = useState(null);
    const [deviceStatus, setDeviceStatus] = useState({
        sensors: null,
        gateway: null
    })

    const [isDeviceInFieldTest, setIsDeviceInFieldTest] = useState(false);

    const navigate = useNavigate();


    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    var getData = async () => {
        const param = {
            "device_serial": 'GTWY-' + window.location.pathname.split('GTWY-')[1],
        }

        const gtwID = "GTWY-" + window.location.pathname.split('GTWY-')[1]

        getSingleGatewayData(gtwID)
            .then(res => {
                var shadow = JSON.parse(res.data.shadow.replaceAll("'", '"').replaceAll('True', true).replaceAll('False', false))
                var gateway_info = JSON.parse(res.data.gateway_info.replaceAll("Decimal('", '').replaceAll("'),", ',').replaceAll("')}", '}').replaceAll("'", '"').replaceAll('True', true).replaceAll('False', false).replaceAll('None', null))

                setSingleGtwDetailData({
                    shadow: shadow,
                    last_activity: res.data.last_activity,
                    gateway_info: gateway_info,
                    conn_status: res.data.conn_status,
                })
                setsingleGtwDetailDataIsLoadFinished({
                    shadow: true,
                    last_activity: true,
                    gateway_info: true
                })

                if (res?.data?.tenant_db_id === 6) {
                    setIsDeviceInFieldTest(true);
                }
            })
        getSingleAllSupportTicketData(param)
            .then(res => {
                if (res.status === 200) {
                    setSupportTicketData(res.data)
                }
                setSupportTicketDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })

        getLastLogTimes(gtwID)
            .then(res => {
                if (res.status === 200) {
                    setLastLogTime(res.data.last_log)
                    setLastSetupTime(res.data.last_setup)
                    // setFirstSetupTime(res.data.first_setup)
                    setLogLoading(false)
                }
            })
            .catch(err => {
                console.log("last log err:",err)
                setLogLoading(false)
            })

        getGatewayGMT(gtwID)
            .then(res => {
                if (res.status === 200) {
                    setGatewayGMT(res.data);
                }
            }).catch(err => {
                console.log(err);
            })
        
        getDeviceStatus(
            'sensor',
            {
                device_serial: 'GTWY-' + window.location.pathname.split('GTWY-')[1]
            }
        ).then((res) => {
            setDeviceStatus({
                ...deviceStatus,
                sensors: res.data
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        dispatch(setTitle(t('pages.gateway_detail.title')))
        // SetLocalStorageApi()
        dispatch(listSetupLogs({ "device_serial": 'GTWY-' + window.location.pathname.split('GTWY-')[1] }))
        dispatch(listFotaLogs({ "device_serial": 'GTWY-' + window.location.pathname.split('GTWY-')[1] }))
        dispatch(listOldNodes('GTWY-' + window.location.pathname.split('GTWY-')[1]))
        getData()
    }, [dispatch, window.location.pathname])


    /* -------------------------------------------------------------------------- */
    //TODO                    Table For Room and Sensors                          */
    /* -------------------------------------------------------------------------- */
    /* -------------------------------------------------------------------------- */
    //TODO                    Table For Room and Sensors                          */
    /* -------------------------------------------------------------------------- */

    const getRoomByType = (type) => {
        const roomTypeMapping = {
            1: t('room_types.living_room'),
            2: t('room_types.bedroom'),
            3: t('room_types.bathroom'),
            4: t('room_types.kitchen'),
            5: t('room_types.entrance'),
            6: t('room_types.other'),
            7: t('room_types.lavatory'),
        }
        return roomTypeMapping[type] || t('general.unknown')
    }

    const GtwSummary = () => {
        var columnCount = 12
        if (isPortrait) {
            columnCount = 3
        } else if (isTabletOrMobile) {
            columnCount = 6
        }        

        const getLastRoom = () => {
            if (singleGtwDetailData?.last_activity?.lastRoomID && singleGtwDetailData?.shadow?.state?.desired?.rooms) {
                const room = singleGtwDetailData?.shadow?.state?.desired?.rooms.find((room) => room?.id === singleGtwDetailData?.last_activity?.lastRoomID)
                if (room) {
                    return `${room?.id} - ${room?.name} - ${getRoomByType(room?.type)}`
                }
            }
            return t('general.unknown')
        }

        return (
			<Descriptions
				title={
					<div className="d-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center justify-content-start">
							<div className="me-2">
								{user_info?.user_settings.gtw_fav_list.find(
									(f) => f === record.gtwID
								) !== undefined ? (
									<i
										onClick={() => gtwFavControl()}
										className="fa-solid fa-star"
										style={{
											color: "red",
											cursor: "pointer",
										}}
									></i>
								) : (
									<i
										onClick={() => gtwFavControl()}
										className="fa-regular fa-star"
										style={{
											color: "#aaaaaa",
											cursor: "pointer",
										}}
									></i>
								)}
							</div>
							<div className="d-flex flex-row">
								<div>GTWY-{record.gtwID}</div>
								<div className="text-muted ms-2">
									{singleGtwDetailData?.shadow?.state
										?.reported?.place_name
										? singleGtwDetailData?.shadow?.state?.reported?.place_name.toUpperCase()
										: t('pages.gateway_detail.no_gateway_tag')}
								</div>
							</div>
						</div>
						{singleGtwDetailData &&
							singleGtwDetailData.last_activity &&
							singleGtwDetailData.last_activity
								.SystemInitialized && (
								<div>
									<Tag
										bordered={"false"}
										color="#e6f4ff"
										style={{ color: "#329bd7" }}
									>
										{t(
											"pages.gateway_detail.system_initialized"
										)}
									</Tag>
								</div>
							)}
					</div>
				}
				bordered
				layout="vertical"
				column={columnCount}
				size="small"
			>
				<Descriptions.Item
					span={1}
					label={t("pages.gateway_detail.description_items.system")}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailData &&
					singleGtwDetailData.conn_status &&
					singleGtwDetailData.conn_status === true ? (
						<Tag bordered={"false"} color="success">
							{t("general.online")}
						</Tag>
					) : (
						<Tag bordered={"false"} color="red">
							{t("general.offline")}
						</Tag>
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={1}
					label={t("pages.gateway_detail.description_items.at_home")}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.last_activity === true ? (
						singleGtwDetailData.last_activity &&
						singleGtwDetailData.last_activity.isAtHome === true ? (
							<Tag color="success">{t("general.true")}</Tag>
						) : (
							<Tag color="red">{t("general.false")}</Tag>
						)
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={1}
					label={t("pages.gateway_detail.description_items.door")}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.last_activity === true ? (
						singleGtwDetailData.last_activity &&
						singleGtwDetailData.last_activity.isDoorClosed === 1 ? (
							<Tag color="success">{t("general.closed")}</Tag>
						) : (
							<Tag color="red">{t("general.open")}</Tag>
						)
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={2}
					label={t(
						"pages.gateway_detail.description_items.last_room_id"
					)}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished?.last_activity && singleGtwDetailData?.last_activity?.lastRoomID ? (
                        getLastRoom()
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={1}
					label={t(
						"pages.gateway_detail.description_items.last_sensor_id"
					)}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.last_activity === true ? (
						singleGtwDetailData.last_activity &&
						singleGtwDetailData.last_activity.lastSensorID
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={2}
					label={t("pages.gateway_detail.description_items.activity")}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.last_activity === true ? (
						singleGtwDetailData.last_activity && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}
							>
								<span>
									{activityPropsHandle(
										singleGtwDetailData.last_activity
											.activity
									)}
								</span>
								<span>
									{calculateCountryCode(
										singleGtwDetailData.last_activity
											.timestamp
									)}
								</span>
							</div>
						)
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={1}
					label={t(
						"pages.gateway_detail.description_items.last_activity"
					)}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.last_activity === true ? (
						singleGtwDetailData.last_activity &&
						calculateCountryCode(
							singleGtwDetailData.last_activity.lastActivityTs
						)
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={1}
					label={t("pages.gateway_detail.description_items.pet")}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.gateway_info === true ? (
						singleGtwDetailData.gateway_info &&
						singleGtwDetailData.gateway_info.hasPet === true ? (
							t("general.true")
						) : (
							t("general.false")
						)
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={1}
					label={t(
						"pages.gateway_detail.description_items.version_esp_st"
					)}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.shadow === true ? (
						singleGtwDetailData.shadow &&
						singleGtwDetailData.shadow.state &&
						singleGtwDetailData.shadow.state.reported &&
						singleGtwDetailData.shadow.state.reported.ESP_ver
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}{" "}
					{" - "}
					{singleGtwDetailDataIsLoadFinished.shadow === true ? (
						singleGtwDetailData.shadow &&
						singleGtwDetailData.shadow.state &&
						singleGtwDetailData.shadow.state.reported &&
						singleGtwDetailData.shadow.state.reported.ST_ver
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				{/* <Descriptions.Item label="ST  VER" labelStyle={{ fontWeight: 400, fontSize: '13px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center'  }} contentStyle={{ fontWeight: 500, color: '#081342', fontSize: '13px' }}>
            </Descriptions.Item> */}
				{/* <Descriptions.Item span={1} label="Last Act Recived" labelStyle={{ fontWeight: 400, fontSize: '13px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} contentStyle={{ fontWeight: 500, color: '#081342', fontSize: '13px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {singleGtwDetailDataIsLoadFinished.last_activity === true ? singleGtwDetailData.last_activity && calculateCountryCode(singleGtwDetailData.last_activity.actPayloadTs) : <BeatLoader size={10} color="#329ad6" />}
            </Descriptions.Item> */}
				<Descriptions.Item
					span={1}
					label={t('pages.gateway_detail.description_items.gtw_local_time')}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{singleGtwDetailDataIsLoadFinished.last_activity === true ? (
						singleGtwDetailData.last_activity &&
						localCalculateCountryCode()
					) : (
						<BeatLoader size={10} color="#329ad6" />
					)}
				</Descriptions.Item>
				{/* <Descriptions.Item span={4} label="First Setup Time" labelStyle={{ fontWeight: 400, fontSize: '13px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }} contentStyle={{ fontWeight: 500, color: '#081342', fontSize: '13px', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {firstSetupTime !== null ? moment(firstSetupTime).format("DD/MM/YY, HH:mm") : t('general.unknown')}
            </Descriptions.Item> */}
				<Descriptions.Item
					span={6}
					label={t("pages.gateway_detail.description_items.installation_date")}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{logLoading ? (
						<BeatLoader size={10} color="#329ad6" />
					) : lastSetupTime !== null ? (
						moment(lastSetupTime).utcOffset(gatewayGMT - 12).format("DD/MM/YY, HH:mm")
					) : (
						t('general.unknown')
					)}
				</Descriptions.Item>
				<Descriptions.Item
					span={6}
					label={t("pages.gateway_detail.description_items.last_log_date")}
					labelStyle={{
						fontWeight: 400,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
					contentStyle={{
						fontWeight: 500,
						// color: colorPrimary,
						fontSize: "13px",
						textAlign: "center",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{logLoading ? (
						<BeatLoader size={10} color="#329ad6" />
					) : lastLogTime !== null ? (
						moment.unix(lastLogTime).utcOffset(gatewayGMT - 12).format("DD/MM/YY, HH:mm")
					) : (
						t('general.unknown')
					)}
				</Descriptions.Item>
			</Descriptions>
		);
    }

    const activityPropsHandle = (activity) => {
        if (activity === 1) {
            return <>{t('activity_types.standing')}</>
        }
        else if (activity === 2) {
            return <>{t('activity_types.sleeping')}</>
        }
        else if (activity === 3) {
            return <>{t('activity_types.sitting')}</>
        }
        else if (activity === 4) {
            return <>{t('activity_types.taking_shower')}</>
        }
        else if (activity === 5) {
            return <>{t('activity_types.anomaly')}</>
        }
        else if (activity === 6) {
            return <>{t('activity_types.error')}</>
        }
    }


    const localCalculateCountryCode = () => {
        // var mometUnixDate = moment.unix(code);
        var mometUnixDate = moment();

        var abc = mometUnixDate.utcOffset(gatewayGMT - 12)
        return abc.format('HH:mm')

    }
    const calculateCountryCode = (code) => {
        // var mometUnixDate = moment.unix(code);
        var mometUnixDate = moment.unix(code);
        var abc = mometUnixDate.utcOffset(gatewayGMT - 12)
        return abc.format("DD/MM/YY, HH:mm")

    }

    const gtwFavControl = () => {
        if (user_info?.permissions?.gtw_detail_page_add_favorite) {
            dispatch(addRemoveFavAction('GTWY-' + window.location.pathname.split('GTWY-')[1]))
        } else {
            toast.error(t('toast.permission_error'), {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    /* -------------------------------------------------------------------------- */
    //TODO                          FOR TABLE PROPS                               */
    /* -------------------------------------------------------------------------- */

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={t('tables.search')}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {t('tables.search')}
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        {t('tables.clear')}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const tablePropOnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };
    /* -------------------------------------------------------------------------- */
    //TODO                          SUPPORT TICKET DATA                           */
    /* -------------------------------------------------------------------------- */

    const columns = [
		{
			title: t("tables.support_tickets_table.image"),
			dataIndex: "image",
			key: "image",
			width: 100,
			render: (image) =>
				image ? (
					<Image
						src={image}
						alt="image"
						style={{
							maxWidth: "80px",
							maxHeight: "80px",
							borderRadius: "7px",
						}}
					/>
				) : (
					"-"
				),
		},
		{
			title: t("tables.date"),
			dataIndex: "date",
			key: "date",
			width: 140
		},
		{
			title: t("tables.customer_id"),
			dataIndex: "customer_id",
			key: "customer_id",
			width: 160,
			filteredValue: filteredInfo.customer_id || null,
			sorter: (a, b) => a.customer_id.length - b.customer_id.length,
			...getColumnSearchProps("customer_id"),
		},
		{
			title: t("tables.support_tickets_table.ticket_id"),
			dataIndex: "spid",
			key: "spid",
			width: 160,
			filteredValue: filteredInfo.spid || null,
			sorter: (a, b) => a.spid - b.spid,
			filters: [
				...new Set(supportTicketData?.map((res, index) => res.id)),
			].map((res, index) => {
				return { text: res, value: res };
			}),
			onFilter: (value, record) => record.spid === value,
			filterSearch: true,
			render: (spid) => <div style={{ textAlign: "center" }}>{spid}</div>,
		},
		{
			title: t("tables.status"),
			dataIndex: "status",
			key: "status",
			width: 160,
			sortDirections: ["descend", "ascend"],
			filteredValue: filteredInfo.status || null,
			filters: [
				{
					text: (
						<Tag style={{ margin: "0" }} color={"orange"}>
							{t("tables.support_tickets_table.filters.new")}
						</Tag>
					),
					value: "new",
				},
				{
					text: (
						<Tag style={{ margin: "0" }} color={"green"}>
							{t(
								"tables.support_tickets_table.filters.work_in_proggress"
							)}
						</Tag>
					),
					value: "work_in_proggress",
				},
				{
					text: (
						<Tag style={{ margin: "0" }} color={"red"}>
							{t(
								"tables.support_tickets_table.filters.unresolved"
							)}
						</Tag>
					),
					value: '"unresolved"',
				},
				{
					text: (
						<Tag style={{ margin: "0" }} color={"blue"}>
							{t("tables.support_tickets_table.resolved")}
						</Tag>
					),
					value: "resolved",
				},
			],
			onFilter: (value, record) => record.status === value,
			sorter: (a, b) => a.status.length - b.status.length,
			render: (status) => (
				<div style={{ textAlign: "center" }}>
					{status === "new" ? (
						<Tag style={{ margin: "0" }} color={"orange"}>
							{t("tables.support_tickets_table.filters.new")}
						</Tag>
					) : status === "work_in_proggress" ? (
						<Tag style={{ margin: "0" }} color={"green"}>
							{t("tables.support_tickets_table.filters.work_in_proggress")}
						</Tag>
					) : status === "unresolved" ? (
						<Tag style={{ margin: "0" }} color={"red"}>
							{t("tables.support_tickets_table.filters.unresolved")}
						</Tag>
					) : (
						<Tag style={{ margin: "0" }} color={"blue"}>
							{t("tables.support_tickets_table.resolved")}
						</Tag>
					)}
				</div>
			),
		},
		{
			title: t("tables.support_tickets_table.support_level"),
			dataIndex: "sp",
			key: "sp",
			width: 160,
			filteredValue: filteredInfo.sp || null,
			sortDirections: ["descend", "ascend"],
			filters: [
				{
					text: 1,
					value: 1,
				},
				{
					text: 2,
					value: 2,
				},
				{
					text: 3,
					value: 3,
				},
			],
			onFilter: (value, record) => record.sp === value,
			sorter: (a, b) => a.sp - b.sp,
			render: (sp) => (
				<div style={{ textAlign: "center", minWidth: "140px" }}>
					{sp === 1 ? 1 : sp === 2 ? 2 : 3}
				</div>
			),
		},
		{
			title: t("tables.support_tickets_table.contact_info"),
			dataIndex: "contact_info",
			key: "contact_info",
			width: 160,
			filters: [
				...new Set(
					supportTicketData?.map(
						(res) => res?.contact_phone || res?.contact_mail
					)
				),
			].map((res) => {
				if (res) {
                    return { text: res, value: res };
                }
                return { text: t('general.unknown'), value: null }
			}),
			onFilter: (value, record) => record.contact_info === value,
			filteredValue: filteredInfo.contact_info || null,
			filterSearch: true,
            render: (text) => text || t('general.unknown'),
		},
		{
			title: t("tables.app_version"),
			dataIndex: "app_version",
			key: "app_version",
			width: 140,
			filteredValue: filteredInfo.app_version || null,
			filters: [
				...new Set(
					supportTicketData?.map((res, index) => res.app_version)
				),
			].map((res, index) => {
				return { text: res, value: res };
			}),
			onFilter: (value, record) => record.app_version === value,
		},
		{
			title: t("tables.detail"),
			dataIndex: "detail",
			key: "detail",
			filteredValue: filteredInfo.detail || null,
			...getColumnSearchProps("detail"),
		},
		{
			key: "update",
			fixed: "right",
			width: 60,
			dataIndex: "update",
			filteredValue: filteredInfo.update || null,
			render: (_, ticket) => {
				if (user_info?.permissions?.support_tickets_detail_page_read) {
					return (
						<div>
							<a
								href={`${process.env.REACT_APP_MEDIA_URL + routes.single_support_ticket.path}/GTWY-${record.gtwID}/ticket/${ticket.spid}`}
								target="_blank"
								rel="noreferrer"
								className="btn btn-primary"
								style={{ backgroundColor: "#329bd7" }}
							>
								<i className="fa-solid fa-pen-to-square"></i>
							</a>
						</div>
					);
				} else {
					return (
						<div>
							<a
								href={"##"}
								rel="noreferrer"
								className="btn btn-primary"
								style={{ backgroundColor: "#329bd7" }}
								onClick={() => {
									toast.error(
										t('toast.permission_error'),
										{
											position: "top-right",
											autoClose: false,
											hideProgressBar: false,
											closeOnClick: true,
											pauseOnHover: true,
											draggable: true,
											progress: undefined,
										}
									);
								}}
							>
								<i className="fa-solid fa-pen-to-square"></i>
							</a>
						</div>
					);
				}
			},
		},
	];


    const dataSource = useMemo(() => {
        var list = []
        if (supportTicketData) {
            [...supportTicketData].sort((a, b) => b.ts - a.ts).map((res, index) => 
                list.push({
                    key: index,
                    spid: res.id,
                    gtwID: res.device_serial_id && res.device_serial_id.split('GTWY-')[1],
                    image: res.image,
                    date: Moment.unix(res.ts).utcOffset(gatewayGMT - 12).format("DD/MM/YY, HH:mm"),
                    status: res.status,
                    sp: res.level,
                    customer_id: res.customer_id,
                    contact_info: res?.contact_phone || res?.contact_mail,
                    app_version: res.app_version,
                    detail: res.first_message,
                    update: <Button type="primary"><i className="fa-solid fa-pen-to-square"></i></Button>,
                })
            )
        }
        return list
    }, [supportTicketData, gatewayGMT])



    /* -------------------------------------------------------------------------- */
    //TODO                          ADD NEW NODE HANDLE                           */
    /* -------------------------------------------------------------------------- */


    const addNewNodeHandle = () => {
        dispatch(setModalDataAntd(<AddNodeModal gtwID={`GTWY-${record.gtwID}`} rooms={singleGtwDetailData.shadow.state.desired.rooms} />))
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('pages.gateway_detail.rooms_and_sensors_table.add_node')))
        dispatch(setRunFuncHandle(getData))
    }

    const deleteNodeHandle = (room, node) => {
        dispatch(setModalDataAntd(<DeleteNodeModal gtwID={`GTWY-${record.gtwID}`} roomID={room?.id} nodeID={node.id} />))
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('modals.delete_node_modal.title')))
        dispatch(setRunFuncHandle(getData))
    }


    const addNewRoomHandle = (roomID) => {
        dispatch(setModalDataAntd(<AddRoomModal gtwID={`GTWY-${record.gtwID}`} roomID={roomID} />))
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('pages.gateway_detail.rooms_and_sensors_table.add_room')))
        dispatch(setRunFuncHandle(getData))
    }

    const exportCSVLogsHandle = (nodes) => {
        dispatch(setModalDataAntd(<ExportCSVLogsModal gtwID={`GTWY-${record.gtwID}`} nodes={nodes} />))
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('modals.export_csv_modal.title')))
    }

    const deleteRoomHandle = (room) => {
        dispatch(setModalDataAntd(<DeleteRoomModal gtwID={`GTWY-${record.gtwID}`} room={room} />))
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('modals.delete_room_modal.title')))
        dispatch(setRunFuncHandle(getData))
    }

    const editRoomHandle = (room, room_image) => {
        dispatch(setModalDataAntd(<EditRoomModal gtwID={`GTWY-${record.gtwID}`} room={room} room_image_id={room_image} />))
        dispatch(setStyleAntd({ maxWidth: '800px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('modals.edit_room_modal.title')))
        dispatch(setRunFuncHandle(getData))
    }

    const listUsersHandle = () => {
        dispatch(setModalDataAntd(<UsersModal gtwID={`GTWY-${record.gtwID}`} />))
        dispatch(setStyleAntd({ maxWidth: '1000px' }))
        dispatch(setIsModalOpenAntd(true))
        dispatch(setModalCaptionAntd(t('modals.users_modal.title')))
    }

    const refreshLogsHandle = () => {
        toast.info(t('pages.gateway_detail.toasts.refresh_logs_progress_info'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        refreshLogs("GTWY-" + record.gtwID)
            .then(res => {
                console.log("response: ", res)
                if (res.status === 200) {
                    setTimeout(() => {
                        navigate(0)
                    }, 5000)
                } else {
                    toast.dismiss();
                    toast.error(`${t("pages.gateway_detail.toasts.refresh_logs_error")} Detail: ${res.data.detail}`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            }).catch(err => {
                toast.dismiss();
                toast.error(`${t("pages.gateway_detail.toasts.refresh_logs_error")} Detail: ${err}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            })
    };

    const deleteHouseHandle = () => {
        toast.info(t('pages.gateway_detail.toasts.delete_home_progress_info'), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        deleteHome("GTWY-" + record.gtwID)
            .then(res => {
                toast.dismiss();
                if (res.status === 200) {
                    toast.success(`${t('pages.gateway_detail.toasts.delete_home_success')} Detail: ${res.data.detail}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    getData();
                } else {
                    toast.error(`${t('pages.gateway_detail.toasts.delete_home_error')} Detail: ${res.data.detail}`, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
    }

    const maxID = singleGtwDetailData?.shadow?.state?.desired?.rooms?.length > 0 ? Math.max(...singleGtwDetailData?.shadow?.state?.desired?.rooms?.map(room => room?.id)) : 0;

    const getFormattedDuration = (status) => {
        return `${status?.hours} ${t('general.hr')}, ${status?.minutes} ${t('general.min')}, ${status?.seconds} ${t('general.sec')}`
    }

    const getSensorRowByRoom = (room) => {
        if (!room?.nodes || room?.nodes?.length === 0) {
            return (
                <tr key={`room-${room?.id}-no-nodes`} className='text-center last-td' style={{ height: '40px', fontWeight: 500 }}>
                    <td colSpan={8} style={{color: colorText}}>{t('pages.gateway_detail.rooms_and_sensors_table.no_node')}</td>
                </tr>
            );
        }

        return room?.nodes?.map((node, i) => {

            const sensor = singleGtwDetailData?.last_activity?.sensors ? singleGtwDetailData?.last_activity?.sensors.filter(sensor => sensor.id === node.id)[0] : null;

            const nodeTypeMap = {
                1: t('sensor_types.motion'),
                2: t('sensor_types.door'),
                3: t('sensor_types.force'),
                4: t('sensor_types.emergency_button'),
                5: t('sensor_types.humidity_motion'),
                6: t('sensor_types.sleep_monitor'),
                7: t('sensor_types.extender')
            }
            
            const status = deviceStatus?.sensors?.find((item) => item.device_id === parseInt(node.id))
            const content = !status ? 
                <div>{t('general.unknown')}</div>
            :
                <div>
                    {t('general.online')}: {getFormattedDuration(status?.online_duration)}<br/>
                    {t('general.offline')}: {getFormattedDuration(status?.offline_duration)}
                </div>;

            const statusColor = !status ? colorWarning : status?.online_duration?.hours > status?.offline_duration?.hours ? colorPrimary :
                status?.online_duration?.hours === status?.offline_duration?.hours ?
                    status?.online_duration?.minutes > status?.offline_duration?.minutes ? colorPrimary :
                        status?.online_duration?.minutes === status?.offline_duration?.minutes &&
                            status?.online_duration?.seconds > status?.offline_duration?.seconds ? colorPrimary :
                            colorError
                        : colorError

            return (
                <tr key={node.id} style={{ height: '40px' }} className={room?.nodes?.length === i + 1 ? `last-td` : ''}>
                    <td>
                        <Row justify={'start'} align={'middle'} className='gap-1 mb-1'>
                            <Button
                                href={`/${routes.gateway_detail.path}/GTWY-${record.gtwID}/${routes.single_node_logs.path}/${node.id}/battery`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: colorPrimary }}                                
                                className={`justify-content-center align-self-center ${isDarkMode ? '' : ' border-0'}`}
                                size='small'
                            >
                                <i className="fa-solid fa-battery-half fa-rotate-270"></i>
                            </Button>
                            
                            <Button
                                href={`/${routes.gateway_detail.path}/GTWY-${record.gtwID}/${routes.single_node_logs.path}/${node.id}/charts`}
                                target="_blank"
                                style={{ color: colorPrimary }}
                                className={`justify-content-center align-self-center ${isDarkMode ? '' : ' border-0'}`}
                                size='small'
                            >
                                <i className="fa-solid fa-chart-area"></i>
                            </Button>

                            { isDeviceInFieldTest &&
                                <Popover
                                    content={content}
                                    title={t('pages.node_list.pre_day_status')}
                                >
                                    <Button
                                        style={{ color: statusColor }}
                                        className={`justify-content-center align-self-center ${isDarkMode ? '' : ' border-0'}`}
                                        size='small'
                                    >
                                        <i class="fa-solid fa-heart-pulse"></i>
                                    </Button>
                                </Popover>
                            }

                            <Typography.Link
                                href={`/${routes.gateway_detail.path}/GTWY-${record.gtwID}/${routes.single_node_logs.path}/${node.id}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ color: colorPrimary, fontWeight: 'normal' }}
                            >
                                {node.id.toString()}
                            </Typography.Link>
                        </Row>
                        {/* <Row justify={'center'} align={'middle'}>
                        </Row> */}
                    </td>
                    <td style={{color: colorText}}>{nodeTypeMap[node.type] || t('general.unknown')}</td>
                    <td style={{color: colorText}}>{node?.ver}</td>
                    <td style={{color: colorText}}>{sensor?.bat || t('general.unknown')}</td>
                    <td style={{color: colorText}}>{sensor?.sigq || t('general.unknown')}</td>
                    <td style={{color: colorText}}>{sensor?.stat ? <Tag color="success">{t('general.active')}</Tag> : <Tag color="red">{t('general.passive')}</Tag>}</td>
                    <td style={{color: colorText}}>{sensor?.sensorTs ? (sensor.sensorTs === 0 ? '-' : Moment.unix(sensor.sensorTs).utcOffset(gatewayGMT - 12).format("DD/MM/YY, HH:mm")) : '-'}</td>
                    <td style={{color: colorText}}>
                        <Button onClick={() => deleteNodeHandle(room, node)} size='small'>
                            <i style={{ color: colorError }} className="fa-regular fa-trash-can"></i>
                        </Button>
                    </td>
                </tr>
            )
        })
    }

    return (
        // user_info?.permissions?.gtw_detail_page.read === false ?
        //     <NonePermissionPage />
        //     :
        <>
            <SEO_HELMET title={`${record.gtwID.substring(record.gtwID.length - 4)}`} />
            {(() => {
                if (isPortrait) {
                    return <div id='SingleGtwDetail' >
                        <div className='SingleGtwDetail-cover'>

                            <div className='SingleGtwDetail-buttons-and-info-section isPortrait'>
                                <div style={{ width: '100%' }}>{GtwSummary()}</div>
                                <div className='buttons'>
                                    {/* <div className='button'>
                                    <a
                                        href={`${process.env.REACT_APP_UNSPLASH_URL}act-GTWY-${record.gtwID}.html`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >GET ALL ACTS</a>
                                    </div> */}

                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}log-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/notifications`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.notifications')}
                                        </a>
                                    </div>

                                    { user_info?.permissions?.gtw_detail_page_display_all_logs_button &&
                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}log-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/all-logs`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.all_logs')}
                                        </a>
                                    </div>
                                    }
                                    { user_info?.permissions?.gtw_detail_page_display_anomaly_analyzer_button &&
                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}analyze-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/anomaly-analyzer`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.anomaly_analyzer')}
                                        </a>
                                    </div>
                                    }
                                    { user_info?.permissions?.gtw_detail_page_display_update_configs_button &&
                                
                                    <div className='button text-center'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}config-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/update-configs`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.update_configs')}
                                        </a>
                                    </div>
                                    }
                                    { user_info?.permissions?.gtw_detail_page_display_sessions_button &&
                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}session-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/sessions`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.sessions')}
                                        </a>
                                    </div>

                                    }
                                    {/* <div className='button'>
                                        <a
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/${routes.node_logs.path}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            NODE LOGS
                                        </a>
                                    </div> */}
                                    { user_info?.permissions?.gtw_detail_page_display_reset_st_button &&
                                    <div className='button reset-buttons'>
                                        <Popconfirm title={t('pages.gateway_detail.reset_st_ask')} onConfirm={() => {
                                            return resetST("GTWY-" + record.gtwID).then((res) => {
                                                if (res.status === 200) {
                                                    toast.success(t('pages.gateway_detail.toasts.reset_st_success'), {
                                                        position: "top-right",
                                                        autoClose: false,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    })
                                                } else {
                                                    toast.error(t('pages.gateway_detail.toasts.reset_st_error'), {
                                                        position: "top-right",
                                                        autoClose: false,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    })
                                                }
                                            })
                                        }} >
                                            {t('pages.gateway_detail.buttons.reset_st')}
                                        </Popconfirm>
                                    </div>
                                    }
                                </div>
                            </div>

                            <div className='SingleGtwDetail-sensors-info-section w-100 isPortrait'>
                                {singleGtwDetailData.shadow?.state?.desired?.rooms ?
                                    <>
                                        <Row className='w-100'>
                                            <div className='w-50'>
                                                <Divider orientation={singleGtwDetailData.shadow?.state?.desired?.rooms?.length > 2 ? "left" : "center"}>{t('pages.gateway_detail.rooms_and_sensors_table.title')}</Divider>
                                            </div>
                                            {user_info?.is_superuser &&
                                                <div className='w-50'>
                                                    <Divider orientation="right">
                                                        <Space>
                                                            <Button onClick={() => addNewNodeHandle()}>{t('pages.gateway_detail.rooms_and_sensors_table.add_node')}</Button>
                                                            <Button onClick={() => addNewRoomHandle(maxID)}>{t('pages.gateway_detail.rooms_and_sensors_table.add_room')}</Button>
                                                            <Button onClick={() => exportCSVLogsHandle(
                                                                singleGtwDetailData?.shadow?.state?.desired?.rooms.map(room => room?.nodes).flat().map(node => node.id)
                                                            )}>{t('pages.gateway_detail.rooms_and_sensors_table.export_csv_logs')}</Button>
                                                        </Space>
                                                    </Divider>
                                                </div>
                                            }
                                        </Row>
                                        <div className='SingleGtwDetail-sensors-info-right-side table-responsive w-100'>
                                            {singleGtwDetailData.shadow ?
                                                user_info?.is_superuser || singleGtwDetailData.shadow?.state?.desired?.rooms?.length > 2 ?
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }} >
                                                        <table className="table table-hover table-borderless table-left" >
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{color: colorTextBase}}>{t('pages.gateway_detail.rooms_and_sensors_table.room_id')}</th>
                                                                    <th scope="col" style={{color: colorTextBase}}>{t('pages.gateway_detail.rooms_and_sensors_table.room_name')}</th>
                                                                    <th scope="col" style={{color: colorTextBase}}>{t('pages.gateway_detail.rooms_and_sensors_table.room_type')}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    Object.values(singleGtwDetailData?.shadow?.state?.desired?.rooms).map((room, index) => {
                                                                        const roomType = getRoomByType(room?.type);
                                                                        const totalHeight = (room?.nodes?.length || 1) * 60;

                                                                        return <tr key={index} style={{ height: `${totalHeight}px`, borderBottom: '1px solid #b1b1b16b' }}>
                                                                            <td style={{color: colorText}}>{room?.id}</td>
                                                                            <td style={{color: colorText}}>{room?.name}</td>
                                                                            <td style={{color: colorText}}>{roomType}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <table className="table table-hover table-borderless node-table">

                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t("pages.gateway_detail.rooms_and_sensors_table.node_id")}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t("pages.gateway_detail.rooms_and_sensors_table.node_name")}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t("pages.gateway_detail.rooms_and_sensors_table.node_ver")}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t("pages.gateway_detail.rooms_and_sensors_table.battery").substring(0,3)}.</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t("pages.gateway_detail.rooms_and_sensors_table.signal").substring(0,3)}.</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t("pages.gateway_detail.rooms_and_sensors_table.status")}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t("pages.gateway_detail.rooms_and_sensors_table.timestamp")}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    Object.values(singleGtwDetailData?.shadow?.state?.desired?.rooms).map((room) => {
                                                                        return getSensorRowByRoom(room);
                                                                    })
                                                                    
                                                                }
                                                            </tbody>
                                                            </table>
                                                    </div>
                                                    : <div className='d-flex align-items-center justify-content-center w-100 '>{t('pages.gateway_detail.rooms_and_sensors_table.not_installed')}</div>
                                                : <PageLoading />
                                            }
                                        </div>
                                    </>
                                :
                                    <>
                                        <Divider orientation="center">{t('pages.gateway_detail.rooms_and_sensors_table.title')}</Divider>
                                        <div className='SingleGtwDetail-sensors-info-right-side table-responsive w-100'>
                                            <div className='d-flex align-items-center justify-content-center w-100 '>{t('pages.gateway_detail.rooms_and_sensors_table.not_installed')}</div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='SingleGtwDetail-support-tickets-data' style={{background: colorBgContainer}}>
                                <div className="SingleGtwDetail-support-tickets-data-cover">
                                    <Divider orientation="left">{t('pages.gateway_detail.support_tickets')}</Divider>
                                    <Table
                                        footer={() => <div>{t('tables.total_data')}: <b>{supportTicketData && supportTicketData.length}</b> </div>}
                                        size='middle'
                                        bordered={true}
                                        columns={columns}
                                        pagination={false}
                                        dataSource={dataSource}
                                        loading={supportTicketData ? false : true || apiStatusController.isLoading === true ? true : false}
                                    />
                                </div>
                            </div>
                        </div>

                        {setupLogs?.length > 0 &&

                            <Collapse className='border-0 shadow w-100 mt-4 text-center h5' style={{maxWidth: '1500px', background: colorBgContainer}} items={[{key: 1, label:t('pages.gateway_detail.gateway_setup_logs'),
                                
                                children: 
                                    <SetupLogTable setupLogs={setupLogs} loading={setupLogDetailLoading} gatewayGMT={gatewayGMT} />
                                }]}
                            />

                        }

                        {fotaLogs?.length > 0 &&

                            <Collapse className='border-0 shadow w-100 mt-4 text-center h5' style={{maxWidth: '1500px', background: colorBgContainer}} items={[{key: 1, label:t('pages.gateway_detail.gateway_fota_logs'),
                                
                                children: 
                                    <FotaLogsTable fotaLogs={fotaLogs} loading={fotaLogsLoading} gatewayGMT={gatewayGMT} />
                                }]}
                            />
                        }
                    </div>
                }
                else if (isTabletOrMobile) {
                    return <div id='SingleGtwDetail' >
                        <div className='SingleGtwDetail-cover isTabletOrMobile'>

                            <div className='SingleGtwDetail-buttons-and-info-section isTabletOrMobile'>
                                <div style={{ width: '100%' }}>{GtwSummary()}</div>
                                <div className='buttons'>
                                    {/* <div className='button'>
                                    <a
                                        href={`${process.env.REACT_APP_UNSPLASH_URL}act-GTWY-${record.gtwID}.html`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >GET ALL ACTS</a>
                                </div> */}

                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}log-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/notifications`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.notifications')}
                                        </a>
                                    </div>

                                { user_info?.permissions?.gtw_detail_page_display_all_logs_button &&
                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}log-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/all-logs`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.all_logs')}
                                        </a>
                                    </div>
                                }
                                { user_info?.permissions?.gtw_detail_page_display_anomaly_analyzer_button &&
                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}analyze-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/anomaly-analyzer`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.anomaly_analyzer')}
                                        </a>
                                    </div>
                                }
                                { user_info?.permissions?.gtw_detail_page_display_update_configs_button &&
                                
                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}config-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/update-configs`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.update_configs')}
                                        </a>
                                    </div>
                                }
                                { user_info?.permissions?.gtw_detail_page_display_sessions_button &&
                                    <div className='button'>
                                        <a
                                            // href={`${process.env.REACT_APP_UNSPLASH_URL}session-GTWY-${record.gtwID}.html`}
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/sessions`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {t('pages.gateway_detail.buttons.sessions')}
                                        </a>
                                    </div>

                                }
                                    {/* <div className='button'>
                                        <a
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/${routes.node_logs.path}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            NODE LOGS
                                        </a>
                                    </div> */}
                                { user_info?.permissions?.gtw_detail_page_display_reset_st_button &&
                                    <div className='button reset-buttons'>
                                        <Popconfirm title={t('pages.gateway_detail.reset_st_ask')} onConfirm={() => {
                                            return resetST("GTWY-" + record.gtwID).then((res) => {
                                                if (res.status === 200) {
                                                    toast.success(t('pages.gateway_detail.toasts.reset_st_success'), {
                                                        position: "top-right",
                                                        autoClose: false,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    })
                                                } else {
                                                    toast.error(t('pages.gateway_detail.toasts.reset_st_error'), {
                                                        position: "top-right",
                                                        autoClose: false,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    })
                                                }
                                            })
                                        }} >
                                            {t('pages.gateway_detail.buttons.reset_st')}
                                        </Popconfirm>
                                    </div>
                                }
                                </div>

                            </div>



                            <div className='SingleGtwDetail-sensors-info-section w-100 isTabletOrMobile'>
                                {singleGtwDetailData.shadow?.state?.desired?.rooms ?
                                    <>
                                        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                                            <div className='w-50'>
                                                <Divider className='w-50' orientation={singleGtwDetailData.shadow?.state?.desired?.rooms?.length > 2 ? "left" : "center"}>{t('pages.gateway_detail.rooms_and_sensors_table.title')}</Divider>
                                            </div>
                                            {user_info?.is_superuser &&
                                                <div className='w-50'>
                                                    <Divider className='w-50' orientation="right">
                                                        <Space>
                                                            <Button onClick={() => addNewNodeHandle()}>{t('pages.gateway_detail.rooms_and_sensors_table.add_node')}</Button>
                                                            <Button onClick={() => addNewRoomHandle(maxID)}>{t('pages.gateway_detail.rooms_and_sensors_table.add_room')}</Button>
                                                            <Button onClick={() => exportCSVLogsHandle(
                                                                singleGtwDetailData?.shadow?.state?.desired?.rooms.map(room => room?.nodes).flat().map(node => node.id)
                                                            )}>{t('pages.gateway_detail.rooms_and_sensors_table.export_csv_logs')}</Button>
                                                        </Space>
                                                    </Divider>
                                                </div>
                                            }
                                        </Row>
                                        <div className='SingleGtwDetail-sensors-info-right-side table-responsive w-100'>
                                            {singleGtwDetailData.shadow ?
                                                user_info?.is_superuser || singleGtwDetailData.shadow?.state?.desired?.rooms?.length > 2 ?
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }} >
                                                        <table className="table table-hover table-borderless table-left" >
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">ID</th>
                                                                    <th scope="col">{t('pages.gateway_detail.rooms_and_sensors_table.room_name')}</th>
                                                                    <th scope="col">{t('pages.gateway_detail.rooms_and_sensors_table.room_type')}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    Object.values(singleGtwDetailData.shadow.state.desired.rooms).map((room, index) => {
                                                                        const roomType = getRoomByType(room?.type);
                                                                        const totalHeight = (room?.nodes?.length || 1) * 60;
                                                                        
                                                                        return <tr key={index} style={{ height: `${totalHeight}px`, borderBottom: '1px solid #b1b1b16b' }}>
                                                                            <td style={{color: colorText}}>{room?.id}</td>
                                                                            <td style={{color: colorText}}>{room?.name}</td>
                                                                            <td style={{color: colorText}}>{roomType}</td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        <table className="table table-hover table-borderless node-table">

                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.node_id')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.node_name')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.node_ver')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.battery').substring(0,3)}.</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.signal').substring(0,3)}.</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.status')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.timestamp')}</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {
                                                                    Object.values(singleGtwDetailData?.shadow?.state?.desired?.rooms).map((room) => {
                                                                        return getSensorRowByRoom(room);
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <div className='d-flex align-items-center justify-content-center w-100 '>{t('pages.gateway_detail.rooms_and_sensors_table.not_installed')}</div>
                                                : <PageLoading />
                                            }
                                        </div>
                                    </>
                                :
                                    <>
                                        <Divider orientation="center">{t('pages.gateway_detail.rooms_and_sensors_table.title')}</Divider>
                                        <div className='SingleGtwDetail-sensors-info-right-side table-responsive w-100'>
                                            <div className='d-flex align-items-center justify-content-center w-100 '>{t('pages.gateway_detail.rooms_and_sensors_table.not_installed')}</div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className='SingleGtwDetail-support-tickets-data' style={{background: colorBgContainer}}>
                                <div className="SingleGtwDetail-support-tickets-data-cover">
                                    <Divider orientation="left">{t('pages.gateway_detail.support_tickets')}</Divider>
                                    <Table
                                        footer={() => <div>{t('tables.total_data')}: <b>{supportTicketData && supportTicketData.length}</b> </div>}
                                        size='middle'
                                        bordered={true}
                                        columns={columns}
                                        pagination={false}
                                        dataSource={dataSource}
                                        loading={supportTicketData ? false : true || apiStatusController.isLoading === true ? true : false}
                                    />
                                </div>
                            </div>

                        </div>

                        {setupLogs?.length > 0 &&

                            <Collapse className='border-0 shadow w-100 mt-4 text-center h5' style={{maxWidth: '1500px', background: colorBgContainer}} items={[{key: 1, label:t('pages.gateway_detail.gateway_setup_logs'),
                                
                                children: 
                                    <SetupLogTable setupLogs={setupLogs} loading={setupLogDetailLoading} gatewayGMT={gatewayGMT} />
                                }]}
                            />

                        }

                        {fotaLogs?.length > 0 &&

                            <Collapse className='border-0 shadow w-100 mt-4 text-center h5' style={{maxWidth: '1500px', background: colorBgContainer}} items={[{key: 1, label:t('pages.gateway_detail.gateway_fota_logs'),
                                
                                children: 
                                    <FotaLogsTable fotaLogs={fotaLogs} loading={fotaLogsLoading} gatewayGMT={gatewayGMT} />
                                }]}
                            />
                        }
                    </div>
                }
                else if (isDesktopOrLaptop) {
                    return < div id='SingleGtwDetail' className='pb-4' >
                        <div className='SingleGtwDetail-cover isDesktopOrLaptop'>

                            <div className='SingleGtwDetail-buttons-and-info-section isDesktopOrLaptop'>
                                <div style={{ width: '100%' }}>{GtwSummary()}</div>
                                <Row className='gap-3 my-3'>

                                    <Button onClick={() => listUsersHandle()} size='large' className='fw-bold shadow-sm'>
                                        {t('pages.gateway_detail.buttons.users')}
                                    </Button>

                                    <Button href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/notifications`} target='blank' size='large' className='fw-bold shadow-sm'>
                                        {t('pages.gateway_detail.buttons.notifications')}
                                    </Button>

                                { user_info?.permissions?.gtw_detail_page_display_all_logs_button &&
                                    <Button href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/all-logs`} target='blank' size='large' className='fw-bold shadow-sm'>
                                        {t('pages.gateway_detail.buttons.all_logs')}
                                    </Button>
                                }
                                { user_info?.permissions?.gtw_detail_page_display_anomaly_analyzer_button &&
                                    <Button href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/anomaly-analyzer`} target='blank' size='large' className='fw-bold shadow-sm'>
                                        {t('pages.gateway_detail.buttons.anomaly_analyzer')}                                        
                                    </Button>
                                }
                                { user_info?.permissions?.gtw_detail_page_display_update_configs_button &&                            
                                    <Button href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/update-configs`} target='blank' size='large' className='fw-bold shadow-sm'>                                        
                                        {t('pages.gateway_detail.buttons.update_configs')}                                        
                                    </Button>
                                }
                                { user_info?.permissions?.gtw_detail_page_display_sessions_button &&
                                    <Button href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/sessions`} target='blank' size='large' className='fw-bold shadow-sm'>                                        
                                        {t('pages.gateway_detail.buttons.sessions')}                                        
                                    </Button>
                                }
                                    {/* <div className='button'>
                                        <a
                                            href={`${process.env.REACT_APP_MEDIA_URL + routes.gateway_detail.path}/GTWY-${record.gtwID}/${routes.node_logs.path}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            NODE LOGS
                                        </a>
                                    </div> */}
                                { user_info?.permissions?.gtw_detail_page_display_reset_st_button &&
                                    <Button size='large' className='fw-bold shadow-sm'>
                                        <Popconfirm title={t('pages.gateway_detail.reset_st_ask')} onConfirm={() => {
                                            return resetST("GTWY-" + record.gtwID).then((res) => {
                                                if (res.status === 200) {
                                                    toast.success(t('pages.gateway_detail.toasts.reset_st_success'), {
                                                        position: "top-right",
                                                        autoClose: false,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    })
                                                } else {
                                                    toast.error(t('pages.gateway_detail.toasts.reset_st_error'), {
                                                        position: "top-right",
                                                        autoClose: false,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                    })
                                                }
                                            })
                                        }} >
                                            {t('pages.gateway_detail.buttons.reset_st')}
                                        </Popconfirm>
                                    </Button>
                                }

                                <Button onClick={() => refreshLogsHandle()} size='large' className='fw-bold shadow-sm'>
                                    {t('pages.gateway_detail.buttons.refresh_logs')}
                                </Button>
                                </Row>
                            </div>
                            
                            { user_info?.permissions?.gtw_detail_page_display_room_and_sensors_table &&

                                <div className='SingleGtwDetail-sensors-info-section w-100 shadow isDesktopOrLaptop' style={{backgroundColor: colorBgContainer}}>
                                    { singleGtwDetailData?.shadow?.state?.desired?.rooms ?
                                    <>
                                        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
                                            <div className='w-50'>
                                                <Divider className='w-50' orientation={singleGtwDetailData.shadow?.state?.desired?.rooms?.length > 2 ? "left" : "center"}>{t('pages.gateway_detail.rooms_and_sensors_table.title')}</Divider>
                                            </div>
                                            {user_info?.is_superuser &&
                                                <div className='w-50'>
                                                    <Divider className='w-50' orientation="right">
                                                        <Space>
                                                            <Button onClick={() => addNewNodeHandle()}>{t('pages.gateway_detail.rooms_and_sensors_table.add_node')}</Button>
                                                            <Button onClick={() => addNewRoomHandle(maxID)}>{t('pages.gateway_detail.rooms_and_sensors_table.add_room')}</Button>
                                                            <Button onClick={() => exportCSVLogsHandle(
                                                                singleGtwDetailData?.shadow?.state?.desired?.rooms.map(room => room?.nodes).flat().map(node => node.id)
                                                            )}>{t('pages.gateway_detail.rooms_and_sensors_table.export_csv_logs')}</Button>
                                                            <Button>
                                                                <Popconfirm title={t('pages.gateway_detail.delete_home_ask')} onConfirm={() => deleteHouseHandle()} >
                                                                    {t('pages.gateway_detail.rooms_and_sensors_table.delete_home')}
                                                                </Popconfirm>
                                                            </Button>
                                                        </Space>
                                                    </Divider>
                                                </div>
                                            }
                                        </Row>
                                        <div className='SingleGtwDetail-sensors-info-right-side table-responsive w-100'>
                                            {singleGtwDetailData?.shadow ?
                                                user_info?.is_superuser || singleGtwDetailData?.shadow?.state?.desired?.rooms?.length > 2 ?
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
                                                        <table className="table table-hover table-borderless table-left" style={{ marginRight: '20px' }}>
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{color: colorTextBase}}>
                                                                        <div className='text-center'>{t('pages.gateway_detail.rooms_and_sensors_table.room_edit')}</div>
                                                                    </th>
                                                                    <th scope="col" style={{color: colorTextBase}}>{t('pages.gateway_detail.rooms_and_sensors_table.room_id')}</th>
                                                                    <th scope="col" style={{color: colorTextBase}}>{t('pages.gateway_detail.rooms_and_sensors_table.room_name')}</th>
                                                                    <th scope="col" style={{color: colorTextBase}}>{t('pages.gateway_detail.rooms_and_sensors_table.room_type')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Object.values(singleGtwDetailData?.shadow?.state?.desired?.rooms).map((room, index) => {
                                                                        const roomType = getRoomByType(room?.type);
                                                                        const totalHeight = (room?.nodes?.length || 1) * 40;

                                                                        const imageID = singleGtwDetailData?.shadow?.state?.desired?.room_images ? singleGtwDetailData?.shadow?.state?.desired?.room_images.filter(image => image.room_id === room?.id)[0]?.image_id : null;

                                                                        return (
                                                                            <tr key={index} style={{ height: `${totalHeight}px`, borderBottom: '1px solid #b1b1b16b' }}>
                                                                                { user_info?.is_superuser &&
                                                                                <td>
                                                                                    <Row className='gap-2 justify-content-center'>
                                                                                        { user_info?.is_superuser | singleGtwDetailData?.shadow?.state?.desired?.rooms.length >= 4 &&
                                                                                            <Button onClick={() => deleteRoomHandle(room)} size='small'>
                                                                                                <i style={{ color: colorError }} className="fa-regular fa-trash-can"></i>
                                                                                            </Button>
                                                                                        }
                                                                                        <Button onClick={() => editRoomHandle(room, imageID)} size='small'>
                                                                                            <i style={{ color: colorPrimary }} className="fa-solid fa-edit"></i>
                                                                                        </Button>
                                                                                    </Row>
                                                                                </td>
                                                                                }
                                                                                <td style={{color: colorText}}>{room?.id}</td>
                                                                                <td style={{color: colorText}}>{room?.name}</td>
                                                                                <td style={{color: colorText}}>{roomType}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                        
                                                        <table className="table table-hover table-borderless node-table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.node_id')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.node_name')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.node_ver')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.battery').substring(0,3)}.</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.signal').substring(0,3)}.</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.status')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.timestamp')}</th>
                                                                    <th scope="col" style={{ color: colorTextBase }}>{t('pages.gateway_detail.rooms_and_sensors_table.node_edit')}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    Object.values(singleGtwDetailData?.shadow?.state?.desired?.rooms).map((room) => {
                                                                        return getSensorRowByRoom(room);
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    : <div className='d-flex align-items-center justify-content-center w-100 '>{t('pages.gateway_detail.rooms_and_sensors_table.not_installed')}</div>
                                                : <PageLoading />
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        <Divider className='w-50' orientation="center">{t('pages.gateway_detail.rooms_and_sensors_table.title')}</Divider>
                                        <div className='SingleGtwDetail-sensors-info-right-side table-responsive w-100'>
                                            <div className='d-flex align-items-center justify-content-center w-100 '>{t('pages.gateway_detail.rooms_and_sensors_table.not_installed')}</div>
                                        </div>
                                    </>
                                    }
                                </div>
                            }
                                
                            <Collapse className='border-0 shadow w-100 mt-4 text-center h5' style={{maxWidth: '1500px', background: colorBgContainer}} items={[{key: 1, label:t('pages.gateway_detail.gateway_old_nodes'),
                                    
                                    children: 
                                        <OldNodesTable oldNodes={oldNodes} loading={oldNodesLoading} />
                                    }]}
                            />

                            { user_info?.permissions?.gtw_detail_page_display_support_ticket_table &&
                                <div className='SingleGtwDetail-support-tickets-data' style={{background: colorBgContainer}}>
                                    <div className="SingleGtwDetail-support-tickets-data-cover">

                                        <Divider orientation="left">{t('pages.gateway_detail.support_tickets')}</Divider>
                                        <Table
                                            footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>
                                                <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                                                <Button onClick={() => setFilteredInfo({})}>{t('tables.clear_filters')}</Button>
                                            </div>
                                            }
                                            size='middle'
                                            bordered={true}
                                            columns={columns}
                                            pagination={false}
                                            dataSource={dataSource}
                                            loading={supportTicketDataLoading}
                                            onChange={tablePropOnChange}
                                        />
                                    </div>
                                </div>
                            }
                            
                            {setupLogs?.length > 0 &&

                                <Collapse className='border-0 shadow w-100 mt-4 text-center h5' style={{maxWidth: '1500px', background: colorBgContainer}} items={[{key: 1, label:t('pages.gateway_detail.gateway_setup_logs'),
                                    
                                    children: 
                                        <SetupLogTable setupLogs={setupLogs} loading={setupLogDetailLoading} gatewayGMT={gatewayGMT} />
                                    }]}
                                />

                            }

                            {fotaLogs?.length > 0 &&

                                <Collapse className='border-0 shadow w-100 mt-4 text-center h5' style={{maxWidth: '1500px', background: colorBgContainer}} items={[{key: 1, label:t('pages.gateway_detail.gateway_fota_logs'),
                                    
                                    children: 
                                        <FotaLogsTable fotaLogs={fotaLogs} loading={fotaLogsLoading} gatewayGMT={gatewayGMT} />
                                    }]}
                                />
                            }

                        </div>
                    </ div >
                }
            })()}
        </>
    )
}


const AddNodeModal = ({ gtwID, rooms }) => {

    /* -------------------------------------------------------------------------- */
    //TODO -                             USE-FORM                                   */
    /* -------------------------------------------------------------------------- */

    const [form] = Form.useForm();
    const room = Form.useWatch('room', form)
    const node_type = Form.useWatch('node_type', form)
    const node_id = Form.useWatch('node_id', form)
    // const ver = Form.useWatch("ver", form)
    // const cfg = Form.useWatch("cfg", form)
    // const tenant = Form.useWatch("tenant", form)

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const addNewNodeHandle = () => {

        const nodeData = {
            "id": node_id,
            "type": node_type,
        }

        addNodeApi(gtwID, room, nodeData)
            .then(res => {
                if (res.status === 201) {
                    cancel()
                    toast.success(res.data.detail, {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(res.data.detail, {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.detail, {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const cancel = () => {
        dispatch(setIsModalOpenAntd(false))
    }

    /* -------------------------------------------------------------------------- */
    //TODO -                    ALL ROOMS AND TYPES                               */
    /* -------------------------------------------------------------------------- */


    var getRoomsProps = () => {
        var roomList = []
        Object.values(rooms).map((res, index) => roomList.push({ "value": res.id, "label": res.name }))

        return roomList
    }

    var getNodeProps = () => {
        var nodeTypeList = []
        nodeTypeList.push(
            { "value": 1, "label": t('sensor_types.motion') },
            { "value": 2, "label": t('sensor_types.door') },
            { "value": 3, "label": t('sensor_types.force') },
            { "value": 4, "label": t('sensor_types.emergency_button') },
            { "value": 5, "label": t('sensor_types.humidity_motion') },
            { "value": 6, "label": t('sensor_types.sleep_monitor') },
            { "value": 7, "label": t('sensor_types.extender') },
        )
        return nodeTypeList
    }

    return <div>
        <Form
            form={form}
            layout="vertical"
            onFinish={addNewNodeHandle}
        >
            <Row gutter={[15, 0]} >
                <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }} >
                    <Form.Item
                        name='room'
                        label={t('modals.add_node_modal.room_label')}
                        required tooltip={t('general.required_field')}
                        style={{ margin: '0px' }}
                        rules={
                            [
                                { required: true, message: t('modals.add_node_modal.room_required') },
                                // { type: 'string', min: 3, message: 'Group adı minimum 3 karakter olmalıdır' }
                            ]
                        }>
                        <Select
                            options={getRoomsProps()}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }} >
                    <Form.Item
                        name='node_type'
                        label={t('modals.add_node_modal.node_type_label')}
                        required tooltip={t('general.required_field')}
                        style={{ margin: '0px' }}
                        rules={
                            [
                                { required: true, message: t('modals.add_node_modal.node_type_required') },
                                // { type: 'string', min: 3, message: 'Group adı minimum 3 karakter olmalıdır' }
                            ]
                        }>
                        <Select
                            onChange={(e) => {
                                // form.resetFields(['cfg']);
                                // runAgain(e)
                            }}
                            options={getNodeProps()}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 8 }} lg={{ span: 8 }} >
                    <Form.Item
                        name='node_id'
                        label={t('modals.add_node_modal.node_id_label')}
                        required tooltip={t('general.required_field')}
                        rules={
                            [
                                { required: true, message: t('modals.add_node_modal.node_id_required') },
                                // { type: 'string', min: 6, message: 'Konteyner adı minimum 6 karakter olmalıdır' }
                            ]
                        }>
                        <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                {/* <ReRun /> */}
            </Row>
            <Row className='footer_buttons_part' justify="center" align="center" >
                <Space wrap>
                    <Button
                        danger
                        type='primary'
                        icon={<i className="fa-solid fa-xmark pe-1"></i>}
                        onClick={() => cancel()} >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        htmlType="submit"
                        type='primary'
                        icon={<i className="fa-solid fa-floppy-disk pe-1"></i>}>
                        {t('general.add')}
                    </Button>
                </Space>
            </Row>
        </Form>
    </div>
}


const DeleteNodeModal = ({ gtwID, roomID, nodeID }) => {


    const dispatch = useDispatch();
    const { t } = useTranslation();

    const deleteNodeHandle = () => {

        deleteNodeApi(gtwID, roomID, nodeID)
            .then(res => {
                if (res.status === 204) {
                    cancel()
                    toast.success(t('modals.delete_node_modal.toast_success'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const cancel = () => {
        dispatch(setIsModalOpenAntd(false))
    }

    /* -------------------------------------------------------------------------- */
    //TODO -                    ALL ROOMS AND TYPES                               */
    /* -------------------------------------------------------------------------- */
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);


    return <div>
        <Row className='mb-2 w-100'>
            <div className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-center', flexDirection: 'column', textAlign: 'left', width: '100%' }}>
                <span><strong>{t('tables.gtw_id')}</strong> --------&gt; <strong>{gtwID}</strong></span>
                <span><strong>{t('pages.gateway_detail.rooms_and_sensors_table.room_id')}</strong> --------&gt; <strong>{roomID}</strong></span>
                <span><strong>{t('pages.gateway_detail.rooms_and_sensors_table.node_id')}</strong> --------&gt; <strong>{nodeID}</strong></span> <br />
                {t('modals.delete_node_modal.delete_node_text')}
                <span className='text-danger fw-bold mt-3 mb-3'>{gtwID}/{roomID}/{nodeID}</span>
                <Input onChange={(e) => {
                    if (e.target.value === `${gtwID}/${roomID}/${nodeID}`) {
                        setIsDeleteButtonDisabled(false)
                    }
                    else {
                        setIsDeleteButtonDisabled(true)
                    }
                }} />
            </div>
        </Row>
        <Row className='footer_buttons_part' justify="center" align="center"  >
            <Button
                className='me-2'
                type='primary'
                icon={<i className="fa-solid fa-xmark pe-1"></i>}
                onClick={() => cancel()} >
                {t('general.cancel')}
            </Button>
            <Button
                onClick={() => deleteNodeHandle()}
                type="primary"
                danger
                disabled={isDeleteButtonDisabled}
                icon={<i className="fa-solid fa-floppy-disk pe-1"></i>}>
                {t('general.delete')}
            </Button>
        </Row>
    </div>
}



const AddRoomModal = ({ gtwID, roomID }) => {

    /* -------------------------------------------------------------------------- */
    //TODO -                             USE-FORM                                   */
    /* -------------------------------------------------------------------------- */

    const [form] = Form.useForm();
    const room_type = Form.useWatch('room_type', form)
    const room_name = Form.useWatch('room_name', form)
    const room_image = Form.useWatch('room_image', form)

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const addNewRoomHandle = () => {
        var createNewRoomProps = {
            "room": {
                "id": roomID+1,
                "type": room_type,
                "name": room_name,
                "nodes": [],
            }
        }

        if (room_type === 6) {
            createNewRoomProps.room_image = {
                "room_id": roomID+1,
                "image_id": room_image,
            }
        }
        addRoomApi(gtwID, createNewRoomProps)
            .then(res => {
                if (res.status === 201) {
                    cancel()
                    toast.success(t('modals.add_room_modal.toast_success'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(t('toast.error_text'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const cancel = () => {
        dispatch(setIsModalOpenAntd(false))
    }

    /* -------------------------------------------------------------------------- */
    //TODO -                    ALL ROOMS AND TYPES                               */
    /* -------------------------------------------------------------------------- */


    var getRoomProps = () => {
        var roomTypeList = []
        roomTypeList.push(
            { "value": 1, "label": t('room_types.living_room') },
            { "value": 2, "label": t('room_types.bedroom') },
            { "value": 3, "label": t('room_types.bathroom') },
            { "value": 4, "label": t('room_types.kitchen') },
            { "value": 5, "label": t('room_types.entrance') },
            { "value": 6, "label": t('room_types.other') },
            { "value": 7, "label": t('room_types.lavatory') },
        )
        return roomTypeList
    }


    return <div>
        <Form
            form={form}
            layout="vertical"
            onFinish={addNewRoomHandle}
        >
            <Row gutter={[15, 0]} >
                <Col xs={{ span: 24 }} sm={{ span: 12 }} >
                    <Form.Item
                        name='room_type'
                        label={t('modals.add_room_modal.room_type_label')}
                        required tooltip={t('general.required_field')}
                        style={{ margin: '0px' }}
                        rules={
                            [
                                { required: true, message: t('modals.add_room_modal.room_type_required') },
                                // { type: 'string', min: 3, message: 'Group adı minimum 3 karakter olmalıdır' }
                            ]
                        }>
                        <Select
                            options={getRoomProps()}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} >
                    <Form.Item
                        name='room_name'
                        label={t('modals.add_room_modal.room_name_label')}
                        required tooltip={t('general.required_field')}
                        rules={
                            [
                                { required: true, message: t('modals.add_room_modal.room_name_required') },
                            ]
                        }>
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                {/* <ReRun /> */}

                { room_type === 6 &&
                <Col xs={{ span: 24 }}>
                    <Form.Item
                        name='room_image'
                        label={t('modals.add_room_modal.room_image_label')}
                        required tooltip={t('general.required_field')}
                        rules={
                            [
                                { required: true, message: t('modals.add_room_modal.room_image_required')},
                            ]
                        }
                    >
                        <ImageDropdown />
                    </Form.Item>
                </Col>
                }
            </Row>
            <Row className='footer_buttons_part' justify="center" align="center" >
                <Space wrap>
                    <Button
                        danger
                        type='primary'
                        icon={<i className="fa-solid fa-xmark pe-1"></i>}
                        onClick={() => cancel()} >
                        {t('general.cancel')}
                    </Button>
                    <Button
                        htmlType="submit"
                        type="primary"
                        icon={<i className="fa-solid fa-floppy-disk pe-1"></i>}>
                        {t('general.add')}
                    </Button>
                </Space>
            </Row>
        </Form>
    </div>
}


const DeleteRoomModal = ({ gtwID, room }) => {


    const dispatch = useDispatch();
    const { t } = useTranslation();

    const deleteRoomHandle = () => {

        deleteRoomApi(gtwID, room?.id)
            .then(res => {
                if (res.status === 204) {
                    cancel()
                    toast.success(t('modals.delete_room_modal.toast_success'), {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                else {
                    toast.error(res.data.detail, {
                        position: "top-right",
                        // autoClose: false,
                        autoClose: 1000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.detail || t('toast.error_text'), {
                    position: "top-right",
                    // autoClose: false,
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const cancel = () => {
        dispatch(setIsModalOpenAntd(false))
    }

    /* -------------------------------------------------------------------------- */
    //TODO -                    ALL ROOMS AND TYPES                               */
    /* -------------------------------------------------------------------------- */
    const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(true);


    return <div>
        <Row className='mb-2 w-100'>
            <div className='text-center' style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-center', flexDirection: 'column', textAlign: 'left', width: '100%' }}>
                <span><strong>{t('tables.gtw_id')}</strong> --------&gt; <strong>{gtwID}</strong></span>
                <span><strong>{t('pages.gateway_detail.rooms_and_sensors_table.room_id')}</strong> --------&gt; <strong>{room?.id}</strong></span>
                <span><strong>{t('pages.gateway_detail.rooms_and_sensors_table.room_name')}</strong> --------&gt; <strong>{room?.name}</strong></span><br />
                {t('modals.delete_room_modal.delete_room_text')}
                <span className='text-danger fw-bold mt-3 mb-3'>{gtwID}/{room?.id}</span>
                <Input onChange={(e) => {
                    if (e.target.value === `${gtwID}/${room?.id}`) {
                        setIsDeleteButtonDisabled(false)
                    }
                    else {
                        setIsDeleteButtonDisabled(true)
                    }
                }} />
            </div>
        </Row>
        <Row className='footer_buttons_part' justify="center" align="center"  >
            <Button
                className='me-2'
                type='primary'
                icon={<i className="fa-solid fa-xmark pe-1"></i>}
                onClick={() => cancel()} >
                {t('general.cancel')}
            </Button>
            <Button
                onClick={() => deleteRoomHandle()}
                type="primary"
                danger
                disabled={isDeleteButtonDisabled}
                icon={<i className="fa-solid fa-floppy-disk pe-1"></i>}>
                {t('general.delete')}
            </Button>
        </Row>
    </div>
}


const ExportCSVLogsModal = ({ gtwID, nodes }) => {

    const { t } = useTranslation();

    const {
        authReducers: { isDarkMode }
    } = useSelector(state => state)

    const {
        token: {
            colorPrimary,
            colorText
        }
    } = theme.useToken();

    const [logStatus, setLogStatus] = useState(false)
    const [dateString, setDateString] = useState("");
    const [loading, setLoading] = useState(false);

    const [logs, setLogs] = useState([]);

    const dateFormat = 'DD-MM-YYYY';

    var onChangeRangePicker = (date, dateString) => {
        setDateString(dateString)
    }

    const getAllNodeLogs = async () => {

        const data = {
            "device_serial": gtwID,
            "timestamp__gte": moment(dateString[0], dateFormat).unix(),
            "timestamp__lte": moment(dateString[1], dateFormat).set({hour: 23, minute: 59, second: 59}).unix(),
            "csv": true
        }

        try {
            setLoading(true)
            const responses = await Promise.all(nodes.map(node => nodeLogsApi({ ...data, sensor_id: node })))
            
            const csvLogs = responses.map(response => response.data)

            setLogs(csvLogs)

            setLoading(false)
            setLogStatus(true)
        } catch (error) {
            console.log(error)
            setLoading(false)
            setLogStatus(false)
        }

    }

    const downloadAllLogs = () => {
        
        const zip = new JSZip();

        logs.forEach((log, index) => {
            const node_id = Object.keys(log)[0];
            const node_log = log[node_id];

            
            if (node_log?.length > 0) {
                const headers = Object.keys(node_log[0]).map(key => key).filter(key => key !== 'log_id');
                const csvRows = node_log.map(row => headers.map(header => JSON.stringify(row[header])).join(','));
                const csvData = [headers.join(',')].concat(csvRows).join('\n');

                zip.file(`${node_id}.csv`, csvData)                
            }
        });

        zip.generateAsync({ type: "blob" }).then(blob => {
            saveAs(blob, `${gtwID}_${dateString[0]}_to_${dateString[1]}_logs.zip`);
        });
    };

    return (
        <>
            <Row className='justify-content-between border-bottom pb-3'>
                <DatePicker.RangePicker
                    format={dateFormat}
                    onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                />
                <Button className='ms-3 align-self-center' disabled={!dateString} onClick={() => {getAllNodeLogs()}}>{t('modals.export_csv_modal.get_data_button')}</Button>
            </Row>
            <Row className='flex-column gap-2 mt-3'>
                {
                    loading ? <PageLoading /> :
                    logStatus ?

                    <>
                        <Col className='h4 fw-semibold text-center'>
                            {t('modals.export_csv_modal.title')}
                        </Col>
                        
                        {logs.map((log, index) => {
                            const node_id = Object.keys(log)[0];
                            const node_log = log[node_id];

                            return (
                                <Col key={node_id} className='d-flex justify-content-between border-bottom pb-2 align-items-center'>
                                    <span>{t('modals.export_csv_modal.sensor_id_label')}: &nbsp; <Tag color={colorPrimary}>{node_id}</Tag></span>

                                    { node_log.length === 0 ? <button className='btn btn-danger' disabled>{t('modals.export_csv_modal.no_log')}</button> : 
                                        <CSVLink
                                            filename={`${dateString[0]}_to_${dateString[1]}_${node_id}_${node_log?.length}_logs.csv`}
                                            data={node_log}
                                            headers={Object.keys(node_log[0]).map(key => ({ label: key, key: key }))}
                                        >
                                            <Button className='btn d-flex text-center align-items-center' style={{color: isDarkMode && colorText}}>
                                                {t('modals.export_csv_modal.export_csv_button')}
                                            </Button>
                                        </CSVLink>
                                    }
                                </Col>
                            )
                        })}
                        
                        <Col className='text-center my-3'>
                            {logs.every(log => log[Object.keys(log)[0]].length === 0) ?
                                <button className='btn btn-danger' disabled>{t('modals.export_csv_modal.export_all_csv')}</button>
                                :
                                <Button
                                    type='primary'
                                    onClick={() => downloadAllLogs()}>{t('modals.export_csv_modal.export_all_csv')}
                                </Button>
                            }
                        </Col>
                    </>
                    : ''
                }
            </Row>
        </>
    )
}


const UsersModal = ({ gtwID }) => {
    
    const { t } = useTranslation();

    const [users, setUsers] = useState([]);

    useEffect(() => {
        getGatewayUsers(gtwID)
            .then(res => {
                setUsers(res.data);
            })
            .catch(err => {
                console.log("users error:", err);
            })
    }, [gtwID])

    const columns = [
        {
            title: t('modals.users_modal.customer_id'),
            dataIndex: 'customer_id',
            key: 'customer_id',
            width: 250
        },
        {
            title: t('modals.users_modal.name'),
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: t('modals.users_modal.user_type'),
            dataIndex: 'user_type',
            key: 'user_type',
            render: (text) => text ? t(`modals.users_modal.${text}`) : t('general.unknown')
        },
        {
            title: t('modals.users_modal.is_admin'),
            dataIndex: 'is_admin',
            key: 'is_admin',
            render: (text) => text ? t(`general.yes`) : t('general.no')
        },
        {
            title: t('modals.users_modal.last_active'),
            dataIndex: 'last_active',
            key: 'last_active',
            render: (text) => moment.unix(text).format('DD/MM/YYYY HH:mm:ss')
        },
        {
            title: t('modals.users_modal.joined_at'),
            dataIndex: 'joined_at',
            key: 'joined_at',
            render: (text) => moment.unix(text).format('DD/MM/YYYY HH:mm:ss')
        }
    ]

    return (
        <>
            <Table
                dataSource={users}
                columns={columns}

                loading={users.length === 0}
                pagination={false}

                scroll={{
                    y: "calc(100vh - 190px)",
                }}
            />
        </>
    )
}








export default SingleGtwDetail
