import React from 'react'
import './NonePermissionPage.scss'
import { createBrowserHistory } from 'history'

// Parallax Code
const history = createBrowserHistory();

function NonePermissionPage() {

    return (
        <div id='NonePermissionPage'>
            <section className="wrapper">

                <div className="container">

                    <div id="scene" className="scene" data-hover-only="false">


                        <div className="circle" data-depth="1.2"></div>

                        <div className="one" data-depth="0.9">
                            <div className="content">
                                <span className="piece"></span>
                                <span className="piece"></span>
                                <span className="piece"></span>
                            </div>
                        </div>

                        <div className="two" data-depth="0.60">
                            <div className="content">
                                <span className="piece"></span>
                                <span className="piece"></span>
                                <span className="piece"></span>
                            </div>
                        </div>

                        <div className="three" data-depth="0.40">
                            <div className="content">
                                <span className="piece"></span>
                                <span className="piece"></span>
                                <span className="piece"></span>
                            </div>
                        </div>

                        <p className="p404" data-depth="0.50">403</p>
                        <p className="p404" data-depth="0.10">403</p>

                    </div>

                    <div className="text">
                        <article>
                            <p> Gitmek istediğiniz sayfaya erişim izniniz yok <br />Lütfen yetkili kişi ile iletişime geçip gerekli izinleri temin ediniz!</p>
                            <button onClick={() => {
                                // history.push("/gateway-list")
                                history.go()
                            }}>Ana Sayfa</button>
                        </article>
                    </div>

                </div>
            </section>
        </div>
    )
}


export default NonePermissionPage