import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import './GlobalInfo.scss'

function GlobalInfo() {

    /* -------------------------------------------------------------------------- */
    //TODO -                            STATES                                    */
    /* -------------------------------------------------------------------------- */
    const globalInfo = useSelector(state => state.globalInfoController)
    const dispatch = useDispatch();


    /* -------------------------------------------------------------------------- */
    //TODO -                            Functions                                 */
    /* -------------------------------------------------------------------------- */
    const globalInfoCloseHandle = () => {
    }


    return (
        localStorage.getItem('globalInfo')&& JSON.parse(localStorage.getItem('globalInfo')).status === true ?
            <div id='GlobalInfo'>
                <div className='GlobalInfo-cover'>
                    <div className='GlobalInfo-content ps-4 pe-4' >
                        {JSON.parse(localStorage.getItem('globalInfo')).body}
                    </div>
                </div>
            </div> : <></>
    )
}

export default GlobalInfo