import Moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Col, Row, Spin, theme } from 'antd';

import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import hammer from "hammerjs";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';


ChartJS.register(
	CategoryScale,
	TimeScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	zoomPlugin
);


function NodeLogChart({dataSource, sensor_type, reset_zoom, zoom, autoScale, gateway_gmt}) {

    const {
        nodeLogReducers: { loading },
        authReducers: { isDarkMode }
    } = useSelector((state) => state);

    const {
        token: {
            colorTextLabel,
            colorTextHeading,
            colorPrimary,
            colorBgSpotlight,
            colorError,
            colorBorder,
            colorText,
            colorTextSecondary,
            colorTextTertiary,
        }
    } = theme.useToken();

    const {t} = useTranslation();
    const isMobile = useMediaQuery({query: '(max-width: 768px)'});
    const isTablet = useMediaQuery({query: '(max-width: 1200px)'});
    // const isMobileOrTablet = isMobile || isTablet;

    const prevZoomRef = React.useRef(zoom);

    const chartRef = React.useRef([]);  
    const [initialScales, setInitialScales] = React.useState({});

    const updatedDataSource = React.useMemo(() => {
        if (!dataSource || dataSource.length === 0) return [];

        const updatedDataSource = dataSource.slice().reverse();

        return updatedDataSource;
    }, [dataSource]);

    const setInitalOptions = (dataLength) => {
        const options = {
			responsive: true,
			maintainAspectRatio: false,
			elements: {
				point: {
					radius: 0,
					pointRadius: 2,
					pointHoverRadius: 5,
				},
				line: {
					borderWidth: 1,
					borderCapStyle: "round",
					borderJoinStyle: "round",
					capBezierPoints: true,
				},
			},
			plugins: {
				legend: {
					position: "top",
                    labels: {
                        color: colorTextLabel
                    }
				},
				title: {
					display: true,
					text: `TOTAL DATA => ${dataLength}`,
					position: "bottom",
					align: "end",
					padding: {
						top: 30,
					},
                    color: colorTextHeading
				},
				tooltip: {
					mode: "index",
					intersect: false,
					position: "bottom",
				},
				zoom: {
					pan: {
						enabled: true,
						onPan: ({ chart }) => {
							const min = chart.scales.x.min;
							const max = chart.scales.x.max;

							// Sync Charts Pan
							chartRef.current.forEach((chart) => {
								chart.zoomScale("x", { min, max }, "none");
							});
						},
					},
					limits: {
						x: { min: "original", max: "original" },
						y: { min: "original", max: "original" },
					},
					zoom: {
						drag: {
							enabled: true,
							borderColor: "rgba(0,0,0)",
							borderWidth: 1,
							backgroundColor: "rgb(225,225,225, 0.3)",
							modifierKey: "shift",
						},
                        pinch: {
                            enabled: true,
                        },
						mode: "xy",
					},
				},
			},
			hover: {
				mode: "index",
				intersect: false,
			},
			scales: {
				x: {
					type: "time",
					ticks: {
						stepSize: 1,
                        color: colorTextLabel
					},
					time: {
						parser: "DD/MM/YYYY HH:mm:ss",
						unit: "hour",
						displayFormats: {
							hour: "DD/MM - HH:mm",
						},
						tooltipFormat: "DD/MM/YYYY HH:mm:ss",
					},
					grid: {
                        tickColor: colorBorder,
                        color: colorBorder,
						lineWidth: (context) => {
							const hour = Moment(context?.tick?.value).hour();
							if (hour === 0) {
								return 5;
							}
							return 1;
						},
					},
				},
                y: {
                    ticks: {
                        color: colorTextLabel
                    },
                    grid: {
                        color: colorBorder,
                        tickColor: colorBorder
                    }
                }
			},
			onHover: (event, chartElement) => {
				chartRef.current.filter((chart) => chart !== event.chart).forEach((chart) => {
                    chart.tooltip.setActiveElements(chartElement.map((element) => ({datasetIndex: element.datasetIndex, index: element.index})));
                    chart.setActiveElements(chartElement.map((element) => ({datasetIndex: element.datasetIndex, index: element.index})));
                    chart.update('none');
                });
			},
		};

        return options;
    };

    const createChartDataSets = (updatedDataSource, sensor_type) => {

        switch (sensor_type) {
            // case "1":
            //     break;
            // case "2":
            //     break;
            // case "3":
            //     break;
            // case "4":
            //     break;
            // Humidity Sensor
            case "5":
                const humidityValues = updatedDataSource.map((log) => {
                    return log.humidity / 10;
                });

                const refHumidityValues = updatedDataSource.map((log) => {
                    return log.reference_humidity / 10;
                });

                const temperatureValues = updatedDataSource.map((log) => {
                    return log.temperature / 10;
                });

                const refTemperatureValues = updatedDataSource.map((log) => {
                    return log.reference_temperature / 10;
                });

                const normalBackgroundColor = isDarkMode ? colorPrimary + '80' : 'rgba(21, 0, 255, 0.2)';
                const normalBorderColor = isDarkMode ? '#fdcce5' : 'rgba(21, 0, 255, 0.7)';

                const refBackgroundColor = isDarkMode ? '#fd7f6f80' : 'rgba(255, 0, 0, 0.2)';
                const refBorderColor = isDarkMode ? '#fd7f6f' : 'rgba(255, 0, 0, 0.7)';

                return ({
                    humidityChartDatasets: [
                        {
                            label: t('pages.node_charts.humidity_chart.humidity'),
                            data: humidityValues,
                            fill: true,
                            backgroundColor: normalBackgroundColor,
                            borderColor: normalBorderColor,
                        },
                        {
                            label: t('pages.node_charts.humidity_chart.ref_humidity'),
                            data: refHumidityValues,
                            fill: true,
                            backgroundColor: refBackgroundColor,
                            borderColor: refBorderColor,
                        }
                    ],
                    temperatureChartDatasets: [
                        {
                            label: t('pages.node_charts.humidity_chart.temperature'),
                            data: temperatureValues,
                            fill: true,
                            backgroundColor: normalBackgroundColor,
                            borderColor: normalBorderColor,
                        },
                        {
                            label: t('pages.node_charts.humidity_chart.ref_temperature'),
                            data: refTemperatureValues,
                            fill: true,
                            backgroundColor: refBackgroundColor,
                            borderColor: refBorderColor,
                        }
                    ]
                })
            
            // Sleep Sensor
            case "6":
                const sensorStatusValues = updatedDataSource.map((log) => {
                    return log.sleep_sensor_status_number;
                });

                let isPersonDetected = false;

                const sensorStatusValuesForPerson = updatedDataSource.map((log) => {


                    if (log.sleep_sensor_status_number === '3') {
                        isPersonDetected = true;
                    } else if (log.sleep_sensor_status_number === '4') {
                        isPersonDetected = false;
                    }

                    if (isPersonDetected) {
                        return '0.75';
                    } else {
                        return '0';
                    }
                });

                const bpmValues = updatedDataSource.map((log) => {
                    return log.bpm;
                });

                return [
					{
						label: t('pages.node_charts.person_detected'),
						data: sensorStatusValuesForPerson,
                        backgroundColor: isDarkMode ? colorPrimary + 'CC' : "rgba(0, 45, 66, 0.6)",
                        borderColor: "rgba(0, 45, 66, 1)",
						pointRadius: 0,
                        pointHoverRadius: 0,
						stepped: true,
						fill: true,
					},
					{
						label: t('pages.node_charts.sleep_chart.sleep_sensor_status'),
						data: sensorStatusValues,
                        backgroundColor: isDarkMode ? '#EA554580' : 'rgba(255, 0, 0, 0.5)',
                        borderColor: isDarkMode ? '#EA5545' : 'rgba(255, 0, 0, 0.7)',
						pointBorderColor: (context) => {
							if (
								context.raw === "3" &&
								bpmValues[context.dataIndex] > 0
							) {
								return "rgba(255, 0, 0, 1)";
							}

							return "rgba(255, 0, 0, 0.7)";
						},
						pointBackgroundColor: (context) => {
							if (
								context.raw === "3" &&
								bpmValues[context.dataIndex] > 0
							) {
								return "rgba(255, 0, 0, 0.7)";
							}

							return "white";
						},
						pointHoverRadius: (context) => {
							if (
								context.raw === "3" &&
								bpmValues[context.dataIndex] > 0
							) {
								return 6;
							}

							return 3;
						},
						stepped: true,
						fill: true,
					}
				];
            // case "7":
            //     break;
            default:
                break;
        }
    }

    const createChartOptions = (data, sensor_type) => {



        Tooltip.positioners.bottom = function (items) {
            const pos = Tooltip.positioners.average(items);
    
            if (!pos) return false;
    
            const chart = this.chart;
    
            return {
                x: pos.x,
                y: chart.chartArea.bottom,
                xAlign: 'center',
                yAlign: 'top',
            }
        }

        // Tooltip.positioners.top = function (items) {
        //     const pos = Tooltip.positioners.average(items);
    
        //     if (!pos) return false;
    
        //     const chart = this.chart;
    
        //     return {
        //         x: pos.x,
        //         y: chart.chartArea.top,
        //         xAlign: 'center',
        //         yAlign: 'bottom',
        //     }
        // }

        const options = setInitalOptions(data.length);

        switch (sensor_type) {
            // case "1":
            //     break;
            // case "2":
            //     break;
            // case "3":
            //     break;
            // case "4":
            //     break;
            // Humidity Sensor
            case "5":

                const humidityChartOptions = {
					...options,
                    plugins: {
                        ...options.plugins,
                        title: {
                            ...options.plugins.title,
                            text: ''
                        }
                    },
					scales: {
						...options.scales,
						y: {
                            ...options.scales.y,
							min: 20,
							max: 100,
						},
					},
				};

                const temperatureChartOptions = {
					...options,
					scales: {
						...options.scales,
						y: {
                            ...options.scales.y,
							min: 15,
							max: 40,
						},
					},
				};

                setInitialScales({
                    humidity: {
                        y: humidityChartOptions.scales.y
                    },
                    temperature: {
                        y: temperatureChartOptions.scales.y
                    }
                });

                return ({
                    humidityChartOptions,
                    temperatureChartOptions
                })
            // Sleep Sensor
            case "6":
                const sleepSensorChartOptions = {
                    ...options,
                    plugins: {
                        ...options.plugins,
                        tooltip: {
                            ...options.plugins.tooltip,
                            callbacks: {
                                ...options.plugins.tooltip.callbacks,

                                label: (context) => {

                                    const bpm = updatedDataSource[context.dataIndex].bpm;
                                    const label = context.dataset.label || '';
                                    
                                    if (label === t('pages.node_charts.sleep_chart.sleep_sensor_status')) {
                                        switch (context.parsed.y) {
                                            case 1:
                                                return t('pages.node_charts.dummy_data');
                                            case 2:
                                                return t('pages.node_charts.sleep_chart.movement_detected');
                                            case 3:
                                                if (bpm > 0) {
                                                    return `${t('pages.node_charts.person_detected')} - ${bpm} bpm`;
                                                }
                                                return `${t('pages.node_charts.person_detected')} - ${t('pages.node_charts.sleep_chart.no_heartbeat')}`;
                                            case 4:
                                                return t('pages.node_charts.person_not_detected');
                                            default:
                                                return '';
                                        }
                                    } else {
                                        switch (context.parsed.y) {
                                            case 0.75:
                                                return t('pages.node_charts.person_detected');
                                            default:
                                                return "";
                                        };
                                    }
                                }
                            }
                        }
                    },
                    scales: {
                        ...options.scales,
                        y: {
                            ...options.scales.y,
                            min: 0.25,
                            max: 4.25,
                            ticks: {
                                ...options.scales.y.ticks,
                                callback: (value, index, values) => {
                                    switch (value) {
                                        case 1:
                                            return t('pages.node_charts.dummy_data');
                                        case 2:
                                            return t('pages.node_charts.sleep_chart.movement_detected');
                                        case 3:
                                            return t('pages.node_charts.person_detected');
                                        case 4:
                                            return t('pages.node_charts.person_not_detected');
                                        default:
                                            return '';
                                    }
                                }
                            },
                        }
                    }
                };

                setInitialScales({
                    sleepSensor: {
                        y: sleepSensorChartOptions.scales.y
                    }
                });

                return sleepSensorChartOptions;
            // case "7":
            //     break;
            default:
                break;
        }
    }

    const createChartLabels = (dataSource, sensor_type) => {
        const labels = dataSource?.map((log) => {
			return Moment.unix(log.timestamp).utcOffset(gateway_gmt - 12).format("L HH:mm:ss");
		});

        switch (sensor_type) {
            // case "1":
            //     break;
            // case "2":
            //     break;
            // case "3":
            //     break;
            // case "4":
            //     break;
            // case "7":
            //     break;
            default:
                return labels;
        }
    }

    const chartData = React.useMemo(() => {
		return {
			labels: createChartLabels(updatedDataSource, sensor_type),
			datasets: createChartDataSets(updatedDataSource, sensor_type),
		};
	}, [updatedDataSource, sensor_type]);

    const options = React.useMemo(() => createChartOptions(updatedDataSource, sensor_type), [updatedDataSource, sensor_type]);

    const clearActiveElements = (e) => {
        chartRef.current.forEach((chart) => {
            chart.tooltip.setActiveElements([]);
            chart.setActiveElements([]);
            chart.update();
        });
    };

    const createChart = (sensor_type) => {

        let ChartComponent = Line;
    
        const chartProps = {
			data: chartData,
			options: options,
			onMouseOut: clearActiveElements,
		};

        switch (sensor_type) {
            // case "1":
            //     break;
            // case "2":
            //     break;
            // case "3":
            //     break;
            // case "4":
            //     break;
            // Humidity Sensor
            case "5":

                const humidityChartProps = {
                    ...chartProps,
                    data: {
                        ...chartProps.data,
                        datasets: chartProps.data.datasets.humidityChartDatasets
                    },
                    options: options.humidityChartOptions
                }

                const temperatureChartProps = {
                    ...chartProps,
                    data: {
                        ...chartProps.data,
                        datasets: chartProps.data.datasets.temperatureChartDatasets
                    },
                    options: options.temperatureChartOptions
                }

                return (
                    <>
                        <Col className='w-50 h-100'>
                            <ChartComponent ref={(instance) => chartRef.current[0] = instance} {...humidityChartProps} />
                        </Col>
                        <Col className='w-50 h-100'>
                            <ChartComponent ref={(instance) => chartRef.current[1] = instance} {...temperatureChartProps} />
                        </Col>
                    </>
                )
            case "6":

                return (
                    <>
                        <ChartComponent ref={(instance) => chartRef.current[0] = instance} {...chartProps} />
                    </>
                )
            // case "7":
            //     break;
            default:
                return <h1>
                    {t('pages.node_logs.chart_under_development')}
                </h1>
                // break;
        }
    };

    const renderChart = () => {
        if (loading) return <Spinner />;
        if (!dataSource || dataSource.length === 0) return <NoDataMessage />;
   
        return createChart(sensor_type);
    };

    const zoomInChart = () => {
        chartRef.current.forEach((chart) => {
            chart.zoom({x: 1.5});
        });
    };

    const zoomOutChart = () => {
        chartRef.current.forEach((chart) => {
            chart.zoom(0);
        });
    }

    const resetChartZoom = () => {
        chartRef.current.forEach((chart) => {
            chart.resetZoom();
        });
    };

    const chartScaleToggle = () => {
        switch (sensor_type) {
            // case "1":
            //     break;
            // case "2":
            //     break;
            // case "3":
            //     break;
            // case "4":
            //     break;
            // Humidity Sensor
            case "5":
                const humidityChart = chartRef.current[0];
                const temperatureChart = chartRef.current[1];

                humidityChart.options.scales.y = autoScale ? {
                    ticks: initialScales.humidity.y.ticks,
                    grid: initialScales.humidity.y.grid                    
                } : initialScales.humidity.y;

                temperatureChart.options.scales.y = autoScale ? {
                    ticks: initialScales.temperature.y.ticks,
                    grid: initialScales.temperature.y.grid
                } : initialScales.temperature.y;

                humidityChart.update();
                temperatureChart.update();
                break;
            case "6":
                const sleepSensorChart = chartRef.current[0];

                sleepSensorChart.options.scales.y = autoScale ? {
                    ticks: {
                        stepSize: 1,
                        callback: (value, index, values) => {
                            switch (value) {
                                case 1:
                                    return t('pages.node_charts.dummy_data');
                                case 2:
                                    return t('pages.node_charts.sleep_chart.movement_detected');
                                case 3:
                                    return t('pages.node_charts.person_detected');
                                case 4:
                                    return t('pages.node_charts.person_not_detected');
                                default:
                                    return '';
                            }
                        }
                    },
                } 
                : initialScales.sleepSensor.y;

                sleepSensorChart.update();
                break;
            // case "7":
            //     break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (prevZoomRef.current < zoom) {
            zoomInChart();
        }
        
        if (prevZoomRef.current > zoom) {
            zoomOutChart();
        }

        prevZoomRef.current = zoom;
    }, [zoom])

    useEffect(() => {
        resetChartZoom();
    }, [reset_zoom])
    
    useEffect(() => {
        chartScaleToggle();
    }, [autoScale]);

    return (
        <Row style={{height: isMobile ? '50vh' : isTablet ? '40vh' : '69vh'}} className='justify-content-center align-items-center'>
            {renderChart()}
        </Row>
    )
}

const Spinner = () => (
    <div>
        <Spin size='large' />
    </div>
);

const NoDataMessage = () => {
    const {t} = useTranslation();

    return (
        <div>
            <p className='h1'>{t('pages.node_logs.no_data')}</p>
        </div>
    )
};

export default NodeLogChart;
