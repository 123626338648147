import { createSlice } from '@reduxjs/toolkit';


export const setupLogSlice = createSlice({
    name: 'setupLogReducers',
    initialState: {
        error: null,
        loading: false,
        success: false,
        setupLog: {},
        setupLogs: []
    },
    reducers: {
            
        // Setup Logs
        setupLogListRequest: (state) => { state.loading = true },
        setupLogListSuccess: (state, action) => { state.loading = false; state.setupLogs = action.payload; state.success = true; },
        setupLogListFail: (state, action) => { state.loading = false; state.error = action.payload},

        // Setup Log
        setupLogDetailRequest: (state) => { state.setupLogDetailLoading = true },
        setupLogDetailSuccess: (state, action) => { state.setupLogDetailLoading = false; state.setupLog = action.payload; state.setupLogDetailSuccess = true; },
        setupLogDetailFail: (state, action) => { state.setupLogDetailLoading = false; state.setupLogDetailError = action.payload},

        // Reset Actions
        setupLogListReset: (state) => { state.loading = false; state.success = false; state.error = null },
        setupLogDetailReset: (state) => { state.setupLogDetailLoading = false; state.setupLogDetailSuccess = false; state.setupLogDetailError = null },
        
        // Reset Slice
        setupLogSliceReset: (state) => {
            state.loading = false; state.success = false; state.error = null; state.setupLog = null; state.setupLogs = null;
            state.setupLogDetailLoading = false; state.setupLogDetailSuccess = false; state.setupLogDetailError = null;
        },
    }
});

export const {
    
    setupLogListRequest,
    setupLogListSuccess,
    setupLogListFail,
    setupLogListReset,

    setupLogDetailRequest,
    setupLogDetailSuccess,
    setupLogDetailFail,
    setupLogDetailReset,

    setupLogSliceReset

} = setupLogSlice.actions;
