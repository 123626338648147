import { Descriptions, Row } from "antd";
import { useTranslation } from "react-i18next";


function HotlineDetailModal({hotline}) {

    const { t } = useTranslation();
    console.log('hotline: ', hotline);

    const alertDescriptionItems = [
        {
            key: 'date',
            label: t('description_items.date'),
            children: new Date(hotline.created_date).toLocaleString()
        },
        {
            key: 'updated_date',
            label: t('description_items.updated_date'),
            children: new Date(hotline.updated_date).toLocaleString()
        },
        {
            key: 'gateway',
            label: t('description_items.gateway_id'),
            children: hotline.hotline_details.gateway
        },
        {
            key: 'alert_type',
            label: t('pages.hotline.alert_type'),
            children: t(`notification_activities.${hotline.notif_activity}`)
        },
        {
            key: 'ticket_status',
            label: t('pages.hotline.ticket_status'),
            children: t(`pages.hotline.ticket_statuses.${hotline.ticket_status}`)
        },
        {
            key: 'call_status',
            label: t('pages.hotline.call_status'),
            children: t(`pages.hotline.call_statuses.${hotline.call_status}`)
        },
        {
            key: 'guardian_angel_status',
            label: t('pages.hotline.guardian_angel_status'),
            children: t(`pages.hotline.guardian_angel_statuses.${hotline.guardian_angel_status}`)
        },
        {
            key: 'event_report',
            label: t('pages.hotline.event_report'),
            children: hotline.report
        }
    ]
    
    return <>
        <Row gutter={[0,15]} justify={'center'}>
            <Descriptions items={alertDescriptionItems} column={1} bordered />
        </Row>
    </>
}

export default HotlineDetailModal;