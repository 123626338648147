import { Button, Descriptions, Divider, Input, Space, Table, Tag, DatePicker } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useMemo } from 'react';
import Moment from 'moment';
import 'moment/locale/tr'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { SetLocalStorageApi, getAllSupportTicketData, getSessions } from '../../../../core/apis/Apis';
import { setError, setLoading, setSuccess } from '../../../../core/stores/apiStatusControllerSlice';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@mui/icons-material';
import './Sessions.scss'

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SEO_HELMET } from '../../../../core/utils/helmet';
import { setTitle } from '../../../../core/stores/navbarTitleControllerSlice';
import { useTranslation } from 'react-i18next';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';


function Sessions() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const isModalOpen = useSelector(state => state.modalController.isModalOpen)
    const apiStatusController = useSelector(state => state.apiStatusController)
    const dispatch = useDispatch();
    const {t} = useTranslation();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })


    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [record] = useState({
        gtwID: window.location.pathname.split('GTWY-')[1].split('/')[0],
    })
    const [sessionsStatus, setSessionsStatus] = useState([]);
    const [sessionsMessage, setSessionsMessage] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [responseData, setResponseData] = useState();
    const [responseDataLoading, setResponseDataLoading] = useState(false);
    const [dateString, setDateString] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setTitle(t('pages.sessions.title')))
        // SetLocalStorageApi()
        setResponseDataLoading(true)
        getData()
    }, [])


    const onClickFilterButton = () => {
        setResponseDataLoading(true)
        getDataFilterDate()
    }

    const getData = () => {

        getSessions('GTWY-' + record.gtwID)
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data)
                }
                else {
                    toast.error(`${res.data.error}`, {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    })
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const getDataFilterDate = () => {
        const params = {
            start: dateString[0],
            end: dateString[1]
        }

        getSessions('GTWY-' + record.gtwID, params)
            .then(res => {
                if (res.status === 200) {
                    setResponseData(res.data)
                }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })

    }

    /* -------------------------------------------------------------------------- */
    //TODO                          FOR TABLE PROPS                               */
    /* -------------------------------------------------------------------------- */

    const tablePropOnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };
    const columns = [
        {
            title: t('pages.sessions.date'),
            dataIndex: 'date',
            key: 'date',
            width: 140,
            fixed: 'left',
        },
        {
            title: t('pages.sessions.status'),
            dataIndex: 'status',
            key: 'status',
            filteredValue: filteredInfo.status || null,
            filters: sessionsStatus.length > 0 && sessionsStatus.map(res => {
                return {
                    text: res.toUpperCase(),
                    value: res,
                }
            }),
            onFilter: (value, record) => record.status === value,
            render: (status) => <div style={{ textAlign: 'center', }} >{status}</div>,
        },
        {
            title: t('pages.sessions.message'),
            dataIndex: 'message',
            key: 'message',
            filteredValue: filteredInfo.message || null,
            filters: sessionsMessage.length > 0 && sessionsMessage.map(res => {
                return {
                    text: res.toUpperCase(),
                    value: res,
                }
            }),
            onFilter: (value, record) => record.message === value,
            render: (message) => <div style={{ textAlign: 'center', }} >{message}</div>,
        },
    ];


    const dataSource = useMemo(() => {
        var list = []
        var sessionStatusList = []
        var sessionMessageList = []
        if (responseData) {
            responseData.logs.map((res, index) => {
                if (res.status) {
                    sessionStatusList.push(res.status)
                }
                if (res.message) {
                    sessionMessageList.push(res.message)
                }

                list.push({
                    key: index,
                    date: Moment.unix(res.timestamp).format("DD/MM/YY, HH:mm"),
                    status: res.status,
                    message: res.message,
                })
            })
        }
        setSessionsStatus(Array.from(new Set(sessionStatusList)))
        setSessionsMessage(Array.from(new Set(sessionMessageList)))
        return list
    }, [responseData])

    var onChangeRangePicker = (date, dateString) => {
        setDateString(dateString)
    }
    return (
        <>
            <SEO_HELMET title={`${t('pages.sessions.title')} / ${record.gtwID}`} />
            <div id="Sessions">
                <div className="Sessions-cover">
                    {/* <Divider orientation='left'>{record.gtwID} - Sessions</Divider> */}
                    <Descriptions size='small' bordered column={{
                        sm: 2,
                        md: 3,
                        lg: 3
                    }}>
                        <Descriptions.Item label={t('description_items.gateway_id')} labelStyle={{ width: '160px' }} contentStyle={{ width: '160px' }} >
                            {record.gtwID}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('description_items.date')} labelStyle={{ width: '80px' }} contentStyle={{ width: '280px' }} >
                            <RangePicker
                                format={dateFormat}
                                onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                            />
                        </Descriptions.Item>
                        <Descriptions.Item contentStyle={{ width: 'max-content' }}><Button disabled={!dateString && true} onClick={dateString ? () => onClickFilterButton() : null}>{t('description_items.filter')}</Button></Descriptions.Item>
                    </Descriptions>
                    <div className="Sessions-bottom_more_info">
                        <Table
                            footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>

                                <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                                <Button onClick={() => setFilteredInfo({})}>{t('tables.clear_filters')}</Button>
                            </div>
                            }
                            size='middle'
                            bordered={true}
                            columns={columns}
                            pagination={false}
                            dataSource={dataSource}
                            loading={responseDataLoading}
                            scroll={{
                                y: 'calc(100vh - 320px)',
                            }}
                            onChange={tablePropOnChange}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Sessions
