import { Button, Descriptions, Divider, Input, Space, Table, Tag, DatePicker, Row, InputNumber, theme } from 'antd';
import React, { useRef, useState } from 'react'
import { useMemo } from 'react';
import Moment from 'moment';
import 'moment/locale/tr'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { SetLocalStorageApi, getAllSupportTicketData, getAnomalyAnalyzer } from '../../../../core/apis/Apis';
import { setError, setLoading, setSuccess } from '../../../../core/stores/apiStatusControllerSlice';
import Highlighter from 'react-highlight-words';
import { useEffect } from 'react';
import { SearchOutlined } from '@mui/icons-material';
import './AnomalyAnalyzer.scss'

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { SEO_HELMET } from '../../../../core/utils/helmet';
import { setTitle } from '../../../../core/stores/navbarTitleControllerSlice';
import PageLoading from '../../../../components/loadings/PageLoading';
import { useTranslation } from 'react-i18next';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;
const dateFormat = 'DD-MM-YYYY';


function AnomalyAnalyzer() {

    /* -------------------------------------------------------------------------- */
    //TODO -                             SELECTORS                                */
    /* -------------------------------------------------------------------------- */
    const isModalOpen = useSelector(state => state.modalController.isModalOpen)
    const apiStatusController = useSelector(state => state.apiStatusController)
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        token: {
            colorTextHeading,
            colorBorder,
        }
    } = theme.useToken();

    /* -------------------------------------------------------------------------- */
    //TODO -                             MEDIAQUERY                                */
    /* -------------------------------------------------------------------------- */
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })


    /* -------------------------------------------------------------------------- */
    //TODO -                             STATES                                   */
    /* -------------------------------------------------------------------------- */
    const [record] = useState({
        gtwID: window.location.pathname.split('GTWY-')[1].split('/')[0],
    })
    
    const [filteredInfo, setFilteredInfo] = useState({});
    const [responseData, setResponseData] = useState();
    const [responseDataLoading, setResponseDataLoading] = useState(true);
    const [params, setParams] = useState({})
    const [gatewayGMT, setGatewayGMT] = useState(0)
    /* -------------------------------------------------------------------------- */
    //TODO                                Functions                               */
    /* -------------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setTitle(t('pages.anomaly_analyzer.title')))
        // SetLocalStorageApi()
        getData()
    }, [])

    // useEffect(() => getDataForPagination(), [pageNumber])

    const onClickFilterButton = () => {
        setResponseDataLoading(true)
        getDataWithDateRange()
    }

    const getData = () => {

        getAnomalyAnalyzer("GTWY-" + record.gtwID, params)
            .then(res => {
                setResponseData(res.data)
                // if (res.data.status == 200) {
                // }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    const getDataWithDateRange = () => {

        getAnomalyAnalyzer("GTWY-" + record.gtwID, params)
            .then(res => {
                setResponseData(res.data)
                // if (res.data.status == 200) {
                // }
                setResponseDataLoading(false)
                dispatch(setError(false))
                dispatch(setSuccess(true))
                dispatch(setLoading(false))
            })
            .catch(err => {
                dispatch(setError(true))
                dispatch(setSuccess(false))
                dispatch(setLoading(false))
                console.log("err : ", err)
                toast.error(t('toast.error_text'), {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
                toast.error(`${err}`, {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            })
    }

    /* -------------------------------------------------------------------------- */
    //TODO                          FOR TABLE PROPS                               */
    /* -------------------------------------------------------------------------- */

    const tablePropOnChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };
    const columns = [
        {
            title: t('pages.anomaly_analyzer.table_items.start_time'),
            dataIndex: 'start_time',
            key: 'start_time',
            filteredValue: filteredInfo.start_time || null,
            sorter: (a, b) => a.start_time - b.start_time,
            width: 120,
            render: (text, record) => Moment.unix(text).utcOffset(gatewayGMT - 12).format('DD/MM/YYYY, HH:mm:ss')
        },
        {
            title: t('pages.anomaly_analyzer.table_items.end_time'),
            dataIndex: 'end_time',
            key: 'end_time',
            filteredValue: filteredInfo.end_time || null,
            sorter: (a, b) => a.end_time - b.end_time,
            width: 120,
            render: (text, record) => Moment.unix(text).utcOffset(gatewayGMT - 12).format('DD/MM/YYYY, HH:mm:ss')
        },
        {
            title: t('pages.anomaly_analyzer.table_items.duration'),
            dataIndex: 'difference_formatted',
            key: 'difference_formatted',
            filteredValue: filteredInfo.difference_formatted || null,
            sorter: (a, b) => a.difference - b.difference,
            width: 120,
            render: (text, record) => Moment.unix(record.difference).utc().format('HH:mm:ss')
        },
        {
            title: t('pages.anomaly_analyzer.table_items.duration_sec'),
            dataIndex: 'difference',
            key: 'difference',
            filteredValue: filteredInfo.difference || null,
            sorter: (a, b) => a.difference - b.difference,
            width: 120,
        },
    ];


    const dataSource = useMemo(() => {
        var list = []
        setGatewayGMT(responseData?.gateway_gmt)
        if (responseData?.anomaly_detects) {
            Object.values(responseData.anomaly_detects).map((res, index) => 
                list.push({
                    key: index,
                    start_time:res.start,
                    end_time:res.end,
                    difference:res.duration,
                })
            )
        }
        return list.reverse()
    }, [responseData])

    const isThereDateRange = useMemo(() => {
        if (params.start_date && params.end_date)
            return true
        return false
    }, [params])

    var onChangeRangePicker = (date, dateString) => {
        setParams({
            ...params,
            start_date: dateString[0],
            end_date: dateString[1],
        })
    }

    const headerStyles = {
        border: `1px solid ${colorBorder}`,
        padding: '10px',
        borderRadius: '5px',
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: colorTextHeading
    }

    return (
        <>
            <SEO_HELMET title={`${t('pages.anomaly_analyzer.title')} / ${record.gtwID}`} />
            <div id="AnomalyAnalyzer">
                <div className="AnomalyAnalyzer-cover p-2">
                    {/* <Divider orientation='left'>AnomalyAnalyzer</Divider> */}
                    <Descriptions
                        size='small'
                        className='text-center text-md-start'
                        bordered
                        column={{
                            sm: 4,
                            md: 4,
                            lg: 4
                        }}
                        layout={isTabletOrMobile ? 'vertical' : 'horizontal'}
                    >
                        <Descriptions.Item label={t('pages.anomaly_analyzer.description_items.gateway_id')} labelStyle={{ width: '160px' }} contentStyle={{ width: '160px' }} >
                            {record.gtwID}
                        </Descriptions.Item>
                        <Descriptions.Item label={t('pages.anomaly_analyzer.description_items.date')} labelStyle={{ width: '80px' }} contentStyle={{ width: '280px' }} >
                            <RangePicker
                                format={dateFormat}
                                onChange={(date, dateString) => onChangeRangePicker(date, dateString)}
                            />
                            <Button disabled={!isThereDateRange} onClick={isThereDateRange ? () => onClickFilterButton() : null}>{t('pages.anomaly_analyzer.description_items.filter')}</Button>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('pages.anomaly_analyzer.description_items.min_threshold')} labelStyle={{ width: '140px' }} contentStyle={{ width: '100px' }} >
                            <InputNumber
                                type='number'
                                min={0}
                                controls={false}
                                placeholder={30}
                                onChange={(e) => setParams({...params, anomaly: e})}
                            />
                        </Descriptions.Item>
                    </Descriptions>

                    <div className="AnomalyAnalyzer-bottom_more_info">
                        {responseDataLoading == true ?
                            <PageLoading />
                            :
                            <>
                                <Row className='justify-content-center justify-content-lg-start'>
                                    {responseData &&
                                        <>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>{t('pages.anomaly_analyzer.description_items.avg')}</span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>{responseData.average_inactivity}</span>
                                            </div>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>
                                                {t('pages.anomaly_analyzer.description_items.avg_15_min')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                    {responseData?.averages['900 sec'] || 0}
                                                </span>
                                            </div>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>
                                                {t('pages.anomaly_analyzer.description_items.avg_30_min')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                    {responseData?.averages['1800 sec'] || 0}
                                                </span>
                                            </div>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>
                                                {t('pages.anomaly_analyzer.description_items.avg_45_min')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                    {responseData?.averages['2700 sec'] || 0}
                                                </span>
                                            </div>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>
                                                {t('pages.anomaly_analyzer.description_items.avg_60_min')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                    {responseData?.averages['3600 sec'] || 0}
                                                </span>
                                            </div>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>
                                                {t('pages.anomaly_analyzer.description_items.avg_90_min')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                    {responseData?.averages['5400 sec'] || 0}
                                                </span>
                                            </div>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>
                                                {t('pages.anomaly_analyzer.description_items.avg_120_min')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                    {responseData?.averages['7200 sec'] || 0}
                                                </span>
                                            </div>
                                            <div style={headerStyles}>
                                                <span style={{ marginBottom: '5px' }}>
                                                {t('pages.anomaly_analyzer.description_items.max')}
                                                </span>
                                                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                                                    {responseData.max_inactivity}
                                                </span>
                                            </div>
                                        </>
                                    }
                                </Row>

                                <Table
                                    footer={(currentPageData) => <div className='d-flex align-items-center justify-content-between'>
                                        <div>{t('tables.total_data')}: <b>{currentPageData.length}</b> </div>
                                    </div>
                                    }
                                    size='middle'
                                    bordered={true}
                                    columns={columns}
                                    pagination={false}
                                    dataSource={dataSource}
                                    loading={responseData ? false : true || apiStatusController.isLoading == true ? true : false}
                                    onChange={tablePropOnChange}
                                />
                            </>
                        }

                    </div>

                </div>
            </div>
        </>
    )
}
export default AnomalyAnalyzer
