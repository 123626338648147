import React from 'react'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setTitle } from '../../../core/stores/navbarTitleControllerSlice';
import { detailSetupLog, listSetupLogs } from '../../../core/actions/logs/setupLogActions';
import { useSelector } from 'react-redux';
import { SEO_HELMET } from '../../../core/utils/helmet';
import { Card, Collapse, Descriptions, Row, Spin, Typography, theme } from 'antd';
import { toast } from 'react-toastify';
import SetupLogTable from './SetupLogTable';
import { routes } from '../../../core/route/router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';
import { getGatewayGMT } from '../../../core/apis/Apis';

function SetupLogDetail() {

    const {gtw_id, log_id} = useParams();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [gatewayGMT, setGatewayGMT] = React.useState(null);

    const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
    const isTablet = useMediaQuery({ query: `(max-width: 1144px)` });

    const isMobileOrTablet = isMobile || isTablet;


    useEffect(() => {
        dispatch(setTitle(t('pages.setup_log.title')))
        dispatch(detailSetupLog(log_id))
        dispatch(listSetupLogs({"device_serial": gtw_id}))
        
        getGatewayGMT(gtw_id)
            .then((res) => {
                if (res.status === 200)
                    setGatewayGMT(res.data)
            })
            .catch((err) => {
                console.log("gmt error: ", err)
            })

    }, [dispatch, log_id, gtw_id]);

    const {
        setupLogReducers: { setupLog, setupLogDetailLoading, setupLogDetailerror, setupLogs },
    } = useSelector((state) => state);

    useEffect(() => {
        setupLogDetailerror &&
        
        toast.error(setupLogDetailerror, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
            progress: undefined,
        })
    }, [setupLogDetailerror])

    return (
        setupLogDetailLoading ? <Row justify={'center'} align={'middle'} style={{height: '80vh'}}><Spin size='large' /></Row> :
        <>
            <SEO_HELMET title={t('pages.setup_log.title')} />
            <Row align={'middle'} justify={'center'} style={{height: '90vh'}}>
                <Card className='shadow-sm' style={{maxWidth: isMobileOrTablet ? '95%' :'50%'}}>
                    <Descriptions column={{ xs: 1, sm: 1, md: 2, xl: 2, xxl: 2}} layout={isMobileOrTablet ? 'vertical' : 'horizontal'} size='large' bordered title={t('pages.setup_log.title')}>
                        <Descriptions.Item label={t('tables.customer_id')} className='text-center'>{setupLog?.user_uuid === "Unknown" ? t('general.unknown') : setupLog?.user_uuid}</Descriptions.Item>
                        <Descriptions.Item label={t('pages.setup_log.description_items.customer_name')} className='text-center'>{setupLog?.user_name === "Unknown" ? t('general.unknown') : setupLog?.user_name}</Descriptions.Item>
                        <Descriptions.Item label={t('pages.setup_log.description_items.customer_mail')} className='text-center'>{setupLog?.user_mail === "Unknown" ? t('general.unknown') : setupLog?.user_mail}</Descriptions.Item>
                        <Descriptions.Item label={t('pages.setup_log.description_items.customer_country')} className='text-center'>{setupLog?.setup_location?.country === "Unknown" ? t('general.unknown') : setupLog?.setup_location?.country}</Descriptions.Item>
                        <Descriptions.Item label={t('pages.setup_log.description_items.device_serial')} className='text-center'>
                            <Typography.Link
                                href={`${process.env.REACT_APP_MEDIA_URL}${routes.gateway_detail.path}/${setupLog?.device_serial}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {setupLog?.device_serial}
                            </Typography.Link>
                        </Descriptions.Item>
                        <Descriptions.Item label={t('tables.app_version')} className='text-center'>{setupLog?.app_version === "Unknown" ? t('general.unknown') : setupLog?.app_version}</Descriptions.Item>
                        <Descriptions.Item label={t('pages.setup_log.description_items.device_os')} className='text-center'>{setupLog?.device_os === "Unknown" ? t('general.unknown') : setupLog?.device_os?.toUpperCase()}</Descriptions.Item>
                        <Descriptions.Item label={t('tables.date')} className='text-center'>
                            {
                                gatewayGMT ?
                                    moment(parseInt(setupLog?.timestamp)).utcOffset(gatewayGMT - 12).format("DD/MM/YY HH:mm:ss")
                                    :
                                    moment(parseInt(setupLog?.timestamp)).format("DD/MM/YY HH:mm:ss")
                            }
                        </Descriptions.Item>
                    </Descriptions>
                    <Collapse className='mt-5 border-0 shadow' items={[{key: 1, label: t('pages.setup_log.collapse_label'),
                    
                        children:
                            <SetupLogTable setupLogs={setupLogs.filter((log) => log.id !== parseInt(log_id))} loading={setupLogDetailLoading} />
                        }]}
                    />
                </Card>
            </Row>
        </>
    )
}

export default SetupLogDetail
