import { createSlice } from '@reduxjs/toolkit'


export const apiStatusControllerSlice = createSlice({
    name: 'apiStatusControllerSlice',
    initialState: {
        isError: false,
        isSuccess: false,
        isLoading: true,
    },

    reducers: {
        setError: (state, action) => {
            state.isError = action.payload
        },
        setSuccess: (state, action) => {
            state.isSuccess = action.payload
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },

    }
})


export const { setError, setLoading, setSuccess } = apiStatusControllerSlice.actions

export default apiStatusControllerSlice.reducer


