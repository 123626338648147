import { createSlice } from '@reduxjs/toolkit'


export const mapControllerSlice = createSlice({
    name: 'mapControllerSlice',
    initialState: {
        selectedContainer: null,
        fullnessValue: null,
        chargeValue:null,
    },

    reducers: {
        setSelectedContainer: (state, action) => {
            state.selectedContainer = action.payload
        },
        setFullnessValue: (state, action) => {
            state.fullnessValue = action.payload
        },
        setChargeValue: (state, action) => {
            state.chargeValue = action.payload
        },
    }
})


export const { setSelectedContainer, setFullnessValue,setChargeValue } = mapControllerSlice.actions

export default mapControllerSlice.reducer